// Libs
import React from 'react';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';
import DiaryService from 'services/DiaryService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';

class PosTerminalPanel extends FloomlyComponent {
	constructor(props) {
		super(props);

		this.state = {
			checkOut: props.checkOut,
			totalAmount: 0,
			newPayments: props.checkOut.newPayments,
			isLoading: true,
			paymentDevices:[]
		}
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const {
			checkOut
		} = this.state;
		const { isRetailMenu, isCustomerAcctTransaction } = this.props;
		let totalAmount = 0;
		let newPayments = [];
		const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
		let paymentMethods = await DiaryService.loadPaymentMethods();
		const cardPM = paymentMethods.find(pm => pm.code == 'Card');
		if (isRetailMenu) {
			totalAmount = checkOut.total;
		}
		else if (isCustomerAcctTransaction) {
			totalAmount = checkOut.amount;
		}
		else {
			newPayments = checkOut.newPayments.filter(np => np.paymentMethodID == cardPM.paymentMethodID && !np.id && !np.isSavedCard && !np.txnReference);

			if (newPayments.length > 0)
				totalAmount = newPayments[0].amount;
		}

		await this.setStateAsync({
			newPayments: newPayments,
			isLoading: false,
			paymentDevices,
			totalAmount,
			checkOut
		});
	}

	async updateFields(values) {
		const checkOut = { ...this.state.checkOut };

		for (var field in values) {
			let value = values[field];
			checkOut[field] = value;
		}
		this.props.onCheckOutChanged(checkOut);
		this.setState({
			checkOut
		});
	}

	render() {
		const {
			checkOut,
			isLoading,
			paymentDevices
		} = this.state;

		const {
			isAddingDeposit,
			isRefund,
		} = this.props;

		if (isLoading) {
			return (<Loader />);
		}

		return (<>
			<div className="panel payment-methods-panel">
				<div className="panel-header">
					{isAddingDeposit ? 'Add Deposit through Card Machine' : isRefund ? 'Refund' : 'Card Machine'}
				</div>
				<div className="panel-body">
					<ul className="list service-list">
						<li className="non-selectable">
							<span className="service-list-name">
								Card
							</span>

							<span className="service-list-price">
								<Money amount={this.state.totalAmount} />
							</span>
						</li>

						{paymentDevices.length == 0 && <>
							You have no payment devices set up - please add some in the settings area.
						</>}
						<li className="non-selectable">
							<span className="service-list-name">
								Payment Device								
							</span>
							<span className="service-list-price">
								<select
									style={{ width: '100%' }}
									value={checkOut.paymentDeviceId}
									onChange={e => this.updateFields({ paymentDeviceId: e.target.value })}
								><option value="">(Select...)</option>
									{paymentDevices.map(pd =>
										<option key={pd.paymentDeviceId} value={pd.paymentDeviceId}>{pd.paymentDeviceName}</option>
									)}
								</select>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</>)
	}
}

export default PosTerminalPanel;