import BaseService from 'services/BaseService';
import moment from 'moment';
import * as signalR from '@microsoft/signalr';
import GlobalStateService from 'services/GlobalStateService';

//-------------------------------------------------------------------------------------------------------------------

export default class DiaryService
    extends BaseService {

    static getAppointmentStatusFriendly(event) {
        switch (event.status) {
            case 'provisional':
                return 'Provisional';
            case 'unconfirmed':
                if (event.dateReminderSent) return 'Reminder sent';
                else if (!event.sendSMSReminders) return 'SMS reminders disabled';
                else if (!event.hasMobileNumber) return 'No mobile number';
                else return 'Reminder not sent';
            case 'confirmed': return 'Confirmed';
            case 'waiting': return 'Waiting';
            case 'withStylist': return 'With Stylist';
            case 'checkedOut': return 'Checked Out';
            case 'noShow': return 'No Show';
            case 'cancelled': return 'Cancelled';
            default: return 'Unknown';
        }
    }

    static getWorkingTypeFriendly(workingType) {
        switch (workingType) {
            case 'working': return 'Working';
            case 'notWorking': return 'Not working';
            case 'holidayPaid': return 'Holiday';
            case 'maternity': return 'Maternity';
            case 'absenceNotPaid': return 'Unpaid leave';
            case 'sick': return 'Sick';
            case 'bankHoliday': return 'Bank holiday';
            case 'dayInLieu': return 'Day in lieu';
            case 'college': return 'College';
            case 'wedding': return 'Wedding';
            default: return workingType;
        }
    }

    static getAppointmentStatusClass(event) {
        const classNames = [];
        switch (event.status) {
            case 'provisional':
                classNames.push('status-provisional');
                break;
            case 'unconfirmed':
                if (event.hasUnreadComm) {
                    classNames.push('status-not-confirmed-cust-response');
                } else if (event.dateReminderSent) {
                    classNames.push('status-not-confirmed');
                } else {
                    classNames.push('status-reminder-not-sent');
                }
                break;
            case 'confirmed':
                if (event.hasUnreadComm) {
                    classNames.push('status-confirmed-cust-response');
                } else {
                    classNames.push('status-confirmed');
                }
                break;
            case 'waiting':
                classNames.push('status-waiting');
                break;
            case 'withStylist':
                classNames.push('status-with-stylist');
                break;
            case 'checkedOut':
                classNames.push('status-checked-out');
                break;
            case 'noShow':
                classNames.push('status-no-show');
                break;
            case 'cancelled':
                classNames.push('status-cancelled');
                break;
            default:
                classNames.push('status-unknown');
                break;
        }
        return classNames.join(' ');
    }
    static async getBookingInfo() {
        const response = await BaseService.callAPI('appointment/get-booking-info');
        return response;
    }

    static async getDate(date, stylistID) {
        const response = await BaseService.callAPI('appointment/get-date', {
            date: moment(date).format('YYYY-MM-DD'),
            stylistID: stylistID
        });
        return response;
    }

    static async loadDiary(date, view, stylistID, requestID) {
        const response = await BaseService.callAPI('appointment/get-diary', {
            date,
            view,
            stylistID,
            requestID
        });
        return response;
    }

    static async saveAppointmentSchedule(appointment) {
        const response = await BaseService.callAPI('appointment/save-schedule', appointment);
        return response;
    }

    static async loadPreviousApointments(customerID, beforeDate, num) {
        const response = await BaseService.callAPI('appointment/get-previous/' + customerID, {
            beforeDate: beforeDate,
            num: num
        });
        return response;
    }

    static async getPreviousApptWithColourNotes(customerID, beforeDate) {
        const response = await BaseService.callAPI('appointment/get-previous-appt-with-colour-notes', {
            customerID,
            beforeDate
        });
        return response;
    }

    static async loadNextApointments(customerID, afterDate) {
        const response = await BaseService.callAPI('appointment/get-next/' + customerID, {
            afterDate: afterDate
        });
        return response;
    }

    static async loadAllAppointments(customerID) {
        const response = await BaseService.callAPI('appointment/get-all/' + customerID);
        return response;
    }

    static async loadSchedule(id) {
        const response = await BaseService.callAPI('appointment/get-schedule/' + id);
        return response;
    }

    static async loadCheckOut(id) {
        const response = await BaseService.callAPI('appointment/get-check-out/' + id);
        return response;
    }

    static async loadClientRecord(id) {
        const response = await BaseService.callAPI('appointment/get-client-record/' + id);
        return response;
    }

    static async loadAppointmentSummary(id) {
        const response = await BaseService.callAPI('appointment/get-summary/' + id);
        return response;
    }

    static async cancelOrDeleteAppointment(appointmentID, reason, refundOption, type, refundTransactionID, onlineCancellation) {
        return BaseService.callAPI('appointment/cancel-or-delete', {
            appointmentID, reason, refundOption, type, refundTransactionID
        });
    }

    static async updateStatus(id, status) {
        await BaseService.callAPI('appointment/update-status', {
            id: id,
            status: status
        });
    }

    static async rescheduleAppointmentService(appointmentID, appointmentServiceID, stylistUserID, time, durationMins) {
        return BaseService.callAPI('appointment/reschedule-appointment-service/' + appointmentID + '/' + appointmentServiceID, {
            stylistUserID,
            time,
            durationMins
        });
    }

    static async triggerAppointmentRescheduledAutomation(appointmentID) {
        return BaseService.callAPI('appointment/trigger-appointment-rescheduled-automation/' + appointmentID);
    }

    static async loadPaymentMethods() {
        const response = await BaseService.callAPI('appointment/list-payment-methods');
        return response;
    }

	static paymentMethodRequiresCustomer(code) {
		return code === 'LoyaltyPoints' || code === 'Account' || code === 'OnAccount';
	}

    static async loadPaymentMethodsFor(type, hasCustomer) {
		let result = await DiaryService.loadPaymentMethods();

		switch (type) {
			case 'refund':
				return result.filter(pm => pm.useForRefunds && (DiaryService.paymentMethodRequiresCustomer(pm.code) && hasCustomer || !DiaryService.paymentMethodRequiresCustomer(pm.code)));

			case 'retail':
				return result.filter(pm => pm.useForRetail && (DiaryService.paymentMethodRequiresCustomer(pm.code) && hasCustomer || !DiaryService.paymentMethodRequiresCustomer(pm.code)));

			default:
				return result.filter(pm => pm.useForAppointments && (DiaryService.paymentMethodRequiresCustomer(pm.code) && hasCustomer || !DiaryService.paymentMethodRequiresCustomer(pm.code)));
		}
	}

    static async saveClientRecord(clientRecord) {
        await BaseService.callAPI('appointment/save-client-record', clientRecord);
    }

    static async saveCheckOut(checkOut) {
        return BaseService.callAPI('appointment/save-check-out', checkOut);
    }

    static updateBalance(checkOut, isRetail) {
		// Calculate total paid so far
        checkOut.amountToPay = 0;
        //if (isRetail) {
        //    checkOut.amountPaid = 0;
        //debugger;
        //}
        checkOut.newPayments.forEach(ap => {
            const amount = (Number(ap.amount) || 0);
            if (!ap.id || isRetail) {
                checkOut.amountToPay += amount;
            }
            //if (isRetail) {
            //    checkOut.amountPaid += amount;
            //}
        });

        // Calculate total of linked appointments
        let linkedAppointmentsTotal = 0;
        if (checkOut.linkedAppointments) {
            checkOut.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.balance;
            });
        }

        // Calculate overpayment total
        let overpaymentTotal = 0;
        if (checkOut.overpayments) {
            checkOut.overpayments.forEach(op => {
                overpaymentTotal += (op.amount || 0);
            });
        }

        //add voucher amount
        let voucherTotal = 0;
        if (checkOut.apptVoucherList) {
            checkOut.apptVoucherList.forEach(apptVoucher => {
                voucherTotal += (apptVoucher.balance || 0);
            });
        }

        // Calculate balance before and after these payments
        checkOut.balance = (checkOut.total || 0) + (linkedAppointmentsTotal || 0) - (isRetail ? 0 : (checkOut.amountPaid || 0)) - (checkOut.voucherAmountPaid || 0) + overpaymentTotal + voucherTotal;
        checkOut.newBalance = checkOut.balance - (checkOut.amountToPay || 0);

        // Default overpayment action
        if (checkOut.newBalance < 0) {
            if (!checkOut.overpaymentAction) {
				if (!!checkOut.customer) {
                	checkOut.overpaymentAction = 'add-to-account';
				} else {
					checkOut.overpaymentAction = 'give-cashback';
				}
            } else if (checkOut.overpaymentAction == 'add-to-account' && !checkOut.customer) {
				checkOut.overpaymentAction = 'give-cashback';
			}
        }

        // Round to 2dp
        checkOut.balance = Math.round(checkOut.balance * 100) / 100;
        checkOut.newBalance = Math.round(checkOut.newBalance * 100) / 100;
	}

    static updateTotals(appointment, promoCodes) {
        appointment.subTotal = 0;
        appointment.tax = 0;
        appointment.total = 0;
        appointment.serviceSubTotal = 0;
        appointment.serviceTax = 0;
        appointment.serviceTotal = 0;
        appointment.retailSubTotal = 0;
        appointment.retailTax = 0;
        appointment.retailTotal = 0;
        appointment.tipTotal = 0;
        appointment.discountTotal = 0;
        appointment.retailDiscountTotal = 0;
        appointment.promoAmount = 0;
        const promoCode = appointment.promoCodeID ? promoCodes.find(ap => ap.promoCodeID == appointment.promoCodeID) : null;

        // Add packages
        if (appointment.appointmentPackages) {
            appointment.appointmentPackages.forEach(apptPackage => {
                if (apptPackage.pricingType == 'fixed') {
                    appointment.serviceSubTotal += (apptPackage.subTotal || 0);
                    appointment.serviceTax += (apptPackage.tax || 0);
                    appointment.serviceTotal += (apptPackage.total || 0);
                }
            });
        }

        // Add services
        if (appointment.appointmentServices) {
            appointment.appointmentServices.forEach(apptService => {
                // If fixed price package, don't also add the individual prices of the services otherwise we'll be doubling up
                let isFixedPrice = false;
                var servPromoAmount = 0;
                if (apptService.appointmentPackageID) {
                    const apptPackage = (appointment.appointmentPackages || []).find(ap => ap.appointmentPackageID == apptService.appointmentPackageID);
                    if (apptPackage) {
                        isFixedPrice = apptPackage.pricingType == 'fixed';
                    }
                }

                if (apptService.canApplyPromo) {
                    if (appointment.promoCodeID && promoCodes) {
                        if (promoCode && promoCode.promoServiceApplicability == 'selected') {
                            servPromoAmount = this.applyPromoAmount(promoCodes, appointment.promoCodeID, apptService.total);
                        }
                        else if (promoCode && promoCode.promoServiceApplicability == 'all') {
                            servPromoAmount = this.applyPromoAmount(promoCodes, appointment.promoCodeID, apptService.total);
                        }
                    }
                    servPromoAmount = promoCode && promoCode.promoServiceApplicability == 'all' && promoCode.discountType == 'fixed' ? servPromoAmount / appointment.appointmentServices.filter(as => as.canApplyPromo).length : servPromoAmount;
                }
                appointment.promoAmount += servPromoAmount;

                if (!isFixedPrice) {
                    appointment.serviceSubTotal += (apptService.subTotal || 0);
                    appointment.serviceTax += (apptService.tax || 0);
                    appointment.serviceTotal += (apptService.total || 0);
                }
            });
        }

        // Add purchases
        if (appointment.appointmentPurchases) {
            appointment.appointmentPurchases.forEach(apptPurchase => {
                appointment.retailSubTotal += (apptPurchase.subTotal || 0);
                appointment.retailTax += (apptPurchase.tax || 0);
                appointment.retailTotal += (apptPurchase.total || 0);
            });
        }

        if (appointment.stockItems) {
            appointment.stockItems.forEach(apptPurchase => {
                appointment.retailSubTotal += (apptPurchase.subTotal || 0);
                appointment.retailTax += (apptPurchase.tax || 0);
                appointment.retailTotal += (apptPurchase.total || 0);
            });
        }

        // Add discount
        switch (appointment.discountType) {
            case 'amount':
                appointment.discountTotal -= Number(appointment.discountAmount) || 0;
                break;
            case 'percentage':
                appointment.discountTotal = 0;
                if (appointment.type === 'retail') {
                    if (appointment.stockItems) {
                        appointment.stockItems.forEach(si => {
                            if (si.isDiscounted) {
                                appointment.discountTotal -= Number(si.total * (appointment.discountAmount || 0) / 100) || 0;
                                appointment.discountTotal = Number(appointment.discountTotal.toFixed(2));
                            }
                        })
                    }
                } else {
                    if (appointment.appointmentServices) {
                        appointment.appointmentServices.forEach(apptService => {
                            if (apptService.isDiscounted) {
                                appointment.discountTotal -= Number(apptService.total * (appointment.discountAmount || 0) / 100) || 0;
                                appointment.discountTotal = Number(appointment.discountTotal.toFixed(2));
                            }
                        });
                    }
                }
                break;
        }

        // Add retail discount
        switch (appointment.retailDiscountType) {
            case 'amount':
                appointment.retailDiscountTotal -= Number(appointment.retailDiscountAmount) || 0;
                break;
            case 'percentage':
                appointment.retailDiscountTotal = 0;
                    if (appointment.appointmentPurchases) {
                        appointment.appointmentPurchases.forEach(apptPurchase => {
                            if (apptPurchase.isDiscounted) {
                                appointment.retailDiscountTotal -= Number(apptPurchase.total * (appointment.retailDiscountAmount || 0) / 100) || 0;
                                appointment.retailDiscountTotal = Number(appointment.retailDiscountTotal.toFixed(2));
                            }
                        });
                    }
                break;
        }

        // Add tips
        if (appointment.appointmentTips) {
            appointment.appointmentTips.forEach(apptTip => {
                appointment.tipTotal += (apptTip.amount || 0);
            });
        }

        // Totals
        appointment.subTotal = appointment.serviceSubTotal + appointment.retailSubTotal;
        appointment.tax = appointment.serviceTax + appointment.retailTax;
        appointment.total = appointment.serviceTotal + appointment.retailTotal + appointment.discountTotal + appointment.retailDiscountTotal - appointment.promoAmount;
        
        // Clamp at zero
        if (appointment.total < 0) appointment.total = 0;
        if (appointment.subTotal < 0) appointment.subTotal = 0;
    }

    static applyPromoAmount(promoCodes, promoCodeID, total) {
        const promoCode = promoCodes.find(ap => ap.promoCodeID == promoCodeID);
        var promoAmount = 0;

        switch (promoCode.discountType) {
            case 'fixed':
                promoAmount = Number(promoCode.discountAmount) || 0;
                break;
            case 'percentage':
                promoAmount = Number(total * (promoCode.discountAmount || 0) / 100) || 0;
                break;
        }
        return promoAmount;
    }

    static async listTimeZones() {
        return BaseService.callAPI('appointment/list-time-zones');
    }

    static async getSettings(salonPeriodDate) {
        return BaseService.callAPI('appointment/get-settings', {
            salonPeriodDate
        });
    }

    static async saveSettings(settings) {
        return BaseService.callAPI('appointment/save-settings', settings);
    }

    static getCooldownPeriod(lastApptService, nextService) {
        if (lastApptService.service && (!nextService || !lastApptService.service.noCooldownIfOtherColour || !nextService.isColour)) {
            return lastApptService.service.cooldownPeriodMins;
        } else {
            return 0;
        }
    }

    static async saveNotes(appointment) {
        await BaseService.callAPI('appointment/save-notes', appointment);
    }

    //static async getRotaInfo() {
    //    const response = await BaseService.callAPI('appointment/get-rota-info');
    //    return response;
    //}
    
    static async listColours() {
        return BaseService.callAPI('appointment/list-diary-colours');
    }

    static async listRecentNotCheckedOutAppointments(customerID, ignoreIDs, asOfDate) {
        return BaseService.callAPI('appointment/list-recent-not-checked-out', {
            customerID,
            ignoreIDs,
            asOfDate
        });
    }

    static async issueRefund(refund) {
        return BaseService.callAPI('appointment/issue-refund', refund);
    }

    static async saveRotaDate(rotaDate) {
        return BaseService.callAPI('appointment/save-rota-date', rotaDate);
    }

	static async saveRotaDateRange(rotaDateRange) {
        return BaseService.callAPI('appointment/save-rota-date-range', rotaDateRange);
    }

    static async listPendingRefunds() {
        return BaseService.callAPI('appointment/list-pending-refunds');
    }
    
    static async listOnlineBookings() {
        return BaseService.callAPI('appointment/list-online-bookings');
    }

    static async getNumOnlineBookings() {
        return BaseService.callAPI('appointment/get-num-online-bookings');
    }

    static async getNumPendingRefunds() {
        return BaseService.callAPI('appointment/get-num-pending-refunds');
    }

    static async undoCheckOut(appointmentID) {
        return BaseService.callAPI('appointment/undo-check-out/' + appointmentID);
    }

    static async getTotals(dateFrom, dateTo, stylistUserID, requestID) {
        return BaseService.callAPI('appointment/get-totals', {
            dateFrom, dateTo, stylistUserID, requestID
        });
    }

    static async getCancellationInfo(id) {
        return BaseService.callAPI('appointment/get-cancellation-info/' + id);
    }

    static async listAppointmentLogs(id) {
        return BaseService.callAPI('appointment/list-logs/' + id);
    }

	static async getYearlyRota(year, stylistID) {
		return BaseService.callAPI(`appointment/get-yearly-rota/${year}/${stylistID}`);
    }

    static async saveOpeningRule(newRule) {
        return BaseService.callAPI('appointment/save-opening-rule', newRule);
    }

    static async listSalonOpeningRules(isExpired) {
        return BaseService.callAPI('appointment/list-opening-rules/' + isExpired);
    }

    static async getRule(id) {
        return BaseService.callAPI(`appointment/get-rule/${id}`);
    }

    static async deleteRule(id) {
        return BaseService.callAPI(`appointment/delete-rule/${id}`);
    }

    static async loadRefundPayments(id) {
        return BaseService.callAPI('appointment/get-refund-payments/' + id);
    }

    static async sendToCardMachine(totalAmount, checkOut) {
        return BaseService.callAPI('appointment/send-to-card-machine', {
            totalAmount, checkOut
        });
    }

    static async voidTransaction(transactionId, paymentDeviceId) {
        return BaseService.callAPI('appointment/void-transaction', {
            transactionId, paymentDeviceId
        });
    }

    static async refundTransaction(refundAmount, paymentDeviceId) {
        return BaseService.callAPI('appointment/refund-transaction', {
            refundAmount, paymentDeviceId
        });
    }

    static async partialRefundTransaction(refundAmount, paymentDeviceId) {
        return BaseService.callAPI('appointment/partial-refund-transaction', {
            refundAmount, paymentDeviceId
        });
    }

    static async getTransactionDetails(transactionId, paymentDeviceId) {
        return BaseService.callAPI('appointment/get-transaction-details', {
            transactionId, paymentDeviceId
        });
    }

    static loadApptPayments(id) {
        return BaseService.callAPI('appointment/get-appt-payments/' + id);
    }

    static async sendConfirmLinkSMS(customerID, appointmentID, isDepositLink) {
        return BaseService.callAPI('appointment/send-confirm-link-sms', {
            customerID,
            appointmentID,
            isDepositLink
        });
    }

    static async saveAppointmentCancelPayment(appointment) {
        return BaseService.callAPI('appointment/save-appt-cancel-payment', appointment);
    }

    static async checkApptHasDeposit(appointmentID) {
        const response = await BaseService.callAPI('appointment/check-appointment-has-deposit/' + appointmentID);
        return response;
    }
}

window.DiaryService = DiaryService;