/* eslint-disable no-unused-expressions */
// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

class SettingsMerchantServices extends FloomlyComponent {
    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            obSettings: null,
            posIds: null,
            isvId: '',
        };

        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                paymentProvider: {
                    label: 'Payment Provider',
                    type: 'single-select',
                    getOptions: () => [
                        { id: '', name: 'None' },
                        { id: 'evo', name: 'EVO' },
                        { id: 'stripe', name: 'Stripe' }
                    ]
                },
                defaultEvoIsvID: {
                    label: 'EVO ISV ID',
                    type: 'single-select',
                    getOptions: () => this.state.isvIds.map(ea => ({
                        id: ea.evoIsvId,
                        name: ea.evoIsvId
                    }))
                },
                defaultEvoPaymentDeviceID: {
                    label: 'EVO Payment Devices',
                    type: 'single-select',
                    getOptions: () => this.state.posIds.map(ea => ({
                        id: ea.paymentDeviceId,
                        name: ea.paymentDeviceName
                    }))
                },
                stripePublicKey: {
                    label: 'Stripe public key',
                    type: 'text'
                },
                stripeSecretKey: {
                    label: 'Stripe secret key',
                    type: 'text'
                },
                depositType: {
                    label: 'Deposit type',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'fixedAmount', name: 'Fixed amount' },
                        { id: 'percentage', name: 'Percentage' }
                    ]
                },
                depositAmount: {
                    label: () => (this.state.obSettings.depositType == 'fixedAmount' ? 'Amount' : 'Percentage'),
                    type: 'number'
                },
                depositMin: {
                    label: 'Minimum deposit amount',
                    type: 'number'
                },
                depositMax: {
                    label: 'Maximum deposit amount',
                    type: 'number'
                },
                termsConditionsLink: {
                    label: 'Terms & Conditions link',
                    type: 'text'
                },
                depositPageMessage: {
                    label: 'Override deposit page message',
                    type: 'multiline-text',
                    placeholder: 'e.g.: Before confirming your booking request, we require a 20% deposit, in line with our Terms & Conditions.'
                },
                captureCardDetailsMessage: {
                    label: 'Override capture card details message',
                    type: 'multiline-text',
                    placeholder: 'e.g.: You can provide card details to capture for future use.'
                },
                evoSecretKey: {
                    label: 'EVO secret key',
                    type: 'text'
                },
                paymentSolutionID: {
                    label: 'Payment Solution ID',
                    type: 'single-select',
                    getOptions: () => [
                        { id: '500', name: '500' }
                    ]
                },
                evoMerchantID: {
                    label: 'Merchant ID',
                    type: 'text'
                },
                evoAPIPassword: {
                    label: 'Api password',
                    type: 'password'
                },
            },
            getValue: (fieldName, fieldInfo) => this.state.obSettings[fieldName],
            setValue: this.updateField
        });
    }

    async load() {
        const obSettings = await OnlineBookingService.getMerchantServicesSettings() || {};
        const posIds = obSettings.evoPosIds;
        const isvIds = obSettings.evoIsvIds;
        this.setState({
            obSettings,
            posIds,
            isvIds,
            isLoading: false
        });
    }

    async save() {
        this.setState({ isLoading: true });
        try {
            await OnlineBookingService.saveMerchantSettings(this.state.obSettings);
            this.props.history.push('/settings');
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    async updateField(fieldName, value, fieldInfo, callback) {
        const obSettings = { ...this.state.obSettings };
        if (fieldName == 'defaultEvoIsvID') {
            var posIDs = await OnlineBookingService.getPosIDs(value);
            this.setState({
                posIds: posIDs
            })
            this.state.posIds.map(ea => {
                obSettings['defaultEvoPaymentDeviceID'] = ea.paymentDeviceId;
                if (ea.isDefault) {
                    obSettings['defaultEvoPaymentDeviceID'] = ea.paymentDeviceId;
                }
            })
        }
        obSettings[fieldName] = value;
        this.setState({ obSettings }, callback);
    }

    addPos() {
        this.props.history.push('/settings/merchant-services/new');
    }

    editPos() {
        let paymentDeviceId = this.state.obSettings.defaultEvoPaymentDeviceID;
        this.props.history.push('/settings/merchant-services/' + paymentDeviceId);
        document.querySelector('.main-content-inner').scrollTop = 0;
    }

    componentDidMount() {
        this.load();
    }

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar">
                <div className="info-bar-panel-section info-bar-panel-section-text">
                    Merchant Services
                </div>
                <div className="info-bar-panel-section ml-auto">
                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>
                </div>
            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            obSettings
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <>
                {this.renderInfoBar()}
                <div className="settings-page-main">

                    <div className="panel">
                        <div className="panel-body">
                            {this.formHelper.renderFormGroups([
                                'paymentProvider',
                                'depositType',
                                'depositAmount',
                                (obSettings.depositType == 'percentage' && 'depositMin'),
                                (obSettings.depositType == 'percentage' && 'depositMax'),
                                'termsConditionsLink',
                                'depositPageMessage',
                                (obSettings.paymentProvider == 'evo' && 'captureCardDetailsMessage')
                            ])}

                            {obSettings.paymentProvider === 'stripe' && this.formHelper.renderFormGroups([
                                'stripePublicKey',
                                'stripeSecretKey'
                            ])}

                            {obSettings.paymentProvider === 'evo' && this.formHelper.renderFormGroups([
                                'paymentSolutionID',
                                'evoMerchantID',
                                'evoAPIPassword',
                                'evoSecretKey',
                                'defaultEvoIsvID',
                                'defaultEvoPaymentDeviceID',

                            ])}
                            {obSettings.paymentProvider === 'evo' &&
                                <>
                                    <div>
                                        <button className="button button-primary button-small me-3" onClick={() => this.addPos()}>
                                            <span className="fa fa-plus"></span>{' '}
                                            Add Payment Device
                                        </button>

                                        {obSettings.defaultEvoPaymentDeviceID > 0 &&
                                            <button className="button button-primary button-small" onClick={() => this.editPos()}>
                                                <span className="fa fa-plus"></span>{' '}
                                                Edit/Remove Payment Device
                                            </button>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(SettingsMerchantServices);