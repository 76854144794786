// Libs
import React from 'react';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import Search from 'components/reusable/Search';

class TipsPanel extends FloomlyComponent {
	constructor(props) {
		super(props);

		this.state = {
			checkOut: props.checkOut,
			tipAmountFromDevice: props.tipAmountFromDevice,
			isLoading: true,
		}
		this.clientInfo = GlobalStateService.getValue('clientInfo');
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		await this.setStateAsync({
			isLoading: false
		});
	}

	async searchUsersForTips(query) {
		// Get a hash table of users already on the tips list
		const existingUserIDsHash = {};
		const appointment = this.state.checkOut;
		if (appointment.appointmentTips) {
			appointment.appointmentTips.forEach(t => {
				existingUserIDsHash[t.user.userID] = true;
			});
		}

		// Simplify search query
		query = TextHelpers.simplifySearchString(query);

		// Run the search
		const results = [];
		this.clientInfo.users.forEach(user => {
			// Ignore if already in the list
			if (existingUserIDsHash[user.userID]) {
				return
			}

			// Search by name
			if (!user.searchString) {
				user.searchString = TextHelpers.simplifySearchString(user.firstName + ' ' + user.lastName);
			}
			if (user.searchString.indexOf(query) != -1) {
				results.push(user);
			}
		});

		return results;
	}

	async selectUserForTip(user) {
		const appointment = this.state.checkOut;
		const appointmentTips = [...(appointment.appointmentTips || [])];
		let paymentMethodID = 1;
		appointmentTips.push({
			user: user,
			amount: '',
			paymentMethodID
		});
		await this.updateFields({ appointmentTips: appointmentTips });
		await this.props.onChange('appointmentTips', appointmentTips);
	}

	async updateAppointmentTipField(index, field, value) {
		let tipTotal = 0;
		const appointmentTips = [...this.state.checkOut.appointmentTips];
		appointmentTips[index][field] = value;
		appointmentTips.forEach(at => {
			tipTotal += at.amount;
		});		
		const amount = TextHelpers.formatCurrencyNew(this.state.tipAmountFromDevice, { includeSymbol: true });
		if (tipTotal > this.state.tipAmountFromDevice) {
			BootboxHelper.alert('Amount paid on the device is ' + amount + '. Please allocate the exact amount.');
		}
		else {
			await this.updateFields({ appointmentTips });
			await this.props.onChange('appointmentTips', appointmentTips);
		}
	}

	async updateFields(values) {
		const checkOut = { ...this.state.checkOut };

		for (var field in values) {
			let value = values[field];
			checkOut[field] = value;
		}
		if (checkOut.appointmentTips) {
			checkOut.appointmentTips.forEach(apptTip => {
				checkOut.tipTotal += (apptTip.amount || 0);
			});
		}

		this.props.onCheckOutChanged(checkOut);
		this.setState({
			checkOut
		});
	}

	removeTip(index) {
		const appointmentTips = [...this.state.checkOut.appointmentTips]
		appointmentTips.splice(index, 1);
		if (appointmentTips.length == 0) {
			this.setState({
				isAddingTips: false
			});
		}
		this.updateFields({ appointmentTips: appointmentTips });
		this.props.onChange('appointmentTips', appointmentTips);
	}

	renderTips() {
		const {
			tipAmountFromDevice,
			checkOut
		} = this.state;

		let showSearch = true; let tipTotal = 0;
		checkOut.appointmentTips.forEach(apptTip => {
			tipTotal += (apptTip.amount || 0);
		});
		
		if (tipTotal >= tipAmountFromDevice) {
			showSearch = false;
		}

		return (<>
			<li className="non-selectable">
				<span className="service-list-icon">
					<span className="far fa-smile"></span>
				</span>
				<span className="service-list-name">
					Tips from device
				</span>

				<span className="service-list-price">
					<Money amount={tipAmountFromDevice} />
				</span>
			</li>

			{checkOut.appointmentTips && checkOut.appointmentTips.map((apptTip, index) =>
				<React.Fragment key={index}>

					<li className="non-selectable">
						<span className="service-list-name">
							{apptTip.user.nickname}
						</span>

						<span className="service-list-price">
							<input type="number" autoFocus={true} value={apptTip.amount} onChange={e => this.updateAppointmentTipField(index, 'amount', Number(e.target.value) || '')} />
						</span>

						<span className="service-list-icon-right">
							<button className="button customer-summary-change-button" onClick={e => this.removeTip(index)}>
								<span className="fa fa-times"></span>
							</button>
						</span>
					</li>
				</React.Fragment>
			)}


			{showSearch &&
				<li className="non-selectable">
					<span className="service-list-name" style={{ marginRight: 0 }}>
						<div className="user-search search-box-absolute">
							<Search
								className="search-box"
								placeholder="Search for staff..."
								search={async (query, setResults) => {
									const results = await this.searchUsersForTips(query);
									setResults(results);
								}}
								renderResult={(result, srIndex, info) =>
									<div key={srIndex} className="search-result list-item" onMouseDown={e => {
										this.selectUserForTip(result);
										info.clearSearch();
									}}>
										<div className="list-item-name">
											{result.firstName + ' ' + result.lastName}
										</div>
									</div>
								}
							/>
						</div>
					</span>
				</li>
			}
		</>);
	}

	render() {
		const {
			isLoading,
		} = this.state;
		if (isLoading) {
			return (<Loader />);
		}
		return (<>
			<div className="panel payment-methods-panel">
				<div className="panel-header">
					Allocate Tips
				</div>
				<div className="panel-body">
					<ul className="list service-list">
						{this.renderTips()}
					</ul>
				</div>
			</div>
		</>
		)
	}

}

export default TipsPanel;