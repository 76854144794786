// Libs
import React from 'react';
import { Route } from 'react-router';
import { Link, withRouter, Prompt } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'components/reusable/DatePicker';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import FormHelper from 'helpers/FormHelper';
import DateHelpers from 'helpers/DateHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

//-------------------------------------------------------------------------------------------------------------------

class SettingsDiary extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            settings: null,
            addOrEditRule: false,
            isRuleUpdated: false,
            showExpiredRules: false
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                timeZoneLocation: {
                    label: 'Time Zone',
                    type: 'single-select',
                    getOptions: () => {
                        return this.state.timeZones.map(tz => ({
                            id: tz,
                            name: tz.replace(/\//g, ' / ').replace(/_/g, ' ')
                        }));
                    }
                },
                hideStylistsNotIn: {
                    label: 'Hide stylists on non-working days',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName, fieldInfo) => {
                switch (fieldName) {
                    case 'diaryInterval':
                        return this.state.settings.salonPeriod[fieldName];
                    default:
                        return this.state.settings[fieldName];
                }
            },
            setValue: this.updateField,
            onBlur: (fieldName, value, fieldInfo) => {
                if (fieldName == 'diaryInterval') {
                    this.setCurrentDiaryInterval(Number(value));
                }
            }
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        // Load info
        const getDiarySettingsResponse = await DiaryService.getSettings();
        const settings = getDiarySettingsResponse;
        const listTimeZonesResponse = await DiaryService.listTimeZones();
        const timeZones = listTimeZonesResponse;
        const listNewOpeningRules = await DiaryService.listSalonOpeningRules(false);
        const listExpiredRules = await DiaryService.listSalonOpeningRules(true);

        const newSalonTimes = {};
        const modifiedTimes = {};
        const selectedDates = [];
        // Create a lookup for opening times
        const openingTimes = {};
        if (!settings.salonPeriod) {
            settings.salonPeriod = {
                diaryInterval: 15,
                salonOpeningTimes: []
            };
        }
        DateHelpers.daysOfWeek.forEach(dow => {
            let openingTime = settings.salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dow);
            if (!openingTime) {
                openingTime = {
                    isOpen: false,
                    openingTime: null,
                    closingTime: null
                };
            }
            if (openingTime.openingTime) {
                openingTime.openingTime = DateHelpers.removeSeconds(openingTime.openingTime);
            }
            if (openingTime.closingTime) {
                openingTime.closingTime = DateHelpers.removeSeconds(openingTime.closingTime);
            }
            openingTimes[dow] = openingTime;
        });

        // Update UI
        this.setState({
            settings: settings,
            currentDiaryInterval: settings.salonPeriod.diaryInterval, // Kept separate so it can be updated onBlur instead
            timeZones: timeZones,
            openingTimes: openingTimes,
            newSalonTimes,
            modifiedTimes,
            selectedDates,
            listNewOpeningRules,
            listExpiredRules,
            isLoading: false
        });
        this.setNewRule();
    }

    updateField(fieldName, value, fieldInfo) {
        const settings = { ...this.state.settings };

        switch (fieldName) {
            case 'diaryInterval':
                settings.salonPeriod = { ...settings.salonPeriod };
                settings.salonPeriod[fieldName] = parseInt(value);
                break;
            default:
                settings[fieldName] = value;
                break;
        }

        this.setState({
            settings: settings
        });
    }

    updateOpeningTimesField(dayOfWeek, field, value) {
        const openingTimes = { ...this.state.openingTimes };
        if (openingTimes[dayOfWeek]) {
            openingTimes[dayOfWeek] = { ...openingTimes[dayOfWeek] };
        } else {
            openingTimes[dayOfWeek] = {};
        }
        openingTimes[dayOfWeek][field] = value;
        this.setState({
            openingTimes: openingTimes
        });
    }

    setDiaryInterval(value, field) {
        if (value >= 5 && value <= 60) {
            this.updateField('diaryInterval', value);
        }
    }

    setCurrentDiaryInterval(value) {
        if (value != this.state.currentDiaryInterval) {
            const openingTimes = { ...this.state.openingTimes };
            const newTimes = DateHelpers.listTimes(value);
            for (var dayOfWeek in openingTimes) {
                const salonOpeningTime = { ...openingTimes[dayOfWeek] };
                if (salonOpeningTime.openingTime) {
                    salonOpeningTime.openingTime = DateHelpers.findNearestTime(salonOpeningTime.openingTime, newTimes);
                }
                if (salonOpeningTime.closingTime) {
                    salonOpeningTime.closingTime = DateHelpers.findNearestTime(salonOpeningTime.closingTime, newTimes);
                }
                openingTimes[dayOfWeek] = salonOpeningTime;
            }

            this.setState({
                currentDiaryInterval: value,
                openingTimes: openingTimes
            });
        }
    }

    async save() {
        const settings = { ...this.state.settings };

        // Save opening times information
        settings.salonPeriod.salonOpeningTimes = [];
        for (var dayOfWeek in this.state.openingTimes) {
            const salonOpeningTime = this.state.openingTimes[dayOfWeek];
            salonOpeningTime.dayOfWeek = dayOfWeek;
            settings.salonPeriod.salonOpeningTimes.push(salonOpeningTime);
        }

        if (this.state.isRuleUpdated) {
            await BootboxHelper.alert('Please save the opening rule');
            return;
        }
        else {
            this.setState({ isLoading: true });
            await DiaryService.saveSettings(settings);
            this.props.history.push('/settings');
        }
    }

    async addNew() {
        let isContinue = true;
        if (this.state.salonTimingRuleID && this.state.isRuleUpdated) {
            const confirm = await BootboxHelper.confirm('Are you sure you want to exit without saving?');
            isContinue = confirm;
        }

        if (isContinue) {
            this.setNewRule();
            this.setState({
                addOrEditRule: true,
                salonTimingRuleID: null,
                isRuleUpdated: false
            }); 
        }   
    }

    setNewRule() {
        const newRule = {
            ruleType: null,
            name: "",
            salonPeriods: [],
            startDate: moment().startOf('day').toDate(),
            endDate: moment().startOf('day').toDate(),
            salonPeriod: {
                diaryInterval: this.state.settings.salonPeriod.diaryInterval,
                salonOpeningTimes: []
            },
        };
        this.setState({
            newRule
        })
    }

    async edit(id) {
        let isContinue = true;
        if (this.state.salonTimingRuleID != id && this.state.isRuleUpdated) {
            const confirm = await BootboxHelper.confirm('Are you sure you want to exit without saving?');
            isContinue = confirm;
        }
        if (isContinue) {
            const newRule = await DiaryService.getRule(id);
            this.setModifiedTimes(newRule);
            this.setState({
                addOrEditRule: true,
                salonTimingRuleID: id,
                isRuleUpdated: false,
                newRule
            })
        }
    }

    async cancel() {
        let isContinue = true;
        if (this.state.isRuleUpdated) {
            const confirm = await BootboxHelper.confirm('Are you sure you want to cancel without saving?');
            isContinue = confirm;
        }
        if (isContinue) {
            this.setState({
                addOrEditRule: false,
                isRuleUpdated: false
            });
            this.load();
        }
    }

    async delete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this rule?');
        if (confirm) {
            await DiaryService.deleteRule(this.state.salonTimingRuleID);
            this.setState({
                addOrEditRule: false,
                isRuleUpdated: false
            });
            this.load();
        }
    }

    async deleteExpiredRule(ruleID) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this expired rule permanently?');
        if (confirm) {
            await DiaryService.deleteRule(ruleID);
            await this.load();
            if (this.state.listExpiredRules.length == 0) {
                this.setState({
                    showExpiredRules: false
                });
            }
        }
    }

    startOfWeek(date) {
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    endOfWeek(date) {
        var lastday = date.getDate() - (date.getDay() - 1) + 6;
        return new Date(date.setDate(lastday));
    }

    setModifiedSalonTimes(rule) {
        const { newRule, modifiedTimes, selectedDates, settings } = this.state;
        let openingRule = rule ? rule : newRule;
        var salonPeriod = {};
        var sameWeek = false;
        selectedDates.forEach((sd, index) => {
            if (!sameWeek) {
                salonPeriod = openingRule.salonPeriods.find(sp => DateHelpers.toLocalDateString(this.startOfWeek(new Date(sp.startDate))) == DateHelpers.toLocalDateString(this.startOfWeek(new Date(sd))));
                if (!salonPeriod) {
                    salonPeriod = {
                        salonOpeningTimes: [],
                        diaryInterval: settings.salonPeriod.diaryInterval,
                        openClosedDays: [],
                        startDate: DateHelpers.stripTime(DateHelpers.toLocalDateString(new Date(sd)))
                    };
                    openingRule.salonPeriods.push(salonPeriod);
                }
                salonPeriod.startDate = DateHelpers.stripTime(DateHelpers.toLocalDateString(new Date(sd)));
                salonPeriod.salonOpeningTimes = [];
                salonPeriod.openClosedDays = [];
                sameWeek = true;
            }
            var salonOpeningTime = modifiedTimes[sd];
            salonOpeningTime.openingTime = DateHelpers.removeSeconds(salonOpeningTime.openingTime);
            salonOpeningTime.closingTime = DateHelpers.removeSeconds(salonOpeningTime.closingTime);
            salonPeriod.salonOpeningTimes.push(salonOpeningTime);
            if (!salonPeriod.openClosedDays.includes(salonOpeningTime.dayOfWeek))
                salonPeriod.openClosedDays.push(salonOpeningTime.dayOfWeek);
            if ((this.startOfWeek(new Date(sd))).toDateString() != (this.startOfWeek(new Date(selectedDates[index + 1]))).toDateString()) {
                sameWeek = false;
                salonPeriod.endDate = DateHelpers.stripTime(DateHelpers.toLocalDateString(new Date(sd)));
                DateHelpers.daysOfWeek.map(dow => {
                    var isDayExists = salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dow);
                    if (!isDayExists) {
                        var openingTime = {
                            dayOfWeek: dow
                        }
                        salonPeriod.salonOpeningTimes.push(openingTime);
                    }
                })
            }
        });
        this.setState({
            openingRule
        })
    }

    setNewSalonTimes(rule) {
        const { newSalonTimes, newRule } = this.state;
        let openingRule = rule ? rule : newRule;
        DateHelpers.daysOfWeek.map((dow) => {
            let openingTime = openingRule.salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dow);
            if (openingTime) {
                openingTime.isVisible = false;
                if (openingTime.openingTime)
                    openingTime.openingTime = DateHelpers.removeSeconds(openingTime.openingTime);

                if (openingTime.closingTime)
                    openingTime.closingTime = DateHelpers.removeSeconds(openingTime.closingTime);

                if (openingRule.ruleType == 'newOpeningTimes' && openingRule.openCloseRuleType == 'salonClosed') {
                    openingTime.openingTime = null;
                    openingTime.closingTime = null;
                    openingTime.isOpen = false;
                }
            }
            else {
                openingTime = {
                    isOpen: false,
                    isVisible: false,
                    openingTime: null,
                    closingTime: null,
                    dayOfWeek: dow
                };
            }
            newSalonTimes[dow] = openingTime;
        });
        this.setState({
            newSalonTimes
        });
    }

    setModifiedTimes(rule) {
        const { newRule } = this.state;
        let openingRule = rule ? rule : newRule;
        const start = new Date(openingRule.startDate);
        const end = new Date(openingRule.endDate);
        let selectedDate = start;
        var checkDate = start;
        var modifiedTimes = {};
        var selectedDates = [];
        if (openingRule.ruleType != 'newOpeningTimes') {
            while (DateHelpers.toLocalDateString(selectedDate) <= DateHelpers.toLocalDateString(end)) {
                var day = selectedDate.getDay() > 0 ? selectedDate.getDay() - 1 : 6;
                var dayOfWeek = DateHelpers.daysOfWeek[day];

                var salonPeriod = openingRule.salonPeriods.find(sp => DateHelpers.toLocalDateString(new Date(sp.startDate)) <= DateHelpers.toLocalDateString(checkDate)
                    && DateHelpers.toLocalDateString(new Date(sp.endDate)) >= DateHelpers.toLocalDateString(checkDate));

                var openingTime = salonPeriod && salonPeriod.salonOpeningTimes.find(sot => sot.dayOfWeek == dayOfWeek);
                if (openingTime) {
                    if (openingTime.openingTime)
                        openingTime.openingTime = DateHelpers.removeSeconds(openingTime.openingTime);

                    if (openingTime.closingTime)
                        openingTime.closingTime = DateHelpers.removeSeconds(openingTime.closingTime);
                } else {
                    openingTime = {
                        isOpen: false,
                        isVisible: false,
                        openingTime: null,
                        closingTime: null,
                        date: null
                    };
                }

                openingTime.isVisible = true;
                openingTime.date = checkDate.toDateString();
                openingTime.dayOfWeek = dayOfWeek;
                modifiedTimes[openingTime.date] = openingTime;
                if (!selectedDates.includes(openingTime.date))
                    selectedDates.push(openingTime.date);
                let newDate = checkDate.setDate(checkDate.getDate() + 1);
                selectedDate = new Date(newDate);
            }
        }
        this.setState({
            modifiedTimes,
            selectedDates
        }, () => {
            if (openingRule.ruleType != 'newOpeningTimes')
                this.setModifiedSalonTimes(openingRule);
            else
                this.setNewSalonTimes(openingRule);
        });
    }

    updateModifiedTimesField(date, field, value) {
        const modifiedTimes = { ...this.state.modifiedTimes };
        if (modifiedTimes[date]) {
            modifiedTimes[date] = { ...modifiedTimes[date] };
        } else {
            modifiedTimes[date] = {};
        }
        modifiedTimes[date][field] = value;
        this.setState({
            modifiedTimes: modifiedTimes
        }, () => { this.setModifiedSalonTimes(); });

    }

    updateNewTimesField(dayOfWeek, field, value) {
        const newSalonTimes = { ...this.state.newSalonTimes };
        if (newSalonTimes[dayOfWeek]) {
            newSalonTimes[dayOfWeek] = { ...newSalonTimes[dayOfWeek] };
        } else {
            newSalonTimes[dayOfWeek] = {};
        }
        newSalonTimes[dayOfWeek][field] = value;
        this.setState({
            newSalonTimes: newSalonTimes
        });
    }

    updateNewRule(fieldName, value) {
        const newRule = { ...this.state.newRule };
        newRule[fieldName] = value;
        if (fieldName == 'startDate') {
            if (this.checkDates(fieldName, value)) {
                newRule.salonPeriod[fieldName] = value;
            }
        }

        this.setState({
            newRule,
            isRuleUpdated: true
        }, () => {
            if (fieldName == 'startDate' || fieldName == 'endDate' || fieldName == 'openCloseRuleType') {
                this.setModifiedTimes();
            }
        });
    }

    checkDates(fieldName, value) {
        const { newRule } = this.state;

        if (fieldName == 'startDate') {
            if (newRule.ruleType == 'newOpeningTimes') {
                if (value < moment().startOf('day').toDate()) {
                    BootboxHelper.alert('New opening times should be start from future date.');
                    return false;
                }
            }
            else {
                var endDate = new Date(newRule.endDate);
                let difference_In_Time = endDate.getTime() - value.getTime();
                let differenceInDays = Math.round(difference_In_Time / (1000 * 3600 * 24));
                if (differenceInDays > 21) {
                    BootboxHelper.alert('Please select new opening times rule if selection is greater than 3 weeks');
                    return false;
                }
            }
        }
        else if (fieldName == 'endDate' && newRule.ruleType != 'newOpeningTimes') {
            var startDate = new Date(newRule.startDate);
            if (value < startDate) {
                BootboxHelper.alert('End date must be after start date');
                return false;
            }

            let difference_In_Time = value.getTime() - startDate.getTime();
            let differenceInDays = Math.round(difference_In_Time / (1000 * 3600 * 24));
            if (differenceInDays > 21) {
                BootboxHelper.alert('Please select new opening times rule if selection is greater than 3 weeks');
                return false;
            }
        }
        return true;
    }

    checkAll(value) {
        const modifiedTimes = { ...this.state.modifiedTimes };
        for (var mt in modifiedTimes) {
            var time = modifiedTimes[mt];
            time.isOpen = value;
            this.updateModifiedTimesField(time.date, 'isOpen', value);
        }
    }

    async saveNewRule() {
        if (this.checkRuleExists()) {
            const newRule = { ...this.state.newRule };
            newRule.startDate = DateHelpers.stripTime(DateHelpers.toLocalDateString(new Date(newRule.startDate)));
            newRule.endDate = DateHelpers.stripTime(DateHelpers.toLocalDateString(new Date(newRule.endDate)));
            if (!newRule.name) {
                BootboxHelper.alert('Please provide rule name.');
                return;
            }
            else if (!newRule.ruleType) {
                BootboxHelper.alert('Please select rule type.');
                return;
            }
            else if (newRule.ruleType != 'newOpeningTimes' && newRule.startDate > newRule.endDate) {
                BootboxHelper.alert('Start date must be before end date');
                return;
            }
            else if (!newRule.openCloseRuleType) {
                BootboxHelper.alert('Please select open/close type.');
                return;
            }
            this.setState({ isLoading: true });

            if (this.state.newSalonTimes)
                newRule.salonPeriod.salonOpeningTimes = [];
            for (var dayOfWeek in this.state.newSalonTimes) {
                const salonOpeningTime = this.state.newSalonTimes[dayOfWeek];
                salonOpeningTime.openingTime = DateHelpers.removeSeconds(salonOpeningTime.openingTime);
                salonOpeningTime.closingTime = DateHelpers.removeSeconds(salonOpeningTime.closingTime);
                salonOpeningTime.dayOfWeek = dayOfWeek;
                newRule.salonPeriod.salonOpeningTimes.push(salonOpeningTime);
            }
            await DiaryService.saveOpeningRule(newRule);
            this.load();
            this.setState({
                isLoading: false,
                isRuleUpdated: false,
                addOrEditRule: false
            });
        }
    }

    async expiredRules(value) {
        this.setState({
            showExpiredRules: value.trim() == 'Expired rules' ? true : false
        })
    }

    checkRuleExists() {
        const { listNewOpeningRules, selectedDates , newRule} = this.state;
        let isAlreadyExists = false;
        var oneTimeRules = listNewOpeningRules.filter(ln => ln.ruleType == 'oneTime');
        var newOpeningTimeRules = listNewOpeningRules.filter(ln => ln.ruleType == 'newOpeningTimes');
        for (var i = 0; i < selectedDates.length; i++){
            var date = new Date(selectedDates[i]);            
            var existingRule = oneTimeRules.find(ot => ot.startDate <= DateHelpers.toLocalDateString(date) &&
                ot.endDate >= DateHelpers.toLocalDateString(date));
            if (existingRule && existingRule.salonTimingRuleID != newRule.salonTimingRuleID) {
                isAlreadyExists = true;
                var selectedDate = moment(selectedDates[i]).format('ddd, MMM Do, YYYY');
                BootboxHelper.alert('Rule already exists for this date - ' + selectedDate + '. Please delete the existing one or modify the date.');
                break;
            }
        }
        if (selectedDates.length == 0 && newRule.ruleType == 'newOpeningTimes') {           
            existingRule = oneTimeRules.find(ot => ot.startDate <= DateHelpers.toLocalDateString(new Date(newRule.startDate)) &&
                ot.endDate >= DateHelpers.toLocalDateString(new Date(newRule.startDate)));
            existingRule = !existingRule ? newOpeningTimeRules.find(nt => nt.startDate == DateHelpers.toLocalDateString(new Date(newRule.startDate))) : existingRule;
            if (existingRule && existingRule.salonTimingRuleID != newRule.salonTimingRuleID) {
                isAlreadyExists = true;
                selectedDate = moment(newRule.startDate).format('ddd, MMM Do, YYYY');
                BootboxHelper.alert('Rule already exists for this date - ' + selectedDate + '. Please delete the existing one or modify the date.');
                return;
            }            
        }
        return !isAlreadyExists;
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Diary Settings
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    renderNewRulePanel() {
        const { newRule, salonTimingRuleID } = this.state;
        return (
            <div className="panel">
                <div className="panel-body">
                    <div>
                        <div className="new-add-rule">
                            <button className="button button-secondary button-small" onClick={() => this.saveNewRule()}>
                                <span className="fa fa-save" title="Save"></span>{' '}Save
                            </button>
                            <button className="button button-secondary button-small" onClick={() => this.cancel()}>
                                <span className="fa fa-close" title="Cancel"></span>{' '}Cancel
                            </button>
                            {salonTimingRuleID &&
                                <button className="button button-secondary button-small" onClick={() => this.delete()}>
                                    <span className="fa fa-trash" title="Delete"></span>{' '}Delete
                                </button>
                            }
                        </div>
                    </div>
                    <div className="date-range-panel">
                        <div className="right-margin">
                            <label className="mt-1 right-margin">Rule name</label>
                            <input className="rule-input"
                                type="text"
                                value={newRule.name}
                                onChange={e => this.updateNewRule('name', e.target.value)}
                            />
                        </div>
                        <div className="mt-1 right-margin">
                            <label className="mt-1 right-margin">Rule type</label>
                            <label className="mt-1 right-margin radio-margin">
                                <input
                                    type="radio"
                                    checked={newRule.ruleType == 'oneTime'}
                                    onChange={e => this.updateNewRule('ruleType', 'oneTime')}
                                />{' '}
                                One Time
                            </label>
                            {/*Will work on it after update*/}
                            {/*<label className="mt-3 right-margin">*/}
                            {/*    <input*/}
                            {/*        type="radio"*/}
                            {/*        checked={newRule.ruleType == 'repeatEveryYear'}*/}
                            {/*        onChange={e => this.updateNewRule('ruleType', 'repeatEveryYear')}*/}
                            {/*    />{' '}*/}
                            {/*    Repeat every year*/}
                            {/*</label>*/}
                            <label className="mt-1 right-margin">
                                <input
                                    type="radio"
                                    checked={newRule.ruleType == 'newOpeningTimes'}
                                    onChange={e => this.updateNewRule('ruleType', 'newOpeningTimes')}
                                />{' '}
                                New opening times
                            </label>
                        </div>
                        {newRule.ruleType && newRule.ruleType != 'newOpeningTimes' &&
                            <>
                                <div className="dates-panel mt-3">
                                    <label className="mt-1 right-margin">Range of dates</label>
                                    <div className="form-group">
                                        <DatePicker
                                            onChange={date => this.updateNewRule('startDate', date)}
                                            value={DateHelpers.stripTime(newRule.startDate)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <DatePicker
                                            onChange={date => this.updateNewRule('endDate', date)}
                                            value={DateHelpers.stripTime(newRule.endDate)}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {newRule.ruleType == 'newOpeningTimes' &&
                            <div className="dates-panel mt-3 mb-2">
                                <label className="mt-1 right-margin">Date valid from</label>
                                <div className="form-group">
                                    <DatePicker
                                        onChange={date => this.updateNewRule('startDate', date)}
                                        value={DateHelpers.stripTime(newRule.startDate)}
                                    />
                                </div>
                            </div>
                        }
                        {newRule.ruleType &&
                            <div className="right-margin">
                                <label className="mt-1 right-margin">Open/Closed</label>
                                <label className="mt-1 right-margin radio-margin">
                                    <input
                                        type="radio"
                                        checked={newRule.openCloseRuleType == 'salonClosed'}
                                        onChange={e => this.updateNewRule('openCloseRuleType', 'salonClosed')}
                                    />{' '}
                                    Salon closed
                                </label>
                                {newRule.ruleType != 'repeatEveryYear' && <label className="mt-1 right-margin">
                                    <input
                                        type="radio"
                                        checked={newRule.openCloseRuleType == 'changeOpeningTimes'}
                                        onChange={e => this.updateNewRule('openCloseRuleType', 'changeOpeningTimes')}
                                    />{' '}
                                    Change of opening times
                                </label>}
                            </div>
                        }

                        {newRule.openCloseRuleType == 'changeOpeningTimes' && newRule.ruleType != 'newOpeningTimes' && this.renderModifyTimesPanel()}
                        {newRule.openCloseRuleType == 'changeOpeningTimes' && newRule.ruleType == 'newOpeningTimes' && this.renderNewTimesPanel()}
                    </div>
                </div>
            </div>
        )
    }

    renderNewTimesPanel() {
        const { newSalonTimes, currentDiaryInterval } = this.state;
        const times = DateHelpers.listTimes(5);
        return (
            <>
                <div className="new-timings-panel">
                    <label className="mt-1 mb-1 right-margin timings-label">New salon opening times</label>
                    <table className="super-table settings-table opening-times-table">
                        <thead>
                            <tr>
                                <th></th>
                                {DateHelpers.daysOfWeek.map(d =>
                                    <th key={d}>
                                        {DateHelpers.getShortDayName(d)}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-align-left">
                                    Salon Open
                                </td>
                                {DateHelpers.daysOfWeek.map(d =>
                                    <td key={d} className="is-open-cell">
                                        <input
                                            type="checkbox"
                                            checked={newSalonTimes[d] && newSalonTimes[d].isOpen}
                                            onChange={e => this.updateNewTimesField(d, 'isOpen', e.target.checked)}
                                        />
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td className="text-align-left">
                                    Opening Time
                                </td>
                                {DateHelpers.daysOfWeek.map(d =>
                                    <td key={d}>
                                        {newSalonTimes[d] && newSalonTimes[d].isOpen &&
                                            <select
                                                value={newSalonTimes[d].openingTime}
                                                onChange={e => this.updateNewTimesField(d, 'openingTime', e.target.value)}
                                            >
                                                {times.map(t =>
                                                    <option key={t.value} value={t.value}>{t.text}</option>
                                                )}
                                            </select>
                                        }
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td className="text-align-left">
                                    Closing Time
                                </td>
                                {DateHelpers.daysOfWeek.map(d =>
                                    <td key={d}>
                                        {newSalonTimes[d] && newSalonTimes[d].isOpen &&
                                            <select
                                                value={newSalonTimes[d].closingTime}
                                                onChange={e => this.updateNewTimesField(d, 'closingTime', e.target.value)}
                                            >
                                                {times.map(t =>
                                                    <option key={t.value} value={t.value}>{t.text}</option>
                                                )}
                                            </select>
                                        }
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>)
    }

    renderModifyTimesPanel() {
        const { currentDiaryInterval, modifiedTimes, selectedDates } = this.state;
        const times = DateHelpers.listTimes(5);
        return (<>
            <div className="modified-timings-panel">
                <label className="mt-1 mb-1 right-margin timings-label">Modified salon opening times</label>
                <table className="super-table modified-times-table">
                    <thead>
                        <tr>
                            <th className="text-align-left">Day</th>
                            <th>Date</th>
                            <th> <input
                                type="checkbox"
                                onChange={e => this.checkAll(e.target.checked)}
                            /> </th>
                            <th>Opening time</th>
                            <th>Closing time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedDates.map(d =>
                            modifiedTimes[d].isVisible &&
                            <tr>
                                <td className="text-align-left">{DateHelpers.getShortDayName(modifiedTimes[d].dayOfWeek)}</td>
                                <td>{moment(modifiedTimes[d].date).format('DD/MM/YYYY')}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={modifiedTimes[d] && modifiedTimes[d].isOpen}
                                        onChange={e => this.updateModifiedTimesField(d, 'isOpen', e.target.checked)}
                                    />
                                </td>
                                <td>
                                    {modifiedTimes[d] && modifiedTimes[d].isOpen &&
                                        <select
                                            value={modifiedTimes[d].openingTime}
                                            onChange={e => this.updateModifiedTimesField(d, 'openingTime', e.target.value)}
                                        >
                                            {times.map(t =>
                                                <option key={t.value} value={t.value}>{t.text}</option>
                                            )}
                                        </select>
                                    }
                                </td>
                                <td>
                                    {modifiedTimes[d] && modifiedTimes[d].isOpen &&
                                        <select
                                            value={modifiedTimes[d].closingTime}
                                            onChange={e => this.updateModifiedTimesField(d, 'closingTime', e.target.value)}
                                        >
                                            {times.map(t =>
                                                <option key={t.value} value={t.value}>{t.text}</option>
                                            )}
                                        </select>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>)
    }

    render() {
        const {
            isLoading,
            openingTimes,
            settings,
            currentDiaryInterval,
            listNewOpeningRules,
            listExpiredRules,
            addOrEditRule,
            isRuleUpdated,
            showExpiredRules
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const times = DateHelpers.listTimes(5);

        return (<>
            {this.renderInfoBar()}

            <Prompt when={isRuleUpdated} message={() => {
                return "Oh wait! opening rule haven't been saved yet. Are you sure you want to leave?"
            }} />

            <div className="settings-page-main">
                <div className="panel">
                    {/*<div className="panel-header">Diary</div>*/}
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'timeZoneLocation'
                        ])}

                        <div className="form-group">
                            <label>Time Slot Size (minutes)</label>
                            <button type="button" className="button" onClick={e => this.setDiaryInterval(settings.salonPeriod.diaryInterval - 5)}>
                                <span className="fa fa-angles-left"></span>
                            </button>
                            <input
                                type="number"
                                step="5"
                                min="0"                                
                                className="duration-text"
                                disabled
                                value={settings.salonPeriod.diaryInterval || ''}
                            />
                            <button type="button" className="button" onClick={e => this.setDiaryInterval(settings.salonPeriod.diaryInterval + 5)}>
                                <span className="fa fa-angles-right"></span>
                            </button>
                        </div>

                        {this.formHelper.renderFormGroups([
                            'hideStylistsNotIn'
                        ])}

                        <div>
                            <label>Regular Opening Times</label>

                            <div className="table-container">
                                <table className="super-table settings-table opening-times-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {DateHelpers.daysOfWeek.map(d =>
                                                <th key={d}>
                                                    {DateHelpers.getShortDayName(d)}
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-align-left">
                                                Salon Open
                                            </td>
                                            {DateHelpers.daysOfWeek.map(d =>
                                                <td key={d} className="is-open-cell">
                                                    <input
                                                        type="checkbox"
                                                        checked={openingTimes[d] && openingTimes[d].isOpen}
                                                        onChange={e => this.updateOpeningTimesField(d, 'isOpen', e.target.checked)}
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td className="text-align-left">
                                                Opening Time
                                            </td>
                                            {DateHelpers.daysOfWeek.map(d =>
                                                <td key={d}>
                                                    {openingTimes[d] && openingTimes[d].isOpen &&
                                                        <select
                                                            value={openingTimes[d].openingTime}
                                                            onChange={e => this.updateOpeningTimesField(d, 'openingTime', e.target.value)}
                                                        >
                                                            {times.map(t =>
                                                                <option key={t.value} value={t.value}>{t.text}</option>
                                                            )}
                                                        </select>
                                                    }
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td className="text-align-left">
                                                Closing Time
                                            </td>
                                            {DateHelpers.daysOfWeek.map(d =>
                                                <td key={d}>
                                                    {openingTimes[d] && openingTimes[d].isOpen &&
                                                        <select
                                                            value={openingTimes[d].closingTime}
                                                            onChange={e => this.updateOpeningTimesField(d, 'closingTime', e.target.value)}
                                                        >
                                                            {times.map(t =>
                                                                <option key={t.value} value={t.value}>{t.text}</option>
                                                            )}
                                                        </select>
                                                    }
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    {listNewOpeningRules.length > 0 && !showExpiredRules &&
                        <>
                            <div className="additional-rule">Additional opening rules</div>
                            <SuperTable
                                className="rules-table"
                                rows={listNewOpeningRules}
                                keyAccessor={rule => rule.salonTimingRuleID}
                                cols={{
                                    name: { label: 'Rule Name' },
                                    description: { label: 'Description' }
                                }}
                                onClick={(rule, e) => this.edit(rule.salonTimingRuleID)}
                            />
                        </>
                    }
                </div>
                <div>
                    {listExpiredRules.length > 0 && showExpiredRules &&
                        <>
                            <div className="additional-rule">Expired opening rules</div>
                            <SuperTable
                                className="rules-table"
                                rows={listExpiredRules}
                                keyAccessor={rule => rule.salonTimingRuleID}
                                cols={{
                                    name: { label: 'Rule Name' },
                                    description: { label: 'Description' },
                                    actions: {
                                        className: 'actions-col',
                                        getValue: (colInfo, row) =>
                                            <button type="button" className="button delete-button" onClick={() => this.deleteExpiredRule(row.salonTimingRuleID)}>
                                                Delete
                                            </button>
                                    }
                            }}

                            />
                        </>
                    }
                </div>
                <div className="new-rule-panel">
                    <div className="new-add-rule">
                        {!showExpiredRules &&
                            <button className="button button-secondary button-small" onClick={() => this.addNew()}>
                                <span className="fa fa-calendar-plus"></span>{' '}
                                Add Alternative opening rule
                            </button>
                        }
                        {listExpiredRules.length > 0 &&
                            <button className="button button-secondary button-small" onClick={(e) => this.expiredRules(e.target.innerText)}>
                                <span className="fa fa-calendar-plus"></span>{' '}
                                {showExpiredRules ? 'Alternative opening rules' : 'Expired rules'}
                            </button>
                        }
                    </div>
                    <div>
                        {addOrEditRule &&
                            <>
                                {this.renderNewRulePanel()}
                            </>
                        }
                    </div>
                </div>
            </div>

        </>);
    }

};

export default withRouter(SettingsDiary);