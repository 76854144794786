// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import GlobalStateService from 'services/GlobalStateService';
import EvoService from 'services/EvoService';
import OnlineBookingService from 'services/OnlineBookingService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import CustomerSummary from 'components/reusable/CustomerSummary';

//-------------------------------------------------------------------------------------------------------------------

class Refund extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            salonPaymentProvider: null
        };

        this.viewCustomer = this.viewCustomer.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        // Load checkout info
        this.clientInfo = GlobalStateService.getValue('clientInfo');
        const refund = await DiaryService.loadCheckOut(this.props.appointment.appointmentID);
        const refundPayments = await DiaryService.loadRefundPayments(this.props.appointment.appointmentID);

        // As we're now doing a refund, store the original payment method and don't set a refund payment method yet
        refund.originalPaymentMethodID = refund.paymentMethodID;
        refund.paymentMethodID = null;
        let paymentMethods = await DiaryService.loadPaymentMethods();
        let cardPM = paymentMethods.find(pm => pm.code == 'Card');

        const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
        let defaultPaymentDevice = paymentDevices.find(pd => pd.isDefault);
        var paymentDeviceId = defaultPaymentDevice ? defaultPaymentDevice.paymentDeviceId : null;

        // Load payment methods

        const paymentThroughOnlineBooking = refund.appointmentPayments.some(ap => ap.paymentMethodName == 'Online Booking');
        const paymentThroughSavedCard = refund.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && (ap.paymentProvider == 'evo' || ap.paymentProvider == 'stripe'));
        const paymentThroughCardMachine = refund.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && ap.paymentProvider == 'posDevice');
        const paymentThroughCard = refund.appointmentPayments.some(ap => ap.paymentMethodName == 'Card' && ap.paymentProvider == null);

        paymentMethods = paymentMethods.filter(pm => pm.useForRefunds);

        if (!paymentThroughOnlineBooking)
        {
            paymentMethods = paymentMethods.filter(pm => pm.useForRefunds && pm.shortName != 'Online Booking');
        }

        if (!paymentThroughCardMachine && !paymentThroughSavedCard && !paymentThroughCard)
        {
            paymentMethods = paymentMethods.filter(pm => pm.useForRefunds && pm.shortName != 'Card');
        }

        //Add refund payment method if we have both card machine and saved card transactions
        if (paymentThroughSavedCard && paymentThroughCardMachine)
        {
            paymentMethods.push({
                paymentMethodID: 1,
                shortName: 'Saved Card',
                refundName: 'Refund via Saved Card',
                Code: 'Card'
            });
        }

        if (paymentThroughSavedCard && !paymentThroughCardMachine)
        {
            let paymentMethod = paymentMethods.find(pm => pm.shortName == 'Card');
            paymentMethod.shortName = 'Saved Card';
            paymentMethod.refundName = 'Refund via Saved Card';
        }

        if (paymentThroughCard && this.clientInfo.paymentProvider != 'evo') {
            let paymentMethod = paymentMethods.find(pm => pm.shortName == 'Card');
            paymentMethod.refundName = 'Refund via Card';
        }

        // Display
        this.setState({
            refund: refund,
            refundPayments: refundPayments,
            paymentMethods: paymentMethods,
            paidTotalViaPM: 0,
            refundPM: '',
            cardPM,
            paymentDevices,
            paymentDeviceId,
            isLoading: false,
            salonPaymentProvider : this.clientInfo.paymentProvider
        });
    }

    async updatePaymentMethodFields(values) {
        const paymentMethods = { ...this.state.paymentMethods };
        for (var field in values) {
            let value = values[field];
            paymentMethods[field] = value;
        }

        this.setState({
            paymentMethods: paymentMethods
        });
    }

    async updateFields(values) {
        const refund = { ...this.state.refund };
        const {
            paymentMethods,
            refundPayments,
            salonPaymentProvider,
            refundPM,
            paidTotalViaPM
        } = this.state;
        let paymentRefundTotal = 0;
        let apptPaidTotalThroughPM = 0;
        let paymentProvider;
        for (var field in values) {
            let value = values[field];
            refund[field] = value;
        }

        //show the total amount paid by the payment method to refund
        if (values.shortName) {

            const paymentMethod = paymentMethods.find(pm => pm.shortName == values.shortName);
            refund.refundName = paymentMethod.refundName;
            let appointmentPayments = refund.appointmentPayments;

            if (values.shortName == 'Saved Card') {
                appointmentPayments = refund.appointmentPayments.filter(ap => ap.paymentMethodID == values.paymentMethodID &&
                    ((ap.paymentProvider == 'stripe') || ap.paymentProvider == 'evo'));
            }
            else if (values.shortName == 'Card' && salonPaymentProvider == 'evo') {
                appointmentPayments = refund.appointmentPayments.filter(ap => ap.paymentMethodID == values.paymentMethodID && (ap.paymentProvider == 'posDevice'));
            }
            else if (values.shortName == 'Card' && salonPaymentProvider != 'evo') {
                appointmentPayments = refund.appointmentPayments.filter(ap => ap.paymentMethodID == values.paymentMethodID && !ap.paymentProvider);
            }

            if (refundPayments) {
                refundPayments.forEach(pr => {
                    paymentRefundTotal += pr.amount;
                });
            }

            if (appointmentPayments) {
                appointmentPayments.forEach(pr => {
                    apptPaidTotalThroughPM += pr.amount;
                    paymentProvider = pr.paymentProvider;
                });
                apptPaidTotalThroughPM = apptPaidTotalThroughPM - paymentRefundTotal;
            }

            this.setState({
                paidTotalViaPM: apptPaidTotalThroughPM,
                refundPM: values.shortName,
                refundPaymentProvider: paymentProvider
            })
            refund.amount = apptPaidTotalThroughPM;
        }
        if ((refundPM == "Card" || refundPM == "Saved Card" || refundPM == "Online Booking")
            && values.amount > paidTotalViaPM) {
            this.setState({
                disableRefund: true
            });
            const amount = TextHelpers.formatCurrencyNew(paidTotalViaPM, { includeSymbol: true });
            let message = `Please note amount paid through  <b>${refundPM}</b> is <b>${amount}</b>. Please modify the refund amount.`;
            BootboxHelper.alert(message);
        }
        else {
            this.setState({
                disableRefund: false
            })
        }

        this.setState({
            refund: refund
        });
    }

    async confirm() {
        const {
            refund,
            paymentMethods,
            paymentDeviceId
        } = this.state;

        // Validate
        refund.amount = (refund.amount || 0);
        if (refund.amount <= 0) {
            BootboxHelper.alert('Please specify amount to refund');
            return;
        }

        if (refund.refundName == "Refund via Card Machine") {            
            if (!paymentDeviceId) {
                BootboxHelper.alert('Please select a payment device');
                return;
            }
        }

        // Build confirm message
        const amount = TextHelpers.formatCurrencyNew(refund.amount, { includeSymbol: true });
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == refund.paymentMethodID);
        let message = `Issue refund for <b>${amount}</b>${paymentMethod ? ' by ' + paymentMethod.shortName : ''}?`;

        // Ensure they don't accidentally refund more than the value of the appointment
        let linkedAppointmentsTotal = 0;
        if (refund.linkedAppointments) {
            refund.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.total;
            });
        }
        const max = refund.total + linkedAppointmentsTotal - refund.amountRefunded;
        if (refund.amount > max) {
            message += '<br/><br/>(<b>Note that this is more than the total owing of ' + TextHelpers.formatCurrencyNew(max, { includeSymbol: true }) + '</b>)';
        }

        // Check for any card machine transactions and save if confirmed
        const confirm = await BootboxHelper.confirm(message);
        if (confirm) {
            refund.paymentProvider = this.state.refundPaymentProvider;
            if (this.state.refundPM == 'Card' && this.state.salonPaymentProvider == 'evo') {
                await this.checkForPosCardTransaction();
            }
            else {
                this.save();
            }
        }
    }

    async checkForPosCardTransaction() {
        const {
            refund,
            paymentDeviceId
        } = this.state;

        let response = await DiaryService.refundTransaction(refund.amount * 100, paymentDeviceId);
        BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');
        this.setState({ isLoading: true });
        this.checkTransactionCompleteInterval = setInterval(() => {
            this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId);
        }, 5000);

        this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
            BootboxHelper.alert('Pos is busy.Please complete the transaction on the terminal');
        }, 50000);

        this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
            this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
        }, 100000);
    }

    async partialRefund() {
        const {
            refund,
            paymentDeviceId
        } = this.state;

        let response = await DiaryService.partialRefundTransaction(refund.amount * 100, paymentDeviceId);

        BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');
        this.setState({ isLoading: true });
        this.checkTransactionCompleteInterval = setInterval(() => {
            this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId);
        }, 5000);

        this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
            BootboxHelper.alert('Pos is busy.Please complete the transaction on the terminal');
        }, 50000);

        this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
            this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
        }, 100000);
    }

    async checkForTransactionDetails(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == EvoService.RESPONSE_SUCCESS) {
            this.updateFields({
                refundTransactionId: transactionId
            });
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            this.save();
            this.setState({ isLoading: false });
        }
        else if (transactionResponse == EvoService.RESPOSE_CANCELLED
            || transactionResponse == EvoService.RESPONSE_REFUSED) {
            BootboxHelper.alert('Refund transaction cancelled on the device.');
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            this.setState({ isLoading: false });
        }
    }

    async checkForIncompleteTransaction(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == 'Fetching Details') {
            BootboxHelper.alert('Please make sure to select the correct payment device.');
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            this.load();
        }
    }

    async save() {
        this.setState({ isLoading: true });
        const { openCashDrawer } = await DiaryService.issueRefund(this.state.refund);
        if (openCashDrawer) {
            ThermalPrinterService.openCashDrawers(false);
        }
        this.props.onCompleted();
    }

    viewCustomer() {
        this.props.history.push('/customer/' + this.props.appointment.customer.customerID);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            refund,
            paymentMethods,
            paymentDevices,
            paymentDeviceId
        } = this.state;
        const {
            appointment
        } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        let linkedAppointmentsTotal = 0;
        if (refund.linkedAppointments) {
            refund.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.total;
            });
        }

        const originalPaymentMethod = paymentMethods.find(pm => pm.paymentMethodID == refund.originalPaymentMethodID);

        return (<>

            <CustomerSummary
                customerID={appointment.customer.customerID}
                onViewInfoClicked={this.viewCustomer}
            />

            <div className="panel refund-panel">
                <div className="panel-header">Refund</div>
                <div className="panel-body">

                    {this.renderServices()}

                    {this.renderPurchases()}

                    <div className="panel-info-header">
                        Totals
                    </div>

                    <ul className="list service-list">

                        {/* Total Services */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cut"></span>
                            </span>

                            <span className="service-list-name">
                                Total Services
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.serviceTotal} />
                            </span>

                        </li>

                        {/* Total Retail */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cash-register"></span>
                            </span>

                            <span className="service-list-name">
                                Total Retail
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.retailTotal} />
                            </span>

                        </li>

                        {/* Discount */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-arrow-down"></span>
                            </span>

                            <span className="service-list-name">
                                Discount
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.discountTotal} />
                            </span>

                        </li>

                        {/* Tips */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="far fa-smile"></span>
                            </span>

                            <span className="service-list-name">
                                Tips
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.tipTotal} />
                            </span>

                        </li>

                        {/* Other appointments */}
                        {linkedAppointmentsTotal > 0 &&
                            <li className="non-selectable">

                                <span className="service-list-icon">
                                    <span className="fas fa-calendar"></span>
                                </span>

                                <span className="service-list-name">
                                    Other appointments
                                </span>

                                <span className="service-list-price">
                                    <Money amount={linkedAppointmentsTotal} />
                                </span>

                            </li>
                        }

                        {/* Amount already refunded */}
                        {refund.amountRefunded > 0 &&
                            <li className="non-selectable highlight-text">

                                <span className="service-list-icon">
                                    <span className="fas fa-sad-tear"></span>
                                </span>

                                <span className="service-list-name">
                                    Already refunded
                                </span>

                                <span className="service-list-price">
                                    <Money amount={-refund.amountRefunded} />
                                </span>

                            </li>
                        }

                        {/* Total */}
                        <li className="non-selectable total-row line-above">

                            <span className="service-list-icon">
                                <span className="fas fa-money-bill"></span>
                            </span>

                            <span className="service-list-name">
                                Total
                            </span>

                            <span className="service-list-price">
                                <Money amount={refund.total + linkedAppointmentsTotal - refund.amountRefunded} />
                            </span>

                        </li>

                        {/* Method */}
                        {originalPaymentMethod &&
                            <li className="non-selectable">

                                <span className="service-list-name">
                                    Originally paid by
                                </span>

                                <span className="service-list-price">
                                    {originalPaymentMethod.shortName}
                                </span>

                            </li>
                        }


                    </ul>

                </div>

            </div>

            {/* Payment method */}
            <div className="panel payment-methods-panel">
                <div className="panel-header">Payment Method</div>
                <div className="panel-body">
                    <div className="button-stack">
                        {paymentMethods.map(paymentMethod => {
                            const isSelected = (paymentMethod.refundName == refund.refundName);
                            return (
                                <button
                                    key={paymentMethod.paymentMethodID}
                                    className={'button button-small button-selectable'}
                                    onClick={e => this.updateFields({ paymentMethodID: Number(paymentMethod.paymentMethodID), shortName: paymentMethod.shortName })}
                                >
                                    {isSelected && <>
                                        <i className='fas fa-check'></i>{' '}
                                    </>}
                                    {paymentMethod.refundName}
                                    {paymentMethod.code == 'Account' && <>
                                        <div className="button-subtitle">
                                            (Current: <Money amount={refund.customer.accountBalance} />)
                                        </div>
                                    </>}
                                </button>
                            );
                        })}
                    </div>

                </div>

            </div>
            {refund.refundName == 'Refund via Card Machine' && <>
                {paymentDevices.length == 0 && <>
                    You have no payment devices set up - please add some in the settings area.
                </>}
                <div>
                    <div className="non-selectable">
                        <span className="service-list-name">
                            Payment Device
                        </span>
                        <span className="service-list-price">
                            <select
                                style={{ width: '100%' }}
                                value={paymentDeviceId}
                                onChange={e => this.setState({ paymentDeviceId: e.target.value })}
                            ><option value="">(Select...)</option>
                                {paymentDevices.map(pd =>
                                    <option key={pd.paymentDeviceId} value={pd.paymentDeviceId}>{pd.paymentDeviceName}</option>
                                )}
                            </select>
                        </span>
                    </div>
                </div>
            </>}

            <div>
                {/* Refund Amount */}
                <ul className="list service-list">
                    <li className="non-selectable amount-to-refund line-above">

                        <span className="service-list-icon">
                            <span className="fas fa-sad-tear"></span>
                        </span>

                        <span className="service-list-name">
                            Refund amount
                        </span>

                        <span className="service-list-price">
                            <input
                                min="0"
                                type="number"
                                value={refund.amount || ''}
                                onChange={e => this.updateFields({ amount: Number(e.target.value) })}
                            />
                        </span>
                    </li>

                    <li className="non-selectable">

                        <textarea
                            rows="2"
                            value={refund.reason || ''}
                            onChange={e => this.updateFields({ reason: e.target.value })}
                            placeholder="Reason for refund..."
                        />

                    </li>
                </ul>
            </div>

            {/* Issue refund */}

            <button className={'button button-small ' + (this.state.disableRefund ? 'button-disabled' : 'button-primary')} onClick={e => this.confirm()}>
                {/*<i className="fas fa-sad-tear"></i>{' '}*/}
                Issue refund
            </button>

            {/* Back */}
            <button className="button button-secondary button-small" onClick={this.props.onBackClicked}>
                <i className="fas fa-arrow-left"></i>{' '}
                Back
            </button>

        </>);
    }

    renderServices() {
        const {
            isLoading,
            refund
        } = this.state;

        if (refund.appointmentPackages.length == 0 && refund.appointmentServices.length == 0 ) {
            return null;
        }

        const isFixedPricePackage = (apptPackageID) => {
            const apptPackage = refund.appointmentPackages.find(ap => ap.appointmentPackageID == apptPackageID);
            return apptPackage && apptPackage.isFixedPrice;
        };

        return (<>
            <div className="panel-info-header">
                Services
            </div>

            <ul className="list service-list">

                {refund.appointmentPackages && refund.appointmentPackages.map((apptPackage, index) =>
                    <li key={index} className="non-selectable">

                        <span className="service-list-icon">
                            <span className="fas fa-cubes"></span>
                        </span>

                        <span className="service-list-name">
                            {apptPackage.package.name}
                        </span>

                        {apptPackage.isFixedPrice &&
                            <span className="service-list-price">

                                <Money amount={apptPackage.total} />

                            </span>
                        }

                    </li>
                )}

                {refund.appointmentServices && refund.appointmentServices.map((apptService, index) =>
                    <li key={index} className="non-selectable">

                        <span className={'service-list-icon ' + (!!apptService.appointmentPackageID && 'service-list-icon-indent')}>
                            <span className="fas fa-cut"></span>
                        </span>

                        <span className="service-list-name">
                            {apptService.service.name}
                        </span>

                        <span className="service-list-price">

                            <Money amount={apptService.total} />

                        </span>

                    </li>
                )}

            </ul>

        </>);
    }

    renderPurchases() {
        const {
            isLoading,
            refund
        } = this.state;

        if (refund.appointmentPurchases.length == 0) {
            return null;
        }

        return (<>

            <div className="panel-info-header">
                Retail
            </div>

            <ul className="list service-list">
                {refund.appointmentPurchases && refund.appointmentPurchases.map((apptPurchase, index) =>
                    <li key={index} className="non-selectable">

                        <span className="service-list-icon">
                            <span className="fas fa-cash-register"></span>
                        </span>

                        <span className="service-list-name">
                            {apptPurchase.stockItem.product.name}
                        </span>

                        <span className="service-list-price">

                            <Money amount={apptPurchase.total} />

                        </span>

                    </li>
                )}
            </ul>

        </>);
    }

}

export default withRouter(Refund);