// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'components/reusable/DatePicker';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import OfficeService from 'services/OfficeService';
import GlobalStateService from 'services/GlobalStateService';
import SalonService from 'services/SalonService';
import DateHelpers from 'helpers/DateHelpers';
import ThermalPrinterService from 'services/ThermalPrinterService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Money from 'components/reusable/Money';
import WalkInPurchaseModal from './WalkInPurchaseModal';
import RetailRefundModal from './RetailRefundModal';

//-------------------------------------------------------------------------------------------------------------------

class TransactionSummary extends FloomlyComponent {

    constructor(props) {
        super(props);

		this.walkInPurchaseModalRef = React.createRef();
		this.retailRefundModelRef = React.createRef();

        // Init state
        this.state = {
            isLoading: true,
            //paymentMethods: null,
            date: moment().startOf('day').toDate()
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        let paymentMethodsReq;
        if (!this.state.paymentMethods) {
            paymentMethodsReq = DiaryService.loadPaymentMethods();
        }
        const getTransactionSummaryReq = OfficeService.getTransactionSummary(this.state.date);

        let paymentMethods = this.state.paymentMethods;
        if (!this.state.paymentMethods) {
            paymentMethods = await paymentMethodsReq;
        }
        const transactionSummary = await getTransactionSummaryReq; 
        
        this.setState({
            paymentMethods,
            transactionSummary,
            isLoading: false
        }, () => {
            //this.updateBalances();
        });
    }

    setDate(date) {
        this.setState({
            date: date
        }, () => {
            this.load();
        });
    }

    async goTo(transaction) {
        if (transaction.appointmentID) {
            this.props.history.push('/diary/' + moment(transaction.appointmentDate).format('YYYY-MM-DD') + '/' + transaction.appointmentID + '/check-out-payments');
        } else if (transaction.retailPurchaseID) {
            //walk-in transaction
			await this.walkInPurchaseModalRef.current.show({
				retailPurchaseID: transaction.retailPurchaseID
			})
			await this.load();
        } else if (transaction.refundID) {
			//refund retail purchase
			await this.retailRefundModelRef.current.show({
				refundID: transaction.refundID
			})
			await this.load();
		}
    }

    async print(transaction) {
        if (transaction.retailPurchaseID) {
            await ThermalPrinterService.printRetailPurchaseReceiptWithPDFFallback(transaction.retailPurchaseID);
        } else if (transaction.voucherID) {
            await ThermalPrinterService.printVoucherReceiptWithPDFFallback(transaction.voucherID);
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

	renderExtras() { 
		return (<>
			<WalkInPurchaseModal ref={this.walkInPurchaseModalRef} />
			<RetailRefundModal ref={this.retailRefundModelRef} />
		</>);
	}

    render() {
        const {
            isLoading,
            paymentMethods,
            transactionSummary,
            date
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const canEdit = loginDetails.permissions['OfficeEditEndOfDayBanking'];
        const currency = SalonService.getCurrentCurrency();

        return (<>

			{this.renderExtras()}

            <DatePicker
                onChange={date => this.setDate(date)}
                value={date}
            />

            <hr />

            <h2>Transaction Summary</h2>

            {transactionSummary.transactions.length > 0 &&
                <div className="table-container">
                    <table className="transaction-summary-table super-table">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Time</th>
                                <th>Description</th>
                                <th>In</th>
                                <th>Out</th>
                                <th>Payment Method</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionSummary.transactions.map((transaction, index) =>
                                <tr key={index}>
                                    <td>{transaction.customerName}</td>
                                    <td>{transaction.time ? DateHelpers.stripSeconds(transaction.time) : null}</td>
                                    <td>
                                        {transaction.description}
                                    
									    {(transaction.appointmentID || transaction.retailPurchaseID || transaction.refundID) && 
                                            <button className="button button-secondary button-small go-to-button" type="button" onClick={() => this.goTo(transaction)}>
                                                View
                                            </button>
                                        }
                                        {(transaction.retailPurchaseID || transaction.voucherID) &&
                                            <button className="button button-secondary button-small go-to-button" type="button" onClick={() => this.print(transaction)}>
                                                Print Receipt
                                            </button>
                                        }
                                    </td>
                                    <td>
                                        {transaction.amount > 0 &&
                                            <Money amount={transaction.amount} />
                                        }
                                    </td>
                                    <td>
                                        {transaction.amount < 0 &&
                                            <Money amount={-transaction.amount} />
                                        }
                                    </td>
                                    <td>{transaction.paymentMethodName}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            {transactionSummary.transactions.length == 0 && 
                <div className="empty-text">
                    No transactions to show for this date
                </div>
            }
        </>);
    }

};

export default withRouter(TransactionSummary);