// Libs
import React from 'react';
import moment from 'moment';

// Services & Helpers
import RetailService from 'services/RetailService';
import DiaryService from 'services/DiaryService';
import SearchService from 'services/SearchService';
import GlobalStateService from 'services/GlobalStateService';
import DateHelpers from 'helpers/DateHelpers';
import TextHelpers from 'helpers/TextHelpers';
import CustomerService  from 'services/CustomerService';
import OnlineBookingService from 'services/OnlineBookingService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Search from 'components/reusable/Search';
import Money from 'components/reusable/Money';
import DatePicker from 'components/reusable/DatePicker';
import Loader from 'components/reusable/Loader';

class PaymentPanel extends FloomlyComponent {
    constructor(props) {
        super(props);

        if (!(props.checkOut.paymentDate instanceof Date)) {
            props.checkOut.paymentDate = new Date(props.checkOut.paymentDate);
        }

        this.state = {
            checkOut: props.checkOut,
            paymentMethods: [],
            isLoading: true,
            savedCard: '',
            renderTips:true
        }

        this.toggleAddingTips = this.toggleAddingTips.bind(this);
        this.clientInfo = GlobalStateService.getValue('clientInfo');
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const {
            checkOut
        } = this.state;
        const { isRetail, isCancelPayment, cancelPaymentMethods } = this.props;

        let paymentMethods = cancelPaymentMethods ? cancelPaymentMethods : await DiaryService.loadPaymentMethodsFor(this.props.isRefund ? 'refund' : this.props.isRetail ? 'retail' : 'appointment', !!checkOut.customer);
        const cardPM = paymentMethods.find(pm => pm.code == 'Card');

        if (checkOut.apptVoucherList && checkOut.apptVoucherList.length > 0) {
            paymentMethods = paymentMethods.filter(pm => pm.code != 'Voucher' && pm.code != 'PreviousSystemVoucher');
        }

        const customerInfo = checkOut.customer ? await CustomerService.getSummary(
            checkOut.customer.id || checkOut.customer.customerID
        ) : null;

        var savedCard = customerInfo ? await CustomerService.getSavedCard(customerInfo.customerID) : null;
        if (savedCard) {
            savedCard = savedCard.paymentProvider == checkOut.paymentProvider ? savedCard : null;
            if (savedCard && !savedCard.consentToSaveCard) {
                savedCard = isCancelPayment ? savedCard : null;
            }
        }
        checkOut.savedCard = savedCard;

        const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
        let defaultPaymentDevice = paymentDevices.find(pd => pd.isDefault);
        checkOut.paymentDeviceId = defaultPaymentDevice ? defaultPaymentDevice.paymentDeviceId : null;

        // Remove/add payments according to balance
        let updateBalance = false;
        if (!isRetail && !isCancelPayment) {
            while (checkOut.newBalance < 0 && checkOut.newPayments.length > 0) {
                const lastPayment = checkOut.newPayments.pop();
                checkOut.newBalance += lastPayment.amount;
                updateBalance = true;
            }
        }

        if (checkOut.newBalance > 0 && paymentMethods.length > 0) {
            let dontAdd = false, isDepositAddByCard = false;
            if (checkOut.newPayments.length > 0 && !isCancelPayment) {
                const last = checkOut.newPayments[checkOut.newPayments.length - 1];
                if (checkOut.paymentProvider == 'evo') {
                    //dont round the balance if there are card terminal payments to add deposit
                    var pmID = cardPM ? cardPM.paymentMethodID : 0;
                    isDepositAddByCard = checkOut.isAddingDeposit && checkOut.newPayments.some(np => np.paymentMethodID == pmID) && !last.id;
                    dontAdd = isDepositAddByCard;
                }
                if (!last.id && !isDepositAddByCard) {
                    last.amount = Math.round((last.amount + checkOut.newBalance) * 100) / 100;
                    dontAdd = true;
                }
            }

            if (!dontAdd && !isCancelPayment) {
                checkOut.newPayments.push({
                    paymentMethodID: null,
                    amount: Math.round(checkOut.newBalance * 100) / 100,
                });
                checkOut.paymentDate = new Date();
            }
            updateBalance = true;
        }

        if (updateBalance) {
            DiaryService.updateBalance(checkOut, !!isRetail);
        }

        await this.setStateAsync({
            paymentMethods,
            isLoading: false,
            customerInfo,
            checkOut,
            savedCard,
            cardPM
        });
    }

    async updateFields(values) {
        const checkOut = { ...this.state.checkOut };
        for (var field in values) {
            let value = values[field];
            checkOut[field] = value;
        }

        var canApplyPromo = false, isPromoService = false;
        var promoCode = checkOut.apptPromoCodes?.find(ap => ap.promoCodeID == checkOut.promoCodeID);
        if (promoCode) {
            if (checkOut.appointmentServices) {
                checkOut.appointmentServices.forEach(asv => {
                    if (promoCode.promoServiceApplicability == 'selected') {
                        isPromoService = promoCode.servicePromoCodeIDs.includes(asv.service.serviceID);
                    }
                    else if (promoCode && promoCode.promoServiceApplicability == 'all') {
                        isPromoService = true;
                    }
                    asv.canApplyPromo = isPromoService;

                    if (asv.appointmentPackageID && !promoCode.useForPackage && isPromoService) {
                        asv.canApplyPromo = false;
                    }
                });
                canApplyPromo = checkOut.appointmentServices.some(as => as.canApplyPromo);
            }
        }

        checkOut.canApplyPromo = canApplyPromo;

        DiaryService.updateTotals(checkOut, this.props.promoCodes);
        DiaryService.updateBalance(checkOut, !!this.props.isRetail);

        this.props.onCheckOutChanged(checkOut);
        this.setState({
            checkOut
        });
    }

    listUnusedPaymentMethods() {
        const paymentMethodIDs = {};
        this.state.checkOut.newPayments.forEach(ap => {
            if (!ap.id) {
                paymentMethodIDs[ap.paymentMethodID] = true;
            }
        });
        return this.state.paymentMethods.filter(pm => !paymentMethodIDs[pm.paymentMethodID]);
    }

    async addPayment() {
        const checkOut = { ...this.state.checkOut };
        const newPayments = [...checkOut.newPayments];
        const unusedPaymentMethods = this.listUnusedPaymentMethods();
        newPayments.push({
            paymentMethodID: (unusedPaymentMethods.length > 0 ? unusedPaymentMethods[0].paymentMethodID : this.state.paymentMethods[0].paymentMethodID),
            amount: checkOut.newBalance
        });
        await this.updateFields({ newPayments });
        await this.props.onChange('newPayments', newPayments);
        if (checkOut.paymentProvider == 'evo')
            this.shouldRenderTipsSection();
    }

    async updatePaymentFields(index, values) {
        const newPayments = [...this.state.checkOut.newPayments];
        let paymentMethodChanged = null;
        for (let field in values) {
            const value = values[field];
            if (field == 'paymentMethodID' && newPayments[index][field] != value) {
                paymentMethodChanged = true;
            }
            newPayments[index][field] = value;
        }
        await this.updateFields({ newPayments });
        await this.props.onChange('newPayments', newPayments);
        if (paymentMethodChanged) {
            await this.onChangePaymentMethod(index);
        }
        if (this.props.isCancelPayment) {
            this.props.checkPayment(newPayments);
        }
        //Remove tips if tips paid through card terminal
        this.shouldRenderTipsSection();
    }

    shouldRenderTipsSection() {
        const checkOut = { ...this.state.checkOut };
        var pmID = this.state.cardPM ? this.state.cardPM.paymentMethodID : 0;
        const terminalPaymentMethods = checkOut.paymentProvider == 'evo'
            && checkOut.newPayments.some(np => !np.id && np.paymentMethodID == pmID && !np.isSavedCard);

        this.setState({
            renderTips: !terminalPaymentMethods
        });

        if (terminalPaymentMethods) {
            let appointmentTips = checkOut.appointmentTips;
            if (appointmentTips)
                appointmentTips.pop();
            checkOut.tipTotal = 0;
            this.updateFields({ appointmentTips });
        }
    }

    async confirmRemovePayment(index) {
        this.removePayment(index);
        //const confirm = await BootboxHelper.confirm(`Are you sure you want to remove this payment?`);
        //if (confirm) {
        //    this.removePayment(index);
        //}
    }

    async removePayment(index) {
        const newPayments = [...this.state.checkOut.newPayments];
        newPayments.splice(index, 1);
        await this.updateFields({ newPayments });
        await this.props.onChange('newPayments', newPayments);
    }

    async onChangePaymentMethod(index) {
        const {
            checkOut,
            paymentMethods,
            customerInfo,
        } = this.state;
        const appointmentPayment = checkOut.newPayments[index];
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == appointmentPayment.paymentMethodID);

        if (paymentMethod) {

            if (paymentMethod.code === 'Voucher') {
                this.updatePaymentFields(index, { amount: '' });

                // Get all unspent vouchers for this customer
                let vouchers = await RetailService.listUnspentVouchersForCustomer(checkOut.customer.id || checkOut.customer.customerID);

                // Exclude any already listed
                vouchers = vouchers.filter(v => !checkOut.newPayments.find(p => p.voucherID == v.id));

                //// If this is the first payment and we don't already have a voucher selected,
                //// select all vouchers at once
                //let autoAddAll = false;
                //if (index == 0 && checkOut.newPayments.length == 1 && !appointmentPayment.voucherID) {
                //    autoAddAll = true;
                //}

                // Pick the first voucher if any
                if (vouchers.length > 0) {
                    this.selectVoucher(index, vouchers[0]);
                } else {
                    this.selectVoucher(index, null);
                }
            }

            else if (paymentMethod.code === 'Account') {
                this.updatePaymentFields(index, {
                    amount: Math.max(0, Math.min(checkOut.newBalance + appointmentPayment.amount, customerInfo.accountBalance))
                });
            }

            else if (paymentMethod.code === 'LoyaltyPoints') {
                //TODO: handle case if there are not enough points during update

                this.updatePaymentFields(index, {
                    amount: Math.min(checkOut.newBalance + appointmentPayment.amount, customerInfo.loyaltyPointsValue)
                });
            }

        } else {
            this.selectVoucher(index, null);
        }
    }

    async selectVoucher(index, voucher) {
        const {
            checkOut
        } = this.state;
        const appointmentPayment = checkOut.newPayments[index];
        const values = {
            voucher,
            voucherID: voucher ? voucher.id : null
        };
        if (voucher) {
            values.amount = Math.min(checkOut.newBalance + appointmentPayment.amount, voucher.balance);
        } else {
            values.amount = '';
        }
        await this.updatePaymentFields(index, values);
    }

    async updateOverpaymentFields(index, values) {
        const newOverpayments = [...this.state.checkOut.overpayments];
        for (let field in values) {
            const value = values[field];
            newOverpayments[index][field] = value;
        }
        await this.updateFields({ newOverpayments });
        await this.props.onChange('overpayments', newOverpayments);
    }

    async removeOverpayment(index) {
        const overpayments = [...this.state.checkOut.overpayments];
        overpayments.splice(index, 1);
        await this.updateFields({ overpayments });
        await this.props.onChange('overpayments', overpayments);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Tips
    //--------------------------------------------------------------------------------------------------------------------

    async toggleAddingTips() {
        this.setState({
            isAddingTips: !this.state.isAddingTips
        });
    }

    async searchUsersForTips(query) {
        // Get a hash table of users already on the tips list
        const existingUserIDsHash = {};
        const appointment = this.state.checkOut;
        if (appointment.appointmentTips) {
            appointment.appointmentTips.forEach(t => {
                existingUserIDsHash[t.user.userID] = true;
            });
        }

        // Simplify search query
        query = TextHelpers.simplifySearchString(query);

        // Run the search
        const results = [];
        this.clientInfo.users.forEach(user => {
            // Ignore if already in the list
            if (existingUserIDsHash[user.userID]) {
                return
            }

            // Search by name
            if (!user.searchString) {
                user.searchString = TextHelpers.simplifySearchString(user.firstName + ' ' + user.lastName);
            }
            if (user.searchString.indexOf(query) != -1) {
                results.push(user);
            }
        });

        return results;
    }

    async selectUserForTip(user) {
        const appointment = this.state.checkOut;
        const appointmentTips = [...(appointment.appointmentTips || [])];
        let paymentMethodID;
        const paymentMethodsFiltered = this.state.paymentMethods.filter(pm => this.isEditablePaymentMethod(pm.code));
        if (paymentMethodsFiltered.length > 0) {
            paymentMethodID = paymentMethodsFiltered[0].paymentMethodID;
        }
        appointmentTips.push({
            user: user,
            amount: '',
            paymentMethodID
        });
        await this.updateFields({ appointmentTips: appointmentTips });
        await this.props.onChange('appointmentTips', appointmentTips);
    }

    async updateAppointmentTipField(index, field, value) {
        const appointmentTips = [...this.state.checkOut.appointmentTips];
        appointmentTips[index][field] = value;
        await this.updateFields({ appointmentTips });
        await this.props.onChange('appointmentTips', appointmentTips);
    }

    removeTip(index) {
        const appointmentTips = [...this.state.checkOut.appointmentTips]
        appointmentTips.splice(index, 1);
        if (appointmentTips.length == 0) {
            this.setState({
                isAddingTips: false
            });
        }
        this.updateFields({ appointmentTips: appointmentTips });
        this.props.onChange('appointmentTips', appointmentTips);
    }

    //--------------------------------------------------------------------------------------------------------------------

    static canGoNext(checkOut) {
        return checkOut.newBalance <= 0 && (checkOut.newPayments.every(ap => ap.id || !!ap.paymentMethodID) && checkOut.amountToPay > 0);
    }

    static canContinue(checkOut) {
        return checkOut.amountToPay > 0 && checkOut.amountToPay <= checkOut.total && checkOut.newPayments.every(ap => !!ap.paymentMethodID);
    }

    static canPayDeposit(checkOut) {
        return checkOut.newPayments.every(ap => ap.id || !!ap.paymentMethodID) && checkOut.amountToPay > 0;
    }

    //if Terminal payment send to card machine
    static updateButtonText(checkOut, buttonText, paymentMethods) {
        const paymentMethod = paymentMethods.find(pm => pm.code == 'Card');
        const cardPm = checkOut.newPayments.some(pm => pm.paymentMethodID == paymentMethod.paymentMethodID && !pm.id);
        const isTerminalPayment = checkOut.newPayments.some(pm => pm.paymentMethodID == paymentMethod.paymentMethodID && !pm.id && !pm.isSavedCard);
        if (cardPm && checkOut.savedCard) {
            if (!isTerminalPayment)
                return 'Process payment';
            else if (checkOut.paymentProvider == 'evo')
                return 'Send to card machine';
            else {
                return buttonText;
            }
        }
        else if (cardPm && isTerminalPayment && checkOut.paymentProvider == 'evo')
            return 'Send to card machine';
        else {
            return buttonText;
        }
    }

    isEditablePaymentMethod(code, ap) {
        if (ap && ap.id && (ap.paymentMethodName == 'Card' || ap.paymentMethodName == 'Online Booking'))
            return false;
        return true;
        //return ['Cash', 'Card', 'OtherCash', 'OtherCard'].indexOf(code) != -1;
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderPayment(ap, index, isLast) {
        const {
            paymentMethods,
            checkOut,
            customerInfo,
            savedCard,
            cardPM
        } = this.state;
        const {
            isRetail,
            isCancelPayment
        } = this.props;
        const canEdit = !ap.id || isRetail || isCancelPayment;

        const disableDate = ap.paymentMethodID == cardPM.paymentMethodID && !ap.isSavedCard && checkOut.paymentProvider == 'evo';
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == ap.paymentMethodID);
        const canEditPaymentMethod = (!ap.id || (paymentMethod && this.isEditablePaymentMethod(paymentMethod.code,ap)));
        let paymentMethodsFiltered;
        if (ap.id) {
            paymentMethodsFiltered = paymentMethods.filter(pm => this.isEditablePaymentMethod(pm.code));
        }
        else if (checkOut.paymentProvider == 'evo') {
            paymentMethodsFiltered = paymentMethods.filter(pm => pm.code != 'OtherCard');
        }
        else {
            paymentMethodsFiltered = paymentMethods;
        }

        return (<>
            <li className="non-selectable">

                <span className="service-list-name">

                    {canEditPaymentMethod ?
                        <select
                            style={{ width: '100%' }}
                            value={ap.paymentMethodID || ''}
                            onChange={e => this.updatePaymentFields(index, { paymentMethodID: e.target.value })}
                        >
                            {!ap.id &&
                                <option value="">(Select...)</option>
                            }
                            {paymentMethodsFiltered.map(paymentMethod =>
                                <option key={paymentMethod.paymentMethodID} value={paymentMethod.paymentMethodID}>
                                    {paymentMethod.shortName}
                                </option>
                            )}
                        </select> :
                        <>{ap.paymentMethodName || (ap.isDeposit ? 'Deposit' : 'Payment')}</>
                    }

                </span>

                <span className="service-list-price">

                    {canEdit ?
                        <input
                            type="number"
                            value={ap.amount}
                            onChange={e => this.updatePaymentFields(index, { amount: Number(e.target.value) || '' })}
                        /> :
                        <Money amount={ap.amount} />
                    }

                </span>

                {canEdit &&
                    <span className="floating-controls">

                        <button className="button customer-summary-change-button" onClick={e => this.confirmRemovePayment(index)}>
                            <span className="fa fa-times"></span>
                        </button>

                    </span>
                }

            </li>

            {paymentMethod && paymentMethod.code === 'Account' && !ap.id &&
                <li className="non-selectable payment-method-subtitle" key={index + '-a'}>

                    <span className="service-list-name">
                        Account Balance: <Money amount={customerInfo.accountBalance} />
                    </span>

                </li>
            }

            {paymentMethod && paymentMethod.code === 'Card' && !ap.id && savedCard &&
                <li className="non-selectable payment-method-subtitle" key={index + '-a'}>

                    <span className="service-list-name">
                        <input
                            id="saved-card"
                            class="saved-card"
                            type="checkbox"
                            checked={ap.isSavedCard}
                            onChange={e => this.updatePaymentFields(index, { isSavedCard: !ap.isSavedCard })}
                        />
                        <span><label htmlFor="saved-card">Charge Card ending in {savedCard.last4Digits}</label></span>
                    </span>
                </li>
            }

            {paymentMethod && paymentMethod.code === 'LoyaltyPoints' && !ap.id &&
                <li className="non-selectable payment-method-subtitle" key={index + '-lp'}>

                    <span className="service-list-name">
                        Points are worth: <Money amount={customerInfo.loyaltyPointsValue} />
                    </span>

                </li>
            }

            {paymentMethod && paymentMethod.code == 'Voucher' &&
                <li className={'non-selectable ' + (ap.voucher ? 'payment-method-subtitle' : '')} style={ap.voucher ? { height: 40 } : null} key={index + '-v'}>

                    {!!ap.voucher && <>
                        {ap.voucher.code || moment(ap.voucher.dateCreated).format('DD/MM/YYYY')}
                        {!ap.id && <>
                            {' '}(Balance: <Money amount={ap.voucher.balance} />)
                            {ap.voucher.code && <>
                                <p />(Date Issued: {moment(ap.voucher.dateCreated).format('DD/MM/YYYY')})
                            </>}

                            <span className="floating-controls">

                                <button className="button customer-summary-change-button" onClick={e => this.selectVoucher(index, null)}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </span>
                        </>}
                    </>}

                    {!ap.voucher && !ap.id &&
                        <div className="voucher-search search-box-absolute">

                            <Search
                                className="search-box"
                                autoFocus={true}
                                maxResults={25}
                                placeholder="Start typing voucher code..."
                                search={async (query, setResults, maxResults, state) => {
                                    const results = await SearchService.search(query, ['Voucher'], { maxResults, state });
                                    setResults(results);
                                }}
                                renderResult={(result, srIndex, info) =>
                                    <div key={srIndex} className="search-result list-item" onMouseDown={e => {
                                        this.selectVoucher(index, {
                                            id: result.id,
                                            code: result.name,
                                            balance: result.balance,
                                            dateCreated: result.dateCreated
                                        });
                                        info.clearSearch();
                                        info.focus();
                                    }}>
                                        <div className="list-item-name">
                                            {result.name}
                                        </div>
                                        <div className="list-item-price">
                                            <Money amount={result.balance} />
                                        </div>
                                    </div>
                                }
                            />

                        </div>
                    }

                </li>
            }

            {ap.id &&
                <>
                    {(ap.paymentMethodName == 'Card' && ap.paymentProvider == 'posDevice') &&
                        <li className="non-selectable payment-method-subtitle" key={index + '-pd'}>
                            <span className="service-list-name">
                                <>Paid via Pos Device</>
                            </span>
                        </li>
                    }

                    {(ap.paymentMethodName == 'Card' && ap.paidCardLast4Digits) && !this.props.isRetail && !this.props.isRefund &&
                        <li className="non-selectable payment-method-subtitle" key={index + '-pl'}>
                            <span className="service-list-name">
                                <>Paid using card ending in {ap.paidCardLast4Digits}</>
                            </span>
                        </li>
                    }

                    {!this.props.isRetail && !this.props.isRefund &&
                        <li className="non-selectable payment-method-subtitle" key={index + '-t'}>

                            <span className="service-list-name">
                                {/*Points are worth: <Money amount={checkOut.customer.loyaltyPointsValue} />*/}
                                {moment(ap.date).format('DD/MM/YYYY')}
                                {ap.time && <>
                                    {' '}at {DateHelpers.stripSeconds(ap.time)}
                                </>}
                            </span>

                        </li>}

                    {(this.props.isRetail || this.props.isRefund) && isLast && !isCancelPayment &&
                        <li className="non-selectable payment-date">

                            <span className="service-list-icon">
                                <span className="fas fa-calendar-day"></span>
                            </span>

                            <span className="service-list-name">
                                Date
                            </span>

                            {console.log("checkout.paymentDate", checkOut.paymentDate)}

                            <DatePicker
                                selected={checkOut.paymentDate}
                                onChange={value => this.updateFields({ paymentDate: DateHelpers.preserveLocal(value) })}
                                disabled={disableDate}
                            />
                        </li>}
                </>

            }
        </>);
    }

    renderTips() {
        const {
            isAddingTips,
            checkOut
        } = this.state;

        const paymentMethodsFiltered = this.state.paymentMethods.filter(pm => this.isEditablePaymentMethod(pm.code));

        return (<>
            <li className="non-selectable">

                <span className="service-list-icon">
                    <span className="far fa-smile"></span>
                </span>

                <span className="service-list-name">
                    Tips
                </span>

                <span className="service-list-icon">

                    <button className="button customer-summary-change-button" onClick={this.toggleAddingTips}>
                        <span className={'fa ' + (isAddingTips ? 'fa-arrow-left' : 'fa-pencil-alt')}></span>
                    </button>

                </span>

                <span className="service-list-price">

                    <Money amount={checkOut.tipTotal} />

                </span>

            </li>

            {isAddingTips && checkOut.appointmentTips && checkOut.appointmentTips.map((apptTip, index) =>
                <React.Fragment key={index}>

                    <li className="non-selectable">

                        <span className="service-list-icon">

                        </span>

                        <span className="service-list-name">

                            {apptTip.user.nickname}

                        </span>

                        <span className="service-list-price">

                            <input type="number" autoFocus={true} value={apptTip.amount} onChange={e => this.updateAppointmentTipField(index, 'amount', Number(e.target.value) || '')} />

                        </span>

                        <span className="service-list-icon-right">

                            <button className="button customer-summary-change-button" onClick={e => this.removeTip(index)}>
                                <span className="fa fa-times"></span>
                            </button>

                        </span>

                    </li>

                    <li className="non-selectable">

                        <span className="service-list-icon">

                        </span>

                        <select
                            value={apptTip.paymentMethodID || ''}
                            style={{ width: 'calc(100% - 65px)' }}
                            onChange={e => this.updateAppointmentTipField(index, 'paymentMethodID', e.target.value)}
                        >
                            {paymentMethodsFiltered.map(paymentMethod =>
                                <option key={paymentMethod.paymentMethodID} value={paymentMethod.paymentMethodID}>
                                    {paymentMethod.shortName}
                                </option>
                            )}
                        </select>

                    </li>
                </React.Fragment>
            )}

            {isAddingTips &&
                <li className="non-selectable">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name" style={{ marginRight: 0 }}>

                        <div className="user-search search-box-absolute">

                            <Search
                                className="search-box"
                                placeholder="Search for staff..."
                                search={async (query, setResults) => {
                                    const results = await this.searchUsersForTips(query);
                                    setResults(results);
                                }}
                                renderResult={(result, srIndex, info) =>
                                    <div key={srIndex} className="search-result list-item" onMouseDown={e => {
                                        this.selectUserForTip(result);
                                        info.clearSearch();
                                    }}>
                                        <div className="list-item-name">
                                            {result.firstName + ' ' + result.lastName}
                                        </div>
                                    </div>
                                }
                            />

                        </div>

                    </span>

                </li>
            }
        </>);
    }

    render() {

        const {
            checkOut,
            paymentMethods,
            isLoading,
            renderTips,
            cardPM
        } = this.state;

        const {
            isAddingDeposit,
            isRefund,
            isRetail,
            isCancelPayment
        } = this.props;

        if (isLoading) {
            return (<Loader/>);
        }

        const clientInfo = GlobalStateService.getValue('clientInfo');
        const anyUnsavedPayments = !!checkOut.newPayments.find(ap => !ap.id);
        const disableDate = checkOut.paymentProvider == 'evo' && checkOut.newPayments.some(ap => ap.paymentMethodID == cardPM.paymentMethodID && !ap.isSavedCard);

        // Determine whether we can give cashback or change
        let canGiveChange = false, canGiveCashback = false;
        checkOut.newPayments.map(p => {
            const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == p.paymentMethodID);
            if (paymentMethod) {
                switch (paymentMethod.code) {
                    case 'Card':
                    case 'OtherCard':
                    case 'OnlineBookings':
                    case 'BACS':
                    case 'ApplePay':
                    case 'GooglePay':
                        canGiveCashback = true;
                        break;
                    case 'Cash':
                    case 'OtherCash':
                        canGiveChange = true;
                        break;
                }
            }
        });

        return (<>

            <div className="panel payment-methods-panel">
                <div className="panel-header">
                    {isAddingDeposit ? 'Add Deposit' : isRefund ? 'Refund' : 'Payment'}
                </div>
                <div className="panel-body">

                    <ul className="list service-list">

                        {paymentMethods.length == 0 && <>
                            You have no payment methods set up - please add some in the settings area.
                        </>}

                        {checkOut.newPayments.map((ap, index) =>
                            <React.Fragment key={index}>
                                {this.renderPayment(ap, index, index === checkOut.newPayments.length - 1)}
                            </React.Fragment>
                        )}

                        {checkOut.newBalance > 0 && paymentMethods.length > 0 &&
                            <li className="non-selectable">

                                <button className="button button-tertiary search-box-button" onClick={e => this.addPayment()}>
                                    <span className="fa fa-plus"></span>{' '}
                                    {isRefund ? 'Add a refund method' : 'Add a payment method' }
                                </button>

                            </li>
                        }

                        {anyUnsavedPayments && !isCancelPayment &&
                            <li className="non-selectable payment-date">

                                <span className="service-list-icon">
                                    <span className="fas fa-calendar-day"></span>
                                </span>

                                <span className="service-list-name">
                                    Date
                                </span>

                                <DatePicker
                                    selected={checkOut.paymentDate}
                                    onChange={value => this.updateFields({ paymentDate: value })}
                                    disabled={disableDate}
                                />

                            </li>
                        }


                        {renderTips && clientInfo.enableTips && !isRetail && !isRefund && !isCancelPayment && this.renderTips()}

                        {/* Amount to Pay */}
                        {(checkOut.amountToPay + (checkOut.tipTotal - checkOut.tipsPaid)) > 0 &&
                            <li className="non-selectable remaining-balance">

                                <span className="service-list-icon">
                                    <span className="fas fa-check"></span>
                                </span>

                                <span className="service-list-name" style={{ fontWeight: 'bold' }}>
                                    {isRefund ? 'Total Amount to Refund' : 'Total Amount to Pay'}
                                </span>

                                <span className="service-list-price">

                                    <Money amount={checkOut.amountToPay + (checkOut.tipTotal - checkOut.tipsPaid)} />

                                </span>

                            </li>
                        }

                        {/* Balance to Pay */}
                        {!isCancelPayment && <li className="non-selectable remaining-balance" style={checkOut.newBalance > 0 ? { color: 'red' } : null}>

                            <span className="service-list-icon">
                                <span className="fas fa-balance-scale"></span>
                            </span>

                            <span className="service-list-name">
                                {isRefund ? 'Balance to Refund' : (checkOut.newBalance >= 0 ? 'Remaining Balance' : 'Overpayment')}
                            </span>

                            <span className="service-list-price">

                                <Money amount={checkOut.newBalance} />

                            </span>

                        </li>
                        }

                        {/*
                        <li>BALANCE (TEMP): {checkOut.newBalance}</li>
                        */}

                        {/* Overpayment */}
                        {!isRefund && !isCancelPayment && checkOut.newBalance < 0 && <>
                            {!!checkOut.customer &&
                                <li className="non-selectable overpayment line-above">

                                    <span className="service-list-icon">
                                        <span className="fas fa-coins"></span>
                                    </span>

                                    <span className="service-list-name">

                                        <label htmlFor="add-to-account">
                                            Add <Money amount={-checkOut.newBalance} /> to account
                                        </label>

                                    </span>

                                    <span className="service-list-price">

                                        <input
                                            id="add-to-account"
                                            type="radio"
                                            checked={checkOut.overpaymentAction == 'add-to-account'}
                                            onChange={e => this.updateFields({ overpaymentAction: 'add-to-account' })}
                                        />

                                    </span>

                                </li>}
                            {canGiveChange &&
                                <li className="non-selectable overpayment">

                                    <span className="service-list-icon">
                                    </span>

                                    <span className="service-list-name">

                                        <label htmlFor="give-change">
                                            Give <Money amount={-checkOut.newBalance} /> change
                                        </label>

                                    </span>

                                    <span className="service-list-price">

                                        <input
                                            id="give-change"
                                            type="radio"
                                            checked={checkOut.overpaymentAction == 'give-change'}
                                            onChange={e => this.updateFields({ overpaymentAction: 'give-change' })}
                                        />

                                    </span>

                                </li>
                            }
                            {canGiveCashback &&
                                <li className="non-selectable overpayment">

                                    <span className="service-list-icon">
                                    </span>

                                    <span className="service-list-name">

                                        <label htmlFor="give-cashback">
                                            Give <Money amount={-checkOut.newBalance} /> cashback
                                        </label>

                                    </span>

                                    <span className="service-list-price">

                                        <input
                                            id="give-cashback"
                                            type="radio"
                                            checked={checkOut.overpaymentAction == 'give-cashback'}
                                            onChange={e => this.updateFields({ overpaymentAction: 'give-cashback' })}
                                        />

                                    </span>

                                </li>
                            }
                        </>}

                        {/* Receipt */}
                        {!isAddingDeposit && !isCancelPayment &&
                            <li className="non-selectable receipt-row line-above" style={{ borderRadius: 0 }} onClick={e => this.updateFields({ printReceipt: !this.state.checkOut.printReceipt })}>

                                <span className="service-list-icon">
                                    <span className="fas fa-print"></span>
                                </span>

                                <span className="service-list-name">
                                    Print Receipt
                                </span>

                                <span className="service-list-price">

                                    <input
                                        type="checkbox"
                                        checked={checkOut.printReceipt}
                                        onChange={e => { /* NOP */ }}
                                    />

                                </span>

                            </li>
                        }

                        {!isAddingDeposit && !isCancelPayment &&
                            <li className="non-selectable receipt-row" onClick={e => this.updateFields({ emailReceipt: !this.state.checkOut.emailReceipt })}>

                                <span className="service-list-icon">
                                    <span className="far fa-envelope"></span>
                                </span>

                                <span className="service-list-name">
                                    Email receipt
                                </span>

                                <span className="service-list-price">

                                    <input
                                        type="checkbox"
                                        checked={checkOut.emailReceipt}
                                        onChange={e => { /* NOP */ }}
                                    />

                                </span>

                            </li>
                        }
                    </ul>

                </div>

            </div>

            {checkOut.overpayments && checkOut.overpayments.length > 0 &&
                <div className="panel overpayments-panel mt-3">
                    <div className="panel-header">
                        Overpayments
                    </div>
                    <div className="panel-body">

                        <ul className="list service-list">

                            {checkOut.overpayments.map((op, index) =>
                                <React.Fragment key={index}>
                                    <li className="non-selectable">

                                        <span className="service-list-name">

                                            {op.action == 'add-to-account' && <>Add to client account</>}
                                            {op.action == 'give-change' && <>Give change</>}
                                            {op.action == 'give-cashback' && <>Give cashback</>}

                                        </span>

                                        <span className="service-list-price">

                                            <input
                                                type="number"
                                                value={op.amount}
                                                onChange={e => this.updateOverpaymentFields(index, { amount: Number(e.target.value) || '' })}
                                            />

                                        </span>

                                        <span className="floating-controls">

                                            <button className="button customer-summary-change-button" onClick={e => this.removeOverpayment(index)}>
                                                <span className="fa fa-times"></span>
                                            </button>

                                        </span>

                                    </li>
                                </React.Fragment>
                            )}

                        </ul>

                    </div>
                </div>
            }
        </>);
    }
}

export default PaymentPanel;