// Libs
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Services
import SalonService from 'services/SalonService';

// Helpers
import MiscHelpers from 'helpers/MiscHelpers';

// Components
import DatePicker from 'components/reusable/DatePicker';

//-------------------------------------------------------------------------------------------------------------------

export default class FormHelper {

    constructor(opt) {
        opt = opt || {
            className: '',
            fields: {},
            getValue: (fieldName, fieldInfo) => console.log('Please specify getValue'),
            setValue: (fieldName, value, fieldInfo) => console.log('Please specify setValue')
        };
        this.fields = opt.fields;
        this.getValue = opt.getValue;
        this.setValue = opt.setValue;
        this.className = opt.className;
        this.onBlur = opt.onBlur;
        this.onValidate = opt.onValidate;
        this.isDisabled = opt.isDisabled;
        this.setClassName = opt.setClassName;
        this.validation = {
            isValid: false,
            hasValidated: {},
            errors: {}
        };
    }

    renderFormGroups(fieldNames, validation) {
        if (!fieldNames) {
            fieldNames = Object.keys(this.fields);
        }
        return fieldNames.filter(fn => !!fn).map(fieldName =>
            <React.Fragment key={fieldName}>
                {this.renderFormGroup(fieldName, validation)}
            </React.Fragment>
        );
    }

    renderFormGroup(fieldName, validation) {
        if (!fieldName) return;
        let fieldInfo = this.fields[fieldName];
        if (!fieldInfo) {
            fieldInfo = {
                label: fieldName
            };
        }

        const formGroupClasses = ['form-group'];
        if (fieldInfo.className) {
            formGroupClasses.push(fieldInfo.className);
        }
        if (fieldInfo.validation) {
            if (fieldInfo.validation.isRequired) {
                formGroupClasses.push('field-required');
            }
            if (validation && validation.errors[fieldName] && validation.errors[fieldName].length > 0) {
                formGroupClasses.push('field-error');
            }
        }

        const fieldRef = React.createRef();

        let label = fieldInfo.label;
        if (MiscHelpers.isFunction(label)) {
            label = label();
        }

        return (
            <div className={formGroupClasses.join(' ')}>
                {label &&
                    <label>
                        {label}
                        {!!fieldInfo.getLabelExtras && fieldInfo.getLabelExtras(fieldInfo, fieldRef)}
                    </label>
                }
                {fieldInfo.description &&
                    <div className="description">
                        {fieldInfo.description}
                    </div>
                }
                {this.renderField(fieldName, validation, fieldRef)}
            </div>
        );
    }

    renderFields(fieldNames, validation, fieldRef) {
        return fieldNames.map(fieldName =>
            <React.Fragment key={fieldName}>
                {this.renderField(fieldName, validation, fieldRef)}
            </React.Fragment>
        );
    }

    renderField(fieldName, validation, fieldRef) {
        if (!fieldName) return;
        let fieldInfo = this.fields[fieldName];
        if (!fieldInfo) {
            fieldInfo = {
                type: 'text',
                label: fieldName
            };
        }

        let checkLabel = fieldInfo.checkLabel;
        if (MiscHelpers.isFunction(checkLabel)) {
            checkLabel = checkLabel();
        }
        const type = (MiscHelpers.isFunction(fieldInfo.type) ? fieldInfo.type() : fieldInfo.type) || 'text';
        const step = fieldInfo.step;
        const min = fieldInfo.min;

        let isDisabled = fieldInfo.isDisabled || this.isDisabled;
        isDisabled = MiscHelpers.isFunction(isDisabled) ? isDisabled() : !!isDisabled;

        let value = this.getValue(fieldInfo.field || fieldName, fieldInfo) || '';
        if (type == 'radio-boolean') {
            value = this.getValue(fieldInfo.field || fieldName, fieldInfo);
        }
        if (fieldInfo.lastValue === null || typeof (fieldInfo.lastValue) === 'undefined') {
            fieldInfo.lastValue = value;
        }

        // Validate for the first time
        setTimeout(() => {
            if (!this.validation.hasValidated[fieldName]) {
                this.validation.hasValidated[fieldName] = true;
                this.validate(fieldName, fieldInfo, value);
            }
        }, 0);

        let field = null;
        switch (type) {
            case 'text':
            case 'password':
            case 'number':
            case 'tel':
            case 'search':
            case 'email':
            case 'color':
                field = (<>
                    <input
                        ref={fieldRef}
                        type={type}
                        step={step}
                        min={min}
                        className={(this.className || '') + ' ' + (fieldInfo.className || '')}
                        autoFocus={!!fieldInfo.autoFocus}
                        placeholder={fieldInfo.placeholder}
                        disabled={isDisabled}
                        value={value}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            if (this.onBlur && e.target.value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = e.target.value;
                                this.onBlur(fieldName, e.target.value, fieldInfo);
                            }
                        }}
                    />

                    {!!fieldInfo.getFieldExtras && fieldInfo.getFieldExtras(fieldInfo, value)}
                </>);
                break;
            //case 'checkbox':
            //    field = (
            //        <input
            //            type="checkbox"
            //            autoFocus={!!fieldInfo.autoFocus}
            //            className={this.className}
            //            checked={!!value}
            //            disabled={isDisabled}
            //            onChange={e => {
            //                this.setValue(fieldName, e.target.checked, fieldInfo);
            //                this.validate(fieldName, fieldInfo);
            //            }}
            //        />
            //    );
            //    break;
            case 'checkbox':
                field = (<>                   
                    <label className="checkbox-label">                        
                        <input
                            type="checkbox"
                            className={(this.className || '') + ' ' + (fieldInfo.className || '')}
                            autoFocus={!!fieldInfo.autoFocus}
                            checked={!!value}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.checked, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.checked);
                            }}
                        />
                        {checkLabel}
                        {fieldInfo.checkboxLabel &&
                            <span>{fieldInfo.checkboxLabel}</span>
                        }
                    </label>
                </>);
                break;
            case 'radio-boolean':
                field = (
                    <span className="boolean-label">
                        <span className="form-radio-label">Yes: </span>
                        <input
                            className="form-radio-button"
                            type="radio"
                            name={fieldName}
                            value={'true'}
                            id="true"
                            defaultChecked={!!value}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.value, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.value);
                            }
                            }
                        />
                        <span className="form-radio-label">No: </span>
                        <input
                            className="form-radio-button"
                            type="radio"
                            name={fieldName}
                            value={'false'}
                            id="false"
                            defaultChecked={value=='false' || value==false}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.value, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.value);
                            }
                            }
                        />
                    </span>
                );
                break;
            case 'multiline-text':
                fieldInfo.rows = fieldInfo.rows || 4;
                field = (<>
                    <textarea
                        ref={fieldRef}
                        className={(this.className || '') + ' ' + (fieldInfo.className || '')}
                        autoFocus={!!fieldInfo.autoFocus}
                        value={value}
                        rows={fieldInfo.rows}
                        disabled={isDisabled}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                            this.setClassNameOnCondition(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            if (this.onBlur && e.target.value != fieldInfo.lastValue) {
                                fieldInfo.lastValue = e.target.value;
                                this.onBlur(fieldName, e.target.value, fieldInfo);
                            }
                        }}
                    />

                    {!!fieldInfo.getFieldExtras && fieldInfo.getFieldExtras(fieldInfo, value)}
                </>);
                break;
            case 'single-select':
            case 'multi-select': {
                const getOptionValue = fieldInfo.getOptionValue || (o => o.id);
                const getOptionLabel = fieldInfo.getOptionLabel || (o => {
                    if (o.__isNew__) {
                        return o.label;
                    }
                    return o.name
                });
                fieldInfo.getOptions = fieldInfo.getOptions || (() => console.log('Please specify getOptions on field ' + fieldName));
                let options = fieldInfo.getOptions();
                if (type == 'multi-select') {
                    let options = fieldInfo.getOptions();
                    value = value || [];
                    const selectedOptions = options.filter(o => {
                        const optionID = getOptionValue(o);
                        const index = value.findIndex(so => String(so) == String(optionID));
                        return index != -1;
                    });
                    const SelectComponent = (fieldInfo.creatable ? CreatableSelect : Select);
                    field = (
                        <SelectComponent
                            className="select-box"
                            classNamePrefix="select-box"
                            isMulti={true}
                            isDisabled={isDisabled}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            options={options}
                            value={selectedOptions}
                            isClearable={fieldInfo.isClearable}
                            formatCreateLabel={fieldInfo.creatable ? fieldInfo.creatable.formatCreateLabel : undefined}
                            styles={{
                                option: fieldInfo.getOptionStyle,
                                multiValue: fieldInfo.getMultiValueStyle
                            }}
                            onChange={async selected => {
                                if (fieldInfo.creatable && fieldInfo.creatable.onCreateOption) {
                                    for (let i = 0; i < selected.length; i++) {
                                        let option = selected[i];
                                        if (option.__isNew__) {
                                            option = {
                                                id: option.value,
                                                name: option.label
                                            };
                                            option = await fieldInfo.creatable.onCreateOption(option);
                                            selected[i] = option;
                                        }
                                    }
                                }
                                const ids = selected.map(o => getOptionValue(o));
                                this.setValue(fieldName, ids, fieldInfo);
                                this.validate(fieldName, fieldInfo, ids);
                            }}
                        />
                    );
                } else {

                    if (fieldInfo.blankText) {
                        options = [{
                            id: '',
                            name: fieldInfo.blankText
                        }].concat(options);
                    }

                    const selectedOption = (options || []).find(o => value == getOptionValue(o));

                    field = (
                        <Select
                            className="select-box"
                            classNamePrefix="select-box"
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            options={options}
                            value={selectedOption}
                            isClearable={fieldInfo.isClearable}
                            isDisabled={isDisabled}
                            styles={{
                                option: fieldInfo.getOptionStyle,
                                singleValue: fieldInfo.getSingleValueStyle
                            }}
                            onChange={selected => {
                                const id = getOptionValue(selected);
                                this.setValue(fieldName, id, fieldInfo);
                                this.validate(fieldName, fieldInfo, id);
                            }}
                        />
                    );
                }

                break;
            }
            case 'date':
                if (value) {
                    value = moment(value).toDate();
                }
                field = (
                    <DatePicker
                        autoFocus={!!fieldInfo.autoFocus}
                        value={value}
                        disabled={isDisabled}
                        onChange={date => {
                            // Strip out time information
                            if (date) {
                                date = moment(date).local().format('YYYY-MM-DD');
                            }
                            this.setValue(fieldName, date, fieldInfo)
                            this.validate(fieldName, fieldInfo, date);
                        }}
                    />
                );
                break;
            case 'currency':
                const currency = SalonService.getCurrentCurrency();

                field = (
                    <NumberFormat
                        autoFocus={!!fieldInfo.autoFocus}
                        disabled={isDisabled}
                        thousandSeparator={currency.thousandSeparator}
                        decimalSeparator={currency.decimalSeparator}
                        prefix={currency.symbol}
                        value={value || ''}
                        onValueChange={(values) => {
                            this.setValue(fieldName, values.value);
                            this.validate(fieldName, fieldInfo, values.value);
                        }}
                    />
                );
                break;
        }

        return (<>
            {fieldInfo.renderField && fieldInfo.renderField(field, fieldInfo)}
            {!fieldInfo.renderField && field}
        </>);
    }

    validate(fieldName, fieldInfo, value) {
        const validation = fieldInfo.validation;
        if (validation) {
            this.validation.errors[fieldName] = [];
            const fieldErrors = this.validation.errors[fieldName];
            if (validation.isRequired) {
                if (value == null) {
                    fieldErrors.push(validation.requiredMessage || 'This field is required');
                }
            }
        }
        this.updateIsValid();
        if (this.onValidate) {
            this.onValidate(this.validation);
        }
    }

    updateIsValid() {
        this.validation.isValid = true;
        for (var fieldName in this.fields) {
            if (this.validation.errors[fieldName] && this.validation.errors[fieldName].length > 0) {
                this.validation.isValid = false;
                return;
            }
        }
    }

    getValidationSummary() {
        const errors = [];
        for (var fieldName in this.fields) {
            const validationErrors = this.validation.errors[fieldName];
            if (validationErrors) {
                validationErrors.forEach(e => {
                    errors.push({
                        fieldName: fieldName,
                        fieldLabel: this.fields[fieldName].label || fieldName,
                        error: e
                    })
                });
            }
        }
        return errors;
    }

    getValidationSummaryHTML() {
        const validationSummary = this.getValidationSummary();
        return ReactDOMServer.renderToString(<ul>
            {validationSummary.map((v, index) =>
                <li key={index}>
                    <b>{v.fieldLabel}</b>: {v.error}
                </li>
            )}
        </ul>);
    }

    setClassNameOnCondition(fieldName, fieldInfo, value) {
        if (this.setClassName(value) && (fieldName == 'smsTemplate' || fieldName == 'message')) {
            fieldInfo.className = 'sms-credit-error';
        }
        else {
            fieldInfo.className = '';
        }
    }
}
