// Libs
import React from 'react';

// Services & Helpers
import RetailService from 'services/RetailService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import DiaryService from 'services/DiaryService';
import PrintService from 'services/PrintService';
import CustomerService from 'services/CustomerService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

// Steps/Panels
import RetailPurchasePanel from './RetailPurchasePanel';
import PaymentPanel from '../checkout/PaymentPanel';

//-------------------------------------------------------------------------------------------------------------------

export default class RetailRefund extends FloomlyComponent {

	constructor(props) {
		super(props);

		this.retailPurchasePanelRef = React.createRef();
		this.paymentPanelRef = React.createRef();

		this.state = {
			step: 1,
			customer: null,
			isLoading: true,
			retailPurchase: null,
		};
		this.autoGoBackTimeOut = null;
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
	}

	fix(retailPurchase) {
		retailPurchase.newPayments = retailPurchase.newPayments || [];
		retailPurchase.paymentDate = retailPurchase.paymentDate || new Date();
	}

	async load() {
		if (this.props.refundID) {
			const retailPurchase = await RetailService.getRefund(this.props.refundID);

			this.fix(retailPurchase);

			DiaryService.updateBalance(retailPurchase);

			this.setState({
				retailPurchase
			})

			if (retailPurchase.customerID) {
				const customer = await CustomerService.get(retailPurchase.customerID);
				customer.id = customer.customerID;
				retailPurchase.customer = customer;

				this.setState({
					customer,
					retailPurchase
				});
			}
		}

		const paymentMethods = await DiaryService.loadPaymentMethods();
		this.setState({
			paymentMethods,
			isLoading: false,
		});
		this.onButtons(1);
	}

	goBack() {
		if (this.state.step === 1) {
			this.props.onClose();
		} else {
			this.setState({
				step: this.state.step - 1
			});
			this.onButtons(this.state.step - 1);
		}
	}

	goNext() {
		if (this.state.step === 1) {
			this.retailPurchasePanelRef.current.next();
		}
	}

	goToPayment(retailPurchase) {
		this.fix(retailPurchase);
		DiaryService.updateBalance(retailPurchase);	

		this.setState({ step: 2, retailPurchase });
		this.onButtons(2);
	}

	startOver() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
		this.setState({
			step: 1,
			customer: null,
			retailPurchase: null,
		});
		this.onButtons(1);
	}

	close() {
		if (this.autoGoBackTimeOut) {
			window.clearTimeout(this.autoGoBackTimeOut);
			this.autoGoBackTimeOut = null;
		}
		this.props.onClose();
	}

	onButtons(step) {
		if (this.props.onButtons) {
			switch (step) {
				case 1:
					this.props.onButtons([{
						key: "btn1",
						text: (<>Cancel refund</>),
						primary: false,
						click: () => this.close()
					}, {
						key: "btn2",
						text: (<>Continue refund{' '}<i className="fas fa-arrow-right"></i></>),
						primary: true,
						click: () => this.retailPurchasePanelRef.current.next(),
						active: () => RetailPurchasePanel.canGoNext(this.state.retailPurchase),
					}]);
					break;

				case 2:
					this.props.onButtons([{
						key: "btn3",
						text: (<><i className="fas fa-arrow-left"></i>{' '}Back</>),
						primary: false,
						click: () => this.goBack()
					}, {
						key: "btn4",
						text: "Issue refund",
						primary: true,
						click: async () => {
							await this.save();
							this.close();
						},
						active: () => PaymentPanel.canGoNext(this.state.retailPurchase),
					}]);
					break;
				default:
					break;
			}
		}
	}

	//--------------------------------------------------------------------------------------------------------------------
	// Retail purchase
	//--------------------------------------------------------------------------------------------------------------------

	updateRPTotals(retailPurchase) {
		retailPurchase.subTotal = 0;
		retailPurchase.total = 0;

		// Calculate purchase total
		retailPurchase.stockItems.forEach(si => {
			retailPurchase.subTotal += Number(si.total) || 0;
		});

		// Add discount
		retailPurchase.discountTotal = 0;
		switch (retailPurchase.discountType) {
			case 'amount':
				retailPurchase.discountTotal = -retailPurchase.discountAmount;
				break;
			case 'percentage':
				retailPurchase.discountTotal = -(retailPurchase.subTotal * retailPurchase.discountAmount / 100);
				break;
			default:
				break;
		}

		retailPurchase.total = retailPurchase.subTotal + retailPurchase.discountTotal;
	}

	updateRPFields(values) {

		const retailPurchase = { ...this.state.retailPurchase };
		for (var field in values) {
			retailPurchase[field] = values[field];
		}
		this.updateRPTotals(retailPurchase);
		this.setState({
			retailPurchase: retailPurchase
		});

		this.retailPurchasePanelRef.current.setRetailPurchase(retailPurchase);
	}

	canSave() {
		return PaymentPanel.canGoNext(this.state.retailPurchase);
	}

	async save() {
		if (!this.canSave()) {
			return;
		}

		const {
			retailPurchase
		} = this.state;

		this.setState({ isLoading: true });

		//retailPurchase.date = retailPurchase.paymentDate;

		// Save
		const { id, openCashDrawer } = await RetailService.issueRefund(retailPurchase);

		// Print
		if (retailPurchase.printReceipt) {
			PrintService.printURL('/api/retail/get-receipt-refund/' + id);
		}

		// Open cash drawer
		if (openCashDrawer) {
			ThermalPrinterService.openCashDrawers(false);
		}

		if (this.props.isStandalone) {
			this.close();
		} else {
			// Update UI
			this.setState({
				isLoading: false,
				step: 3
			});
			this.onButtons(3);

			// Automatically close and go back to menu (or whatever else)
			this.autoGoBackTimeOut = window.setTimeout(() => {
				this.close();
			}, 3 * 1000);
		}
	}

	renderPayment() {

		let {
			retailPurchase
		} = this.state;

		return (
			<>
				{!this.props.isStandalone &&
				<button className="button button-secondary button-small" onClick={_ => this.goBack()}>
					<span className="fas fa-arrow-left"></span>{' '}
					Back
				</button>}

				<PaymentPanel
					ref={this.paymentPanelRef}
					checkOut={retailPurchase}
					isRetail={true}
					isRefund={true}
					isCancelPayment={false}
					onCheckOutChanged={checkOut => this.setState({retailPurchase:checkOut})}
					onChange={(name,value) => { this.onButtons(2);}}
					/>

				{!this.props.isStandalone &&
				<div className="button-stack">

					<button className={"button button-primary button-small" + (this.canSave() ? '' : ' disabled')} onClick={_ => this.save()}>
						<i className="fas fa-check"></i>{' '}
						Issue refund
					</button>

					<button className="button button-tertiary button-small" onClick={_ => this.goBack()}>
						<i className="fas fa-arrow-left"></i>{' '}
						Back
					</button>

				</div>}
			</>
		);
	}

	renderRPComplete() {
		return (<>
			<div className="panel retail-purchase-complete-panel">
				<div className="panel-body">
					Refund complete
				</div>
			</div>

			<button className="button button-primary" onClick={_ => this.close()}>
				<i className="fas fa-check"></i>{' '}
				Back to Menu
			</button>
		</>);
	}

	render() {
		const {
			isLoading,
			step,
		} = this.state;

		if (isLoading) {
			return (<Loader />);
		}

		if (step === 1) {
			return (<>

				{!this.props.isStandalone && 
				<button className="button button-secondary button-small" onClick={_ => this.goBack()}>
					<span className="fas fa-arrow-left"></span>{' '}
					Back
				</button>}

				<RetailPurchasePanel
					ref={this.retailPurchasePanelRef}

					retailPurchase={this.state.retailPurchase}
					customer={this.state.customer}
					isStandalone={true}
					isRefund={true}

					onGoBack={() => this.goBack()}
					onGoFwd={retailPurchase => this.goToPayment(retailPurchase)}
					onCustomerSelected={customer => {
						let retailPurchase = this.state.retailPurchase;
						if (retailPurchase) {
							retailPurchase.customerID = customer?.id;
							retailPurchase.customer = customer;
							this.setState({ customer, retailPurchase });
						} else {
							this.setState({ customer });
						}
					}}
					onRetailPurchaseChanged={retailPurchase => {
						this.setState({ retailPurchase }); 
					}}
				/>

				<div className="panel retail-purchase">
					<div className="panel-body">
						<textarea
                                rows="2"
                                value={this.state.retailPurchase?.reason}
                                onChange={e => this.updateRPFields({ reason: e.target.value })}
                                placeholder="Reason for refund..."
                            />
					</div>
				</div>

				{!this.props.isStandalone && 
				<div className="button-stack">
					<button className={"button button-primary button-small" + (this.state.retailPurchase?.stockItems.length > 0 ? "" : " disabled")} onClick={_ => this.goNext()}>
						Continue refund{' '}
						<i className="fas fa-arrow-right"></i>
					</button>

					<button className="button button-tertiary button-small" onClick={_ => this.goBack()}>
						<i className="fas fa-arrow-left"></i>{' '}
						Cancel refund
					</button>

				</div>}

			</>);
		} else if (step === 2) {
			return this.renderPayment();
		} else if (step === 3) {
			return this.renderRPComplete();
		}
	}
}
