// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import UploadService from 'services/UploadService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import UploadButton from 'components/reusable/UploadButton';

//-------------------------------------------------------------------------------------------------------------------

class SettingsOnlineBooking extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            obSettings: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                enableOnlineBooking: {
                    checkboxLabel: 'Enable online booking',
                    type: 'checkbox'
                },
                obEnableReferralCode: {
                    checkboxLabel: 'Enable \'Referral Code\' box',
                    type: 'checkbox'
                },
                obColour1Back: {
                    label: 'Colour 1 (Background)',
                    type: 'color'
                },
                obColour1Fore: {
                    label: 'Colour 1 (Foreground)',
                    type: 'color'
                },
                obColour2Back: {
                    label: 'Colour 2 (Background)',
                    type: 'color'
                },
                obColour2Fore: {
                    label: 'Colour 1 (Foreground)',
                    type: 'color'
                },
                obColour3Back: {
                    label: 'Colour 3 (Background)',
                    type: 'color'
                },
                obColour3Fore: {
                    label: 'Colour 3 (Foreground)',
                    type: 'color'
                },
                obBackgroundStyle: {
                    label: 'Background style',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'default', name: 'Default' },
                        { id: 'transparent', name: 'Transparent' },
                        { id: 'solidColour', name: 'Solid Colour' },
                        { id: 'upload', name: 'Upload image' }
                    ]
                },
                obBackgroundSetting: {
                    label: '',
                    type: 'color'
                },
                obPatchTestMessage: {
                    label: 'Override default Patch Test message',
                    type: 'multiline-text'
                },
                obMaxMonthsAhead: {
                    label: 'Months ahead to allow bookings',
                    type: 'single-select',
                    getOptions: () => [
                        { id: '1', name: '1' },
                        { id: '2', name: '2' },
                        { id: '3', name: '3' },
                        { id: '4', name: '4' },
                        { id: '5', name: '5' },
                        { id: '6', name: '6' },
                        { id: '7', name: '7' },
                        { id: '8', name: '8' },
                        { id: '9', name: '9' },
                        { id: '10', name: '10' },
                        { id: '11', name: '11' },
                        { id: '12', name: '12' },
                    ]
                },
                enableDeposits: {
                    checkboxLabel: 'Take a deposit or capture card details',
                    type: 'checkbox'
                },

                obShowCustAccBalance: {
                    label: 'Show customer account balance',
                    type: 'checkbox'
                },
                obShowCustLoyalty: {
                    label: 'Show customer loyalty points',
                    type: 'checkbox'
                }

            },
            getValue: (fieldName, fieldInfo) => this.state.obSettings[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const obSettings = await OnlineBookingService.getSettings() || {};

        this.setState({
            obSettings,
            isLoading: false
        });
    }

    updateField(fieldName, value, fieldInfo, callback) {
        const obSettings = { ...this.state.obSettings };
        obSettings[fieldName] = value;
        if (fieldName == 'obBackgroundStyle') {
            if (value == 'solidColour') {
                obSettings.obBackgroundSetting = '#ffffff';
            } else if (value == 'upload' || value == 'url') {
                obSettings.obBackgroundSetting = '';
            }
        }
        this.setState({ obSettings }, callback);
    }

    async save() {
        this.setState({ isLoading: true });
        try {
            await OnlineBookingService.saveSettings(this.state.obSettings);
            this.props.history.push('/settings');
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    async uploadBackground(file) {
        const response = await UploadService.uploadFromFile(file, {
            type: 'OBBackground'
        });
        this.updateField('obBackgroundSetting', response.content, null);
    }

    removeBackground() {
        this.updateField('obBackgroundSetting', '', null);
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Online booking
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            obSettings
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">

                <div className="panel">
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'enableOnlineBooking'
                        ])}

                        {obSettings.enableOnlineBooking && <>

                            {this.formHelper.renderFormGroups([
                                'obEnableReferralCode',
                                'obColour1Back',
                                'obColour1Fore',
                                'obColour2Back',
                                'obColour2Fore',
                                'obColour3Back',
                                'obColour3Fore',
                                'obBackgroundStyle',
                                'obMaxMonthsAhead',
                            ])}

                            {this.renderBackgroundSetting()}

                            {this.formHelper.renderFormGroups([
                                'obPatchTestMessage',
                                'enableDeposits'
                            ])}

                            {this.formHelper.renderFormGroups([
                                'obShowCustAccBalance',
                                'obShowCustLoyalty'
                            ])}

                            {obSettings.enableDeposits &&
                                <Link to="/settings/merchant-services">
                                    <span className="fas fa-money-check-alt">

                                    </span>
                                    <span>
                                        Merchant Services (Please redirect to select the Payment Provider)
                                    </span>
                                </Link>
                            }
                        </>
                        }
                    </div>

                </div>

            </div>
        </>);
    }

    renderBackgroundSetting() {
        const {
            obSettings
        } = this.state;
        switch (obSettings.obBackgroundStyle) {
            case 'solidColour':
                return this.formHelper.renderFormGroups(['obBackgroundSetting']);
            case 'upload':
                return (<>

                    <div className="row">

                        <div className="col-md-3">

                            <div style={{
                                backgroundColor: '#efefef',
                                border: '2px solid #efefef',
                                padding: 10,
                                borderRadius: 10,
                                marginBottom: 10,
                                height: 200,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundImage: (obSettings.obBackgroundSetting ? `url(/api/upload/view/${obSettings.obBackgroundSetting})` : null)
                            }}>
                                {!obSettings.obBackgroundSetting &&
                                    <p>No image uploaded yet</p>
                                }
                            </div>

                            <UploadButton
                                onUpload={file => this.uploadBackground(file)}
                                className="button upload-button button-secondary mb-3"
                            >
                                <span className="fa fa-plus"></span>{' '}
                                Upload image
                            </UploadButton>

                            {!!obSettings.obBackgroundSetting &&
                                <button className="button button-small button-block button-tertiary" onClick={() => this.removeBackground()}>
                                    Remove image
                                </button>
                            }
                        </div>

                    </div>

                </>);
        }
    }
};

export default withRouter(SettingsOnlineBooking);