// Libs
import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import DatePicker from 'components/reusable/DatePicker';

// Services & Helpers
import ClientFinderService from 'services/ClientFinderService';
import ClientListService from 'services/ClientListService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class ClientFinder extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.nextFilterID = -1;

        this.state = {
            isLoading: true,
            isSaving: false,
            isLoadingCounts: false,
            counts: null,
            clientList: {
                name: '',
                synchroniseWithEmailProvider: true,
                selectedFilters: []
            }
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({
            isLoading: true
        });

        // Load
        const filters = await ClientFinderService.listFilters();

        // Create lookup for filters
        const filtersByID = {};
        filters.forEach(f => {
            filtersByID[f.id] = f;
        });

        // Update UI
        this.setState({
            isLoading: false,
            filters,
            filtersByID
        }, () => {
            this.getCounts();
        });
    }

    updateFilterField(id, field, value) {
        const selectedFilters = [...this.state.clientList.selectedFilters];
        const index = selectedFilters.findIndex(uf => uf.id == id);
        const selectedFilter = { ...selectedFilters[index] };

        // Convert string to bool
        if (field == 'isNot') {
            value = (value == 'true');
        }

        selectedFilter[field] = value;

        if (field == 'filterID' && !value && id != 0) {
            selectedFilters.splice(index, 1);
        } else if (id == 0) {
            selectedFilter.id = this.nextFilterID--;
            selectedFilter.paramValues = {};

            // Add to list
            selectedFilters.push(selectedFilter);
        } else {
            selectedFilters[index] = selectedFilter;
        }

        // Set default value for each param
        if (field == 'filterID') {
            selectedFilter.paramValues = {};
            const filter = this.state.filtersByID[selectedFilter.filterID];
            filter.params.forEach(filterParam => {
                if (filterParam.dataType == 'singleSelect' && filterParam.selectOptions.length > 0) {
                    selectedFilter.paramValues[filterParam.id] = filterParam.selectOptions[0].value;
                }
            });
        }

        this.updateFields({
            selectedFilters
        });
    }

    updateFilterParamValue(selectedFilterID, filterParamID, value) {
        const selectedFilters = this.state.clientList.selectedFilters;
        const index = selectedFilters.findIndex(uf => uf.id == selectedFilterID);
        const selectedFilter = { ...selectedFilters[index] };
        selectedFilter.paramValues = { ...selectedFilter.paramValues };
        selectedFilter.paramValues[filterParamID] = value;
        selectedFilters[index] = selectedFilter;
        this.updateFields({
            selectedFilters
        });
    }

    async confirmDeleteFilter(index) {
        const confirm = await BootboxHelper.confirm('Delete this filter?');
        if (confirm) {
            this.deleteFilter(index);
        }
    }

    deleteFilter(index) {
        const selectedFilters = [...this.state.clientList.selectedFilters];
        selectedFilters.splice(index, 1);
        this.updateFields({ selectedFilters });
    }

    async confirmResetFilters() {
        const confirm = await BootboxHelper.confirm('Reset all filters?');
        if (confirm) {
            this.resetFilters();
        }
    }

    resetFilters() {
        this.updateFields({ selectedFilters: [] });
    }

    debounceGetCounts() {
        clearTimeout(this.getCountsTimeout);
        this.getCountsTimeout = setTimeout(() => {
            this.getCounts();
        }, 1000);
    }

    async getCounts() {
        this.setState({
            isLoadingCounts: true
        });
        const counts = await ClientFinderService.getCounts(this.state.clientList.selectedFilters);
        this.setState({
            isLoadingCounts: false,
            counts
        });
    }

    updateFields(fields) {
        const clientList = { ...this.state.clientList };
        let getCounts = false;
        for (let fieldName in fields) {
            const value = fields[fieldName];
            if (fieldName == 'selectedFilters') {
                getCounts = true;
            }
            clientList[fieldName] = value;
        }
        this.setState({ clientList }, () => {
            if (getCounts) {
                this.debounceGetCounts();
            }
        });
    }

    async save() {
        this.setState({ isSaving: true });
        var id = await ClientListService.create(this.state.clientList);
        if (this.props.emailTemplateID) {
            this.props.history.replace(`/marketing/email-template/${this.props.emailTemplateID}`);
        } else {
            this.props.history.replace(`/marketing/client-lists/saved-lists/${id}`);
        }
        //this.setState({ isSaving: false });
       
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            filters,
            filtersByID,
            isLoadingCounts,
            counts
        } = this.state;
        const selectedFilters = this.state.clientList.selectedFilters.concat({ id: 0 });

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <div className="client-finder">

                {/* FILTERS */}
                <section className="filters">

                    <h2>Filters</h2>

                    {selectedFilters.map((selectedFilter, index) =>
                        <div key={selectedFilter.id} className="filter">

                            {/* SELECT TYPE */}
                            <select value={selectedFilter.id == 0 ? '' : selectedFilter.filterID} onChange={e => this.updateFilterField(selectedFilter.id, 'filterID', e.target.value)}>
                                {selectedFilter.id == 0 &&
                                    <option value="">(Select...)</option>
                                }
                                {filters.map(f =>
                                    <option value={f.id} key={f.id}>{f.name}</option>
                                )}
                            </select>

                            {/* PARAMS */}
                            {selectedFilter.filterID && filtersByID[selectedFilter.filterID].params.map(filterParam =>
                                <div key={filterParam.id} className="filter-param">
                                    {this.renderFilterParam(selectedFilter, filterParam)}
                                </div>
                            )}

                            {selectedFilter.id != 0 && <>
                                {/* THEN ACTION */}
                                {/*
                                <select value={selectedFilter.isNot} onChange={e => this.updateFilterField(selectedFilter.id, 'isNot', e.target.value)}>
                                    <option value="false">Show client</option>
                                    <option value="true">Hide client</option>
                                </select>
                                */}

                                {/* DELETE BUTTON */}
                                <button className="button remove-button" tabIndex="-1" onClick={e => this.confirmDeleteFilter(index)}>
                                    <span className="fa fa-times"></span>
                                </button>
                            </>}

                        </div>
                    )}

                    {/* CONTROLS */}
                    {selectedFilters.length > 0 && selectedFilters[0].id != 0 &&
                        <button className="button button-tertiary reset-filters mb-3" onClick={e => this.confirmResetFilters()}>
                            <span className="fa fa-undo"></span>{' '}
                            Reset filters
                        </button>
                    }

                    {this.renderSaveControls()}

                </section>

                {/* MATCHING CLIENTS */}
                <section className="matching-clients">

                    <div className="counts">

                        <figure>
                            <span className="stat">
                                {isLoadingCounts || !counts ? <Loader isInline /> : counts.total}
                            </span>
                            <figcaption>Matching clients</figcaption>
                        </figure>

                        <figure>
                            <span className="stat">
                                <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.forEmail}</span>
                                <span className="method">Email</span>
                                <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.forSMS}</span>
                                <span className="method">SMS</span>
                            </span>
                            <figcaption>Contactable</figcaption>
                        </figure>

                        <figure>
                            <span className="stat">
                                <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.optInEmail}</span>
                                <span className="method">Email</span>
                                <span>{isLoadingCounts || !counts ? <Loader isInline /> : counts.optInSMS}</span>
                                <span className="method">SMS</span>
                            </span>
                            <figcaption>Marketing Opt-In</figcaption>
                        </figure>


                    </div>

                    {this.renderSaveControls()}

                </section>

            </div>
        );
    }

    renderFilterParam(selectedFilter, filterParam) {
        const value = selectedFilter.paramValues[filterParam.id];

        switch (filterParam.dataType) {
            case '':
            case 'text':
            case 'number':
                return (<>
                    <label>{filterParam.label || <>&nbsp;</>}</label>
                    <input
                        type={filterParam.dataType || 'text'}
                        placeholder={filterParam.placeholder}
                        value={value || ''}
                        onChange={e => this.updateFilterParamValue(selectedFilter.id, filterParam.id, e.target.value)}
                    />
                </>);
                break;
            case 'singleSelect':
                return (
                    <select
                        value={value || ''}
                        onChange={e => this.updateFilterParamValue(selectedFilter.id, filterParam.id, e.target.value)}
                    >
                        {filterParam.selectOptions.map(bo =>
                            <option key={bo.value} value={bo.value}>
                                {bo.text}
                            </option>
                        )}
                    </select>
                );
            case 'date':
                return (
                    <DatePicker
                        value={value ? moment(value).toDate() : null}
                        placeholderText={filterParam.placeholder}
                        clearIcon={null}
                        onChange={value => this.updateFilterParamValue(selectedFilter.id, filterParam.id, value)}
                    />
                );
                break;
        }
        return (<>{filterParam.dataType}</>);
    }

    renderSaveControls() {
        const {
            clientList,
            isSaving
        } = this.state;

        return (
            <div className="save-controls">

                <div className="form-group">
                    <label>List Name</label>
                    <input
                        type="text"
                        value={clientList.name || ''}
                        onChange={e => this.updateFields({ name: e.target.value })}
                        disabled={isSaving}
                    />
                </div>

                <div className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={!!clientList.synchroniseWithEmailProvider}
                            onChange={e => this.updateFields({ synchroniseWithEmailProvider: e.target.checked })}
                            disabled={isSaving}
                        />{' '}
                        Synchronise with email provider
                    </label>
                </div>

                <button
                    className="button button-primary save-button"
                    onClick={e => this.save()}
                    disabled={isSaving}
                >
                    <span className="fa fa-check"></span>{' '}
                    Save
                </button>

            </div>
        );
    }
}

export default withRouter(ClientFinder);