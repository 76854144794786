// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & Helpers
import SalonService from 'services/SalonService';
import FormHelper from 'helpers/FormHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SettingsSalon extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            salon: null
        };

        this.updateField = this.updateField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                name: {
                    label: 'Salon Name',
                    getFieldExtras: (fieldInfo, value) =>
                        <div className="field-subtitle">
                            This name will be used when inserting {' {{ Salon.Name }} '} into SMS and emails
                        </div>
                },
                email: { label: 'Primary Email Address', type: 'email' },
                telephone: { label: 'Primary Telephone' },
                website: { label: 'Website' },
                password: { label: 'Password' },
                idleTimeout: { label: 'Logout if idle for # minutes', type: 'number' },
                isVATReg: { label: 'Salon is VAT-registered', type: 'checkbox' },
                vatRegNo: { label: 'VAT Registration Number' },
                enableTips: { label: 'Enable Tips', type: 'checkbox' },
                enableOnlineBooking: { label: 'Enable Online Booking', type: 'checkbox' },
                firstDayOfWeek: {
                    label: 'First day of week',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'monday', name: 'Monday' },
                        { id: 'tuesday', name: 'Tuesday' },
                        { id: 'wednesday', name: 'Wednesday' },
                        { id: 'thursday', name: 'Thursday' },
                        { id: 'friday', name: 'Friday' },
                        { id: 'saturday', name: 'Saturday' },
                        { id: 'sunday', name: 'Sunday' }
                    ]
                },
                currencyID: {
                    label: 'Currency',
                    type: 'single-select',
                    getOptions: () => this.state.currencies.map(c => ({
                        id: c.currencyID,
                        name: `${c.name} (${c.symbol})`
                    }))
                },
                newCustomerMobileRequired: {
                    checkboxLabel: 'Mobile Number required for new clients',
                    type: 'checkbox'
                },
                newCustomerEmailRequired: {
                    checkboxLabel: 'Email Address required for new clients',
                    type: 'checkbox'
                },
                cancellationFeesAllocatedToStylists: {
                    checkboxLabel: 'Cancellation fees count towards stylists\' commission',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.salon[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const salon = await SalonService.getCurrent();
        const currencies = await SalonService.listCurrencies();
        salon.paymentMethods = await SalonService.listAllPaymentMethods();

        this.setState({
            salon,
            currencies,
            isLoading: false
        });
        const paymentProvider = salon.paymentProvider?.toLowerCase();
        const obPM = salon.paymentMethods.find(pm => pm.code == 'OnlineBookings');
        const applePayPM = salon.paymentMethods.find(pm => pm.code == 'ApplePay');
        this.updatePaymentMethodField(obPM.paymentMethodID, 'isSelected', salon.enableDeposits);
        this.updatePaymentMethodField(applePayPM.paymentMethodID, 'isSelected', false);

        if (paymentProvider == 'evo') {
            const paymentMethod = salon.paymentMethods.find(pm => pm.code == 'OtherCard');
            this.updatePaymentMethodField(paymentMethod.paymentMethodID, 'isSelected', false);
        }
    }

    updateField(fieldName, value) {
        const salon = { ...this.state.salon };
        salon[fieldName] = value;
        this.setState({ salon });
    }

    async save() {
        this.setState({ isLoading: true });
        await SalonService.saveCurrent(this.state.salon);
        this.props.history.push('/settings');
    }

    updatePaymentMethodField(id, field, value) {
        const paymentMethods = [ ...this.state.salon.paymentMethods ];
        const paymentMethod = paymentMethods.find(pm => pm.paymentMethodID == id);
        paymentMethod[field] = value;
        this.updateField('paymentMethods', paymentMethods);
    }

    disableCheckBox(pm) {
        const paymentProvider = this.state.salon.paymentProvider?.toLowerCase();
        if ((pm.code == 'OnlineBookings' || pm.code == 'ApplePay') && (paymentProvider == 'stripe' || paymentProvider == 'evo'))
            return true;
        else if (pm.code == "OtherCard" && (paymentProvider == 'evo'))
            return true;
        else
            return false;
    }

    disableRefundCheckBox(pm) {
        if (pm.code != 'Card' && pm.code != 'Cash' && pm.code != 'OtherCard' && pm.code != 'OtherCash' && pm.code != 'BACS' && pm.code != 'Account' && pm.code != 'OnAccount')
            return true;
        else
            return false;
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Salon Settings
                </div>
                <div className="info-bar-panel-section ml-auto">

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            salon,
            paymentMethods
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">

                <div className="panel">
                    {/*<div className="panel-header">Basic Settings</div>*/}
                    <div className="panel-body">

                        {this.formHelper.renderFormGroups([
                            'name',
                            'email',
                            'telephone',
                            'website',
                            'password',
                            'idleTimeout',
                            'firstDayOfWeek',
                            'currencyID',
                            'newCustomerMobileRequired',
                            'newCustomerEmailRequired',
                            'isVATReg',
                            salon.isVATReg && 'vatRegNo',
                            'enableTips',
                            'enableOnlineBooking',
                            'cancellationFeesAllocatedToStylists'
                        ])}

                        <div className="form-group">
                            <label>Payment Methods</label>
                            <div className="table-container">
                                <table className="table table-bordered payment-methods-table">
                                    <thead>
                                        <tr>
                                            <th className="selected-column"></th>
                                            <th className="name-column">Name</th>
                                            <th className="use-for-column">Appointments</th>
                                            <th className="use-for-column">Retail</th>
                                            <th className="use-for-column">Refunds</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salon.paymentMethods.map(pm =>
                                            <tr key={pm.paymentMethodID} className={pm.isSelected ? '' : 'text-grey'}>
                                                <td className="selected-column">
                                                    <input
                                                        type="checkbox"
                                                        id={'payment-method=' + pm.paymentMethodID}
                                                        disabled={this.disableCheckBox(pm)}
                                                        checked={pm.isSelected}
                                                        onChange={e => this.updatePaymentMethodField(pm.paymentMethodID, 'isSelected', e.target.checked)}
                                                    />
                                                </td>
                                                <td className="name-column">
                                                    <label htmlFor={'payment-method=' + pm.paymentMethodID}>
                                                        {pm.shortName}
                                                    </label>
                                                </td>

                                            {!pm.isSelected &&
                                                <td colSpan="3"></td>
                                            }
                                            {pm.isSelected && <>
                                                <td className="use-for-column">
                                                    <input
                                                        type="checkbox"
                                                        checked={pm.useForAppointments}
                                                        disabled={this.disableCheckBox(pm)}
                                                        onChange={e => this.updatePaymentMethodField(pm.paymentMethodID, 'useForAppointments', e.target.checked)}
                                                    />
                                                </td>
                                                <td className="use-for-column">
                                                    <input
                                                        type="checkbox"
                                                        checked={pm.useForRetail}
                                                        disabled={this.disableCheckBox(pm)}
                                                        onChange={e => this.updatePaymentMethodField(pm.paymentMethodID, 'useForRetail', e.target.checked)}
                                                    />
                                                </td>
                                                <td className="use-for-column">
                                                    <input
                                                        type="checkbox"
                                                        checked={pm.useForRefunds}
                                                        disabled={this.disableRefundCheckBox(pm) || this.disableCheckBox(pm)}
                                                        onChange={e => this.updatePaymentMethodField(pm.paymentMethodID, 'useForRefunds', e.target.checked)}
                                                    />
                                                </td>
                                            </>}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                </div>
            </div>
        </>);
    }

};

export default withRouter(SettingsSalon);