// Libs
import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import moment from 'moment-timezone';
import queryString from 'query-string';

// Services & Helpers
import AccountService       from 'services/AccountService';
import SalonService         from 'services/SalonService';
import GlobalStateService   from 'services/GlobalStateService';
import UserService          from 'services/UserService';
import SearchService        from 'services/SearchService';
import DateHelpers          from 'helpers/DateHelpers';
import BootboxHelper        from 'helpers/BootboxHelper';
import TextHelpers          from 'helpers/TextHelpers';

// Components               
import Layout               from 'components/layout/Layout';
import Login                from 'components/pages/login/Login';
import Loader               from 'components/reusable/Loader';
import Register             from 'components/pages/login/Register';

import DiaryPage            from 'components/pages/diary/DiaryPage';
import DashboardPage        from 'components/pages/dashboard/DashboardPage';
import SettingsPage         from 'components/pages/settings/SettingsPage';
import CustomerPage         from 'components/pages/customer/CustomerPage';
import StockManagerPage     from 'components/pages/stock/StockManagerPage';
import StockCheckOutPage    from 'components/pages/stock/StockCheckOutPage';
import StockCategorisePage  from 'components/pages/stock/StockCategorisePage';
import StockOrderingPage from 'components/pages/stock/stock-ordering/StockOrderingPage';
import YourProfitTargetPage from 'components/pages/your-profit-target/YourProfitTargetPage';
import TheOfficePage        from 'components/pages/the-office/TheOfficePage';
import MarketingPage        from 'components/pages/marketing/MarketingPage';
import DebugPage            from 'components/pages/tools/DebugPage';
import StylistTargetsPage   from 'components/pages/stylist-targets/StylistTargetsPage';

window.moment = moment;
window.DateHelpers = DateHelpers;

//-------------------------------------------------------------------------------------------------------------------

class App extends Component {

    constructor(props) {
        super(props);

        this.checkLogin = this.checkLogin.bind(this);
        this.resetIdleTimeout = this.resetIdleTimeout.bind(this);
        this.isFirstLoad = true;
        window.showErrorDetails = this.showErrorDetails;
        window.applyTheme = this.applyTheme;
        window.updateClientInfo = this.updateClientInfo.bind(this);

        this.state = {
            isLoading: true,
            loginDetails: null
        };
    }

    async componentDidMount() {
        // Remove version info
        window.localStorage.removeItem('version');

        // Determine salon code
        const urlSplit = window.location.pathname.split('/').filter(p => !!p);
        let salonCode = (urlSplit.length >= 1 ? urlSplit[0] : '') || '';
        let salonGroupCode = '';

        //// Mobile salon code? Store in global state so we can show a different login page
        //if (salonCode.indexOf('mobile-') == 0) {
        //    GlobalStateService.setValues({ mobileSalonCode: salonCode });
        //}

        // TODO MERGE THIS IN
        //const url = window.location;
        //let salonCode = url.pathname.length > 0 ? url.pathname.split('/')[1] : '';
        //let mobileSalonCode = '';
        //let userInvitationCode = '';

        //if (salonCode == 'mobile') {
        //    salonCode = url.pathname.split('/')[1] + '/' + url.pathname.split('/')[2] + '/';

        //    mobileSalonCode = url.pathname.split('/')[2];
        //    GlobalStateService.setValues({ mobileSalonCode });
        //    if (url.pathname.includes('register')) {
        //        userInvitationCode = url.pathname.split('/')[4];
        //        this.setState({ loginMode: 'register' });
        //    }
        //}

        //if (userInvitationCode) {
        //    GlobalStateService.setValues({ userInvitationCode });
        //}
        
        // Determine if logged into a group or regular salon
        if (salonCode == 'group') {
            salonGroupCode = urlSplit[1];
            if (urlSplit.length >= 3) {
                salonCode = urlSplit[2];
            }
        } else if (!salonCode && !salonGroupCode) {
            // If no salon code, look for the last one used
            salonGroupCode = window.localStorage.getItem('lastSalonGroupCode');
            salonCode = window.localStorage.getItem('lastSalonCode');
            if (salonCode || salonGroupCode) {
                window.location = (salonGroupCode ? '/group/' + salonGroupCode : '') + '/' + (salonCode || '');
                return;
            }
        }
        GlobalStateService.setValues({ salonCode, salonGroupCode });
        window.localStorage.setItem('lastSalonGroupCode', salonGroupCode || '');
        window.localStorage.setItem('lastSalonCode', (salonCode == 'group' ? '' : salonCode || ''));

        // Set up globally-accessible functions
        GlobalStateService.setValue('logOut', async () => {
            this.setState({ isLoading: true });
            await AccountService.logOutUser();
            this.checkLogin();
        });
        GlobalStateService.setValue('replayRoute', (route) => {
        });
        GlobalStateService.setValue('apiErrorHandler', (response, responseObject) => {
            if (response.status == 401 || response.status == 403) {
                this.setState({ isLoading: true });
                this.checkLogin();
            }
            else if (response.status == 500) {
                let errorMessage =
                    '<h2>Sorry, there has been an error on our side.</h2>' +
                    'Please try again. If the error keeps occurring, please contact support.';
                if (responseObject && responseObject.content) {
                    errorMessage += '<br/><br/><button className="button-tertiary" onclick="showErrorDetails(\'' + TextHelpers.escapeJSString(responseObject.content) + '\')">See Technical Details</button>'
                }
                BootboxHelper.alert(errorMessage);
            }
        });
        GlobalStateService.setValue('preventNav', () => {
            window.onbeforeunload = (e) => {
                e.preventDefault();
                return e.returnValue = 'Are you sure you want to navigate away from this page?';
            }
        });
        GlobalStateService.setValue('allowNav', () => {
            window.onbeforeunload = () => null;
        });
        GlobalStateService.setValue('checkLoginDetails', this.checkLogin.bind(this));
        GlobalStateService.setValue('toggleMobileNav', this.toggleMobileNav.bind(this));

        // Set up global event handlers
        document.addEventListener('wheel', function (event) {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        });

        // Set up an interval to keep checking login details and do other stuff
        clearInterval(this.every30SecondsInterval);
        this.every30SecondsInterval = setInterval(() => {
            this.checkLogin();
        }, 30 * 1000);

        // Check login
        this.checkLogin();

        window.appHistory = this.props.history;
    }

    showErrorDetails(details) {
        BootboxHelper.alert('<pre>' + TextHelpers.escapeHTML(details) + '</pre>');
    }

    async checkLogin() {
        // Determine if logged in       
        const salonCode = GlobalStateService.getValue('salonCode');
        const loginMethod = salonCode && salonCode.includes('mobile') ? 'Mobile' : 'Desktop';
        //const userInvitationCode = GlobalStateService.getValue('userInvitationCode');  
        const loginDetails = await AccountService.getLoginDetails(loginMethod);
        //const userDetails = await UserService.getUserDetails(userInvitationCode);
        
        // If logged in to salon, get client info
        if (loginDetails && loginDetails.isLoggedInSalon) {

            SearchService.updateNgramIndex();

            if (loginDetails.isLoggedInUser) {
                if (this.isFirstLoad) {

                    // Set up idle timeout
                    this.resetIdleTimeout();
                    clearInterval(this.checkIdleTimeoutInterval);
                    this.checkIdleTimeoutInterval = setInterval(() => {
                        this.checkIdleTimeout();
                    }, 1000);
                    const events = ['mousemove', 'keyup'];
                    events.forEach(e => {
                        document.removeEventListener(e, this.resetIdleTimeout);
                        document.addEventListener(e, this.resetIdleTimeout);
                    });

                    // Set up interval to update client info
                    clearInterval(this.updateClientInfoInterval);
                    this.updateClientInfoInterval = setInterval(() => {
                        this.updateClientInfo();
                    }, 300 * 1000);

                    // If SFC not completed, jump to it
                    const clientInfo = await this.updateClientInfo();
                    if (!clientInfo.isSFCCompleted && loginDetails.permissions['OfficeYourProfitTarget']) {
                        this.props.history.push('/your-profit-target');
                    }

                    this.isFirstLoad = false;
                }
            } else {
                clearTimeout(this.resetIdleTimeout);
            }
        } else {
            clearTimeout(this.resetIdleTimeout);
        }

        // Get old login details and only update if something has changed
        const oldLoginDetails = GlobalStateService.getValue('loginDetails');
        if (
            this.state.isLoading ||
            (loginDetails && !oldLoginDetails) ||
            (!loginDetails && oldLoginDetails) ||
            loginDetails.isLoggedInSalon != oldLoginDetails.isLoggedInSalon ||
            loginDetails.isLoggedInUser != oldLoginDetails.isLoggedInUser ||
            this.havePermissionsChanged(loginDetails, oldLoginDetails)
        ) {
            // Update screen
            GlobalStateService.setValue('loginDetails', loginDetails);
            this.setState({
                isLoading: false,
                loginDetails
            });
        }

        // TODO
        //if (userDetails) {
        //    if (userDetails.dateAccepted && !userDetails.dateDisabled) {
        //        this.setState({
        //            loginMode: 'login'
        //        });
        //    }
        //}
    }

    async updateClientInfo() {
        const clientInfo = await SalonService.getClientInfo();
        if (clientInfo) {
            const currentVersion = window.localStorage.getItem('version');
            GlobalStateService.setValues({ clientInfo });
            if (currentVersion) {
                if (clientInfo.version != currentVersion) {
                    GlobalStateService.setValues({
                        newVersion: clientInfo.version
                    });
                }
            } else {
                window.localStorage.setItem('version', clientInfo.version);
            }
            moment.tz.setDefault(clientInfo.timeZoneLocation);

            this.applyTheme(clientInfo.theme);
        }

        return clientInfo;
    }

    applyTheme(theme) {
        if (theme && theme.cssClass) {
            document.body.classList.add(theme.cssClass);
            const link = document.querySelector('link[rel~=\'icon\']');
            if (theme.code == 'kevin-murphy') {
                link.href = '/theme/favicon-kevin-murphy.png';
            }
            else if (theme.code == 'aveda') {
                link.href = '/theme/favicon-aveda.ico';
            }
        }
    }

    havePermissionsChanged(loginDetails, oldLoginDetails) {
        for (let p in loginDetails.permissions) {
            if (!oldLoginDetails.permissions[p]) {
                return true;
            }
        }
        for (let p in oldLoginDetails.permissions) {
            if (!loginDetails.permissions[p]) {
                return true;
            }
        }
        return false;
    }

    checkIdleTimeout() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (loginDetails && loginDetails.isLoggedInUser) {
            const clientInfo = GlobalStateService.getValue('clientInfo');
            const salonCode = GlobalStateService.getValue('salonCode');
            if (salonCode) {
                const lastInteractionStr = localStorage.getItem('last-interaction-' + salonCode) || '0';
                const lastInteraction = (parseInt(lastInteractionStr) || 0);
                if (lastInteraction && Date.now() - lastInteraction >= clientInfo.idleTimeout * 1000) {
                    const logOut = GlobalStateService.getValue('logOut');
                    logOut();
                }
            }
        }
    }

    resetIdleTimeout() {
        const salonCode = GlobalStateService.getValue('salonCode');
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (salonCode) {
            if (loginDetails && loginDetails.isLoggedInUser) {
                localStorage.setItem('last-interaction-' + salonCode, Date.now());
            } else {
                localStorage.removeItem('last-interaction-' + salonCode);
            }
        }
    }

    toggleMobileNav(force) {
        const className = 'mobile-nav-visible';
        if (document.body.classList.contains(className)) {
            document.body.classList.remove(className);
        } else {
            document.body.classList.add(className);
        }
    }

    //-------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loginDetails,
            loginMode
        } = this.state;
        const rootNode = document.querySelector('#root');
        const salonCode = GlobalStateService.getValue('salonCode') || '';
        const salonGroupCode = GlobalStateService.getValue('salonGroupCode') || '';
        //const mobileSalonCode = GlobalStateService.getValue('mobileSalonCode');
        //const userInvitationCode = GlobalStateService.getValue('userInvitationCode');
        rootNode.classList.add('not-logged-in');

        // Loading login status
        if (isLoading) {
            return (<>
                <div className="login-page" >
                    <Loader />
                </div>
            </>);
        }
        
        // Not logged in yet
        if (!loginDetails || !loginDetails.isLoggedInSalon || !loginDetails.isLoggedInUser) {

            // Mobile
            if (salonCode.indexOf('mobile-') == 0) {
                const qs = queryString.parse(window.location.search);

                // Register
                if (qs.userInvitationCode) {
                    return (
                        <div className="register-page">
                            <Register
                                userInvitationCode={qs.userInvitationCode}
                                onLogIn={this.checkLogin}
                            />
                        </div>
                    )
                }

                // Login
                return (
                    <div className="mobile-login-page">
                        <Login
                            isMobile
                            loginDetails={loginDetails}
                            onLogIn={this.checkLogin}
                            salonCode={salonCode}
                        />
                    </div>
                )
            }

            // Desktop
            return (
                <div className="login-page">
                    <Login
                        loginDetails={loginDetails}
                        onLogIn={this.checkLogin}
                        salonCode={salonCode}
                        salonGroupCode={salonGroupCode}
                    />
                </div>
            );
        }

        // Logged in
        rootNode.classList.remove('not-logged-in');
        return (
            <Layout>
                <Switch>
                    <Route path="/diary/:date?/:appointmentID?/:mode?" component={DiaryPage} />

                    <Route path="/dashboard" component={() => (
                        <Switch>
                            {loginDetails.permissions['TeamHub'] &&
                                <Route path="/dashboard/:screen" component={DashboardPage} />
                            }
                            {loginDetails.permissions['Dashboard'] &&
                                <Route path="/dashboard" component={DashboardPage} />
                            }
                            <Redirect to="/diary" />
                        </Switch>
                    )}/>

                    <Route path="/customer/:id?/:tab?/:defaultName?" component={CustomerPage} />

                    {loginDetails.permissions['Stockroom'] &&
                        <Route path="/stock" component={() => (
                            <Switch>
                                <Route path="/stock/manager" component={StockManagerPage} />
                                <Route path="/stock/check-out" component={StockCheckOutPage} />
                            <Route path="/stock/categorise" component={StockCategorisePage} />
                            <Route path="/stock/stock-ordering/:tab?/:subTab?/:subTabParam?" component={StockOrderingPage} />
                            </Switch>
                        )} />
                    }

                    {loginDetails.permissions['Office'] &&
                        <Route path="/the-office/:tab?/:subTab?/:subTabParam?" component={TheOfficePage} />
                    }
                    {loginDetails.permissions['OfficeYourProfitTarget'] &&
                        <Route path="/your-profit-target/:slideIndex?" component={YourProfitTargetPage} />
                    }
                    {loginDetails.permissions['OfficeStylistTargets'] &&
                        <Route path="/stylist-targets" component={StylistTargetsPage} />
                    }

                    {loginDetails.permissions['Settings'] &&
                        <Route path="/settings" component={SettingsPage} />
                    }

                    {loginDetails.permissions['Marketing'] &&
                        <Route path="/marketing/:tab?/:subTab?/:subTabParam?/:queryStringParam?" component={MarketingPage} />
                    }

                    <Route path="/debug" component={DebugPage} />


                    {/* If no routes match */}
                    {loginDetails.permissions['Dashboard'] &&
                        <Redirect to="/dashboard" />
                    }
                    <Redirect to="/diary" />

                    <Route path="/" exact render={() => null} />
                    <Redirect to="/" />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(App);