import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class PackageService
    extends BaseService {

    static async list(param) {
        return BaseService.callAPI('package/list', param);
    }

    static async get(packageID) {
        return BaseService.callAPI('package/get/' + packageID);
    }
    
    static async save(servicePackage) {
        const result = await BaseService.callAPI('package/save', servicePackage);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async delete(id) {
        const result = await BaseService.callAPI('package/delete/' + id);
        SearchService.updateNgramIndex(true);
        return result;
    }

}