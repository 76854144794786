// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

class SettingsMerchantServicesEdit extends FloomlyComponent {

    constructor(props) {
        super(props);

        // Init state
        this.state = {
            paymentDevices: null,
            isLoadingList: true,
            isLoading: true,
            paymentDevice: null,
            editMode: ''
        };
        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                paymentDeviceId: {
                    label: 'EVO Payment Devices',
                    type: 'single-select',
                    getOptions: () => this.state.paymentDevices.map(pd => ({
                        id: pd.paymentDeviceId,
                        name: pd.paymentDeviceName
                    }))
                },
                paymentDeviceName: {
                    label: 'Payment Device Name',
                    type: 'text'
                },
                evoPosId: {
                    label: 'EVO POS ID',
                    type: 'text'
                },
                evoIsvId: {
                    label: 'EVO ISV ID',
                    type: 'text'
                },
                isDefault: {
                    checkboxLabel: 'Is Default',
                    type: 'checkbox'
                },
            },
            getValue: (fieldName, fieldInfo) => this.state.paymentDevice[fieldName],
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const paymentDeviceID = Number(this.props.match.params.paymentDeviceID) || 0;
        const paymentDevices = await OnlineBookingService.listPaymentDevices() || {};
        let paymentDevice;

        if (paymentDeviceID) {
            paymentDevice = await OnlineBookingService.getPaymentDevice(paymentDeviceID);
        }
        else {
            paymentDevice = {
                id: 0,
                evoPosId: '',
                evoIsvId: '',
                isDefault: false
            };
        }

        this.setState({
            paymentDevices,
            paymentDevice,
            isLoading: false,
            paymentDeviceID
        });
    }

    goBack() {
        this.props.history.push('/settings/merchant-services');
    }

    async updateField(fieldName, value, fieldInfo) {
        const paymentDevice = { ...this.state.paymentDevice };
        paymentDevice[fieldName] = value;
        this.setState({ paymentDevice });
    }

    async save() {
        this.setState({ isLoading: true });
        try {
            await OnlineBookingService.savePaymentDevices(this.state.paymentDevice);
            this.goBack();
        } catch (error) {
            BootboxHelper.alert(error);
            this.setState({ isLoading: false });
        }
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this payment device?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        await OnlineBookingService.deletePaymentDevice(this.state.paymentDevice.paymentDeviceId);
        this.goBack();
    }

    async testConnection() {
        this.setState({ isTesting: true, isLoading :true });
        var isSuccess = await OnlineBookingService.testCardMachineConnection(this.state.paymentDevice.paymentDeviceId);
        BootboxHelper.alert(isSuccess);
        this.setState({ isTesting: false, isLoading: false });
    }

    async updateCardMachine() {
        this.setState({ isTesting: true, isLoading: true });
        await OnlineBookingService.updateCardMachine(this.state.paymentDevice.paymentDeviceId);
        this.setState({ isTesting: false, isLoading: false });
    }

    renderInfoBar() {
        const {
            isTesting,
            paymentDeviceID,
        } = this.state;
        return (
            <InfoBar className="settings-info-bar" sticky={true}>
                <div className="info-bar-panel-section">
                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>
                </div>
                {!isTesting &&
                    <div className="info-bar-panel-section ml-auto">
                        {!!paymentDeviceID &&
                            <button className="button width-auto" style={{ marginRight: 10 }} onClick={() => this.testConnection()}>
                                Test connection
                            </button>
                        }
                        {!!paymentDeviceID &&
                            <button className="button width-auto" style={{ marginRight: 10 }} onClick={() => this.updateCardMachine()}>
                                Update card machine
                            </button>
                        }
                    </div>
                }
            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            paymentDeviceID,
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (<>
            {this.renderInfoBar()}
            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">
                        {this.formHelper.renderFormGroups([
                            'paymentDeviceName',
                            'evoIsvId',
                            'evoPosId',
                            'isDefault'
                        ])}
                        <div>
                            <button className="button button-primary button-small me-3" onClick={e => this.save()}>
                                Save Changes
                            </button>

                            {!!paymentDeviceID &&
                                <button className="button button-secondary button-small" onClick={e => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
};

export default withRouter(SettingsMerchantServicesEdit);