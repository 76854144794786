// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

// Services & Helpers
import StockService         from 'services/StockService';
import SalonService         from 'services/SalonService';
import SearchService        from 'services/SearchService';
import TextHelpers          from 'helpers/TextHelpers';
import GlobalStateService   from 'services/GlobalStateService';
import TaxRateService       from 'services/TaxRateService';
import WebSocketService     from 'services/WebSocketService';
import BootboxHelper        from 'helpers/BootboxHelper';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import InfoBar              from 'components/layout/InfoBar'
import Loader               from 'components/reusable/Loader';
import ProductSettingsModal from 'components/pages/stock/ProductSettingsModal';
import ConfirmDeleteModal   from 'components/reusable/ConfirmDeleteModal';

//-------------------------------------------------------------------------------------------------------------------

class StockManagerPage extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.productSettingsModalRef = React.createRef();
        this.confirmDeleteModalRef = React.createRef();

        this.loginDetails = GlobalStateService.getValue('loginDetails');
        this.canEdit = this.loginDetails.permissions['StockroomEditStockManager'];

        // Init state
        this.state = {
            isLoading: true,
            isItemLoading: {},
            isUsageOpen: {},
            isManufacturerOpen: {},
            isRangeOpen: {},
            manufacturersByUsage: {},
            rangesByManufacturerID: {},
            isAddingManufacturer: null,
            isSavingManufacturer: null,
            newManufacturerName: null,
            isAddingRange: null,
            isSavingRange: null,
            newRangeName: null,
            dummySaveStatus: {},
            categoriesByUsage: {},
            categoriesByID: {},
            isCreatingOrder: false
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    async componentDidMount() {
        // Load categories
        const categories = await StockService.listCategories();
        const categoriesByID = {};
        const categoriesByUsage = {};
        categories.forEach(c => {
            categoriesByID[c.id] = c;
            if (!categoriesByUsage[c.usage]) {
                categoriesByUsage[c.usage] = [];
            }
            categoriesByUsage[c.usage].push(c);
        });

        // Set up Web Sockets
        WebSocketService.joinPage('Stock')
            .then(() => {
                WebSocketService.subscribe('StockUpdated', (deltas) => {
                    this.applyStockItemQuantityUpdates(deltas);
                });
            });

        // Load other entities
        const taxRates = await TaxRateService.list();

        // Update
        this.setState({
            categoriesByUsage,
            categoriesByID,
            taxRates,
            isLoading: false
        });
    }

    async componentWillUnmount() {
        WebSocketService.leavePage('Stock');
    }

    async applyStockItemQuantityUpdates(updates) {
        // { 'rangeID,productGUID,stockItemGUID': { changeType: '', value: 0 } }
        // e.g.: { 123 - 456 - 789: { changeType: 'Delta', value: -1 } }

        for (let updateKey in updates) {
            // Get various IDs and the actual delta value
            const keyParts = updateKey.split(',');
            const rangeID = keyParts[0];
            const productGUID = keyParts[1];
            const stockItemGUID = keyParts[2];
            const update = updates[updateKey];

            // Find this list of products (will only return a value if the product range is expanded)
            const productsKey = `products-${rangeID}`;
            const products = this.state[productsKey];

            if (products) {
                // Update in state
                const newProductValues = {};
                const product = products.find(p => p.guid == productGUID);
                const stockItems = [...product.stockItems];
                const index = stockItems.findIndex(si => si.guid == stockItemGUID);
                const stockItem = { ...stockItems[index] };
                if (update.changeType == 'Value') {
                    stockItem.currentStock = update.value;
                } else if (update.changeType == 'Delta') {
                    stockItem.currentStock = (stockItem.currentStock || 0) + update.value;
                }
                stockItem.isChanged = true;
                stockItems[index] = stockItem;
                newProductValues.stockItems = stockItems;
                await this.updateProductFields(rangeID, productGUID, newProductValues);
            }
        }
    }

    async setItemLoading(item, isLoading) {
        const isItemLoading = { ...this.state.isItemLoading };
        if (isLoading) {
            isItemLoading[item] = true;
        } else {
            delete isItemLoading[item];
        }
        this.setState({ isItemLoading });
    }

    async toggleUsageOpen(usage) {
        if (this.state.isSearched) return;
        const isUsageOpen = { ...this.state.isUsageOpen };
        this.setItemLoading(`usage-${usage}`, true);
        if (isUsageOpen[usage]) {
            delete isUsageOpen[usage];
            await this.unloadManufacturers(usage);
        } else {
            isUsageOpen[usage] = true;
            await this.loadManufacturers(usage);
        }
        this.setItemLoading(`usage-${usage}`, false);
        this.setState({ isUsageOpen });
    }

    async loadManufacturers(usage) {
        return new Promise(async (resolve) => {
            const manufacturersByUsage = { ...this.state.manufacturersByUsage };
            if (manufacturersByUsage[usage]) return resolve();
            const manufacturers = await StockService.listManufacturers(usage);
            manufacturersByUsage[usage] = manufacturers;
            this.setState({ manufacturersByUsage }, resolve);
        });
    }

    async unloadManufacturers(usage) {
        const manufacturersByUsage = { ...this.state.manufacturersByUsage };
        delete manufacturersByUsage[usage];
        this.setState({ manufacturersByUsage });
    }

    async toggleManufacturerOpen(manufacturerID, usage) {
        if (this.state.isSearched) return;
        const key = `${manufacturerID}-${usage}`;
        this.setItemLoading(`manufacturer-${key}`, true);
        const isManufacturerOpen = { ...this.state.isManufacturerOpen };
        if (isManufacturerOpen[key]) {
            delete isManufacturerOpen[key];
            await this.unloadRanges(manufacturerID, usage);
        } else {
            isManufacturerOpen[key] = true;
            await this.loadRanges(manufacturerID, usage);
        }
        this.setItemLoading(`manufacturer-${key}`, false);
        this.setState({ isManufacturerOpen });
    }

    async loadRanges(manufacturerID, usage) {
        return new Promise(async (resolve) => {
            const rangesByManufacturerID = { ...this.state.rangesByManufacturerID };
            const key = `${manufacturerID}-${usage}`;
            if (rangesByManufacturerID[key]) return resolve();
            const ranges = await StockService.listRanges(manufacturerID, usage);
            rangesByManufacturerID[key] = ranges;
            this.setState({ rangesByManufacturerID }, resolve);
        });
    }

    async unloadRanges(manufacturerID, usage) {
        const rangesByManufacturerID = { ...this.state.rangesByManufacturerID };
        const key = `${manufacturerID}-${usage}`;
        delete rangesByManufacturerID[key];
        this.setState({ rangesByManufacturerID });
    }

    async toggleRangeOpen(id) {
        if (this.state.isSearched) return;
        this.setItemLoading(`range-${id}`, true);
        const isRangeOpen = { ...this.state.isRangeOpen };
        if (isRangeOpen[id]) {
            delete isRangeOpen[id];
            await this.unloadProducts(id);
        } else {
            isRangeOpen[id] = true;
            await this.loadProducts(id);
        }
        this.setItemLoading(`range-${id}`, false);
        this.setState({ isRangeOpen });
    }

    async loadProducts(rangeID) {
        return new Promise(async (resolve) => {
            if (this.state[`products-${rangeID}`]) return resolve();
            const products = await StockService.listProducts(rangeID);
            this.setState({ [`products-${rangeID}`]: products }, resolve);
        });
    }

    async unloadProducts(rangeID) {
        this.setState({
            [`products-${rangeID}`]: null
        });
    }

    updateProductFields(rangeID, productGUID, newValues) {
        return new Promise((resolve) => {
            const products = [...this.state[`products-${rangeID}`]];
            const index = products.findIndex(p => p.guid == productGUID);
            const product = { ...products[index] };
            for (let fieldName in newValues) {
                const value = newValues[fieldName];
                if (product[fieldName] != value) {
                    product[fieldName] = value;
                    if (fieldName != 'isChanged') {
                        product.isChanged = true;
                    }
                }
            }
            delete product.isNew;
            products[index] = product;
            this.setState({ [`products-${rangeID}`]: products }, () => {
                resolve();
            });
        });
    }

    updateStockItemFields(rangeID, productGUID, stockItemGUID, newValues) {
        return new Promise(async (resolve) => {
            const products = this.state[`products-${rangeID}`];
            const product = products.find(p => p.guid == productGUID);
            const stockItems = [...product.stockItems];
            const index = stockItems.findIndex(si => si.guid == stockItemGUID);
            const stockItem = { ...stockItems[index] };
            const newProductValues = {};
            for (let fieldName in newValues) {
                const value = newValues[fieldName];
                if (stockItem[fieldName] != value) {
                    stockItem[fieldName] = value;
                    if (fieldName != 'isChanged') {
                        stockItem.isChanged = true;
                    }
                }
            }
            stockItems[index] = stockItem;
            newProductValues.stockItems = stockItems;
            await this.updateProductFields(rangeID, productGUID, newProductValues);
            resolve();
        });
    }

    updateStockItemSize(rangeID, productGUID, stockItemGUID, value) {
        return new Promise(async (resolve) => {
            const stockUnitSplit = this.extractStockUnit(value);

            if (stockUnitSplit) {
                // Update size
                await this.updateStockItemFields(rangeID, productGUID, stockItemGUID, {
                    size: stockUnitSplit.size
                });

                // Update stock unit
                await this.updateProductFields(rangeID, productGUID, {
                    stockUnit: stockUnitSplit.stockUnit
                });

                // Save
                await this.saveProduct(rangeID, productGUID);
                await this.saveStockItemField(rangeID, productGUID, stockItemGUID, 'size');

                resolve();
            } else {
                await this.saveStockItemField(rangeID, productGUID, stockItemGUID, 'size');
            }
        });
    }

    extractStockUnit(size) {
        size = (size || '');
        const match = size.match(/([0-9]+)(\s*)([^0-9]+)/);
        if (match && match.length >= 4) {
            let stockUnit = match[3];
            if (stockUnit == '.') {
                stockUnit = '';
            }
            return {
                size: match[1],
                stockUnit: stockUnit
            };
        }
        return null;
    }

    addProduct(rangeID, usage) {
        const products = this.state[`products-${rangeID}`];
        const guid = TextHelpers.getRandomGUID();
        const taxRate = this.state.taxRates.find(tr =>
            (usage == 'retail' && tr.isDefaultRetail) ||
            (usage == 'professional' && tr.isDefaultProfessional)
        );
        const newProduct = {
            guid,
            productRangeID: rangeID,
            stockItems: [],
            taxRateID: (taxRate ? taxRate.id : null),
            isNew: true
        };
        products.push(newProduct);
        this.focusElement = 'product-' + guid;
        this.setState({ [`products-${rangeID}`]: products });
    }

    addStockItem(rangeID, productGUID, index) {
        const products = this.state[`products-${rangeID}`];
        const product = products.find(p => p.guid == productGUID);
        const stockItems = [...product.stockItems];
        const guid = TextHelpers.getRandomGUID();
        const newStockItem = {
            guid,
            productGUID
        };
        stockItems.splice(index + 1, 0, newStockItem);
        this.focusElement = 'stock-item-' + guid;
        this.updateProductFields(rangeID, productGUID, {
            stockItems
        });
    }

    async saveProduct(rangeID, productGUID) {
        const product = this.state[`products-${rangeID}`].find(p => p.guid == productGUID);
        if (product.isChanged) {
            await StockService.saveProduct(product);
            await this.updateProductFields(rangeID, productGUID, { isChanged: false });
        }
    }

    async saveStockItemField(rangeID, productGUID, stockItemGUID, field) {
        const product = this.state[`products-${rangeID}`].find(p => p.guid == productGUID);
        let stockItem = product.stockItems.find(si => si.guid == stockItemGUID);
        if (stockItem.isChanged) {
            stockItem = {
                guid: stockItem.guid,
                productGUID: stockItem.productGUID,
                [field]: stockItem[field]
            };
            const result = await StockService.saveStockItem(stockItem);
            if (result.stockItem) {
                //const updatedStockItem = {
                //    size: result.stockItem.size,
                //    costPrice: result.stockItem.costPrice,
                //    sellPrice: result.stockItem.sellPrice,
                //    currentStock: result.stockItem.currentStock,
                //    minStock: result.stockItem.minStock,
                //    idealStock: result.stockItem.idealStock,
                //    stockAlertLevel: result.stockItem.stockAlertLevel,
                //    isChanged: false
                //};
                //await this.updateStockItemFields(rangeID, productGUID, stockItemGUID, updatedStockItem);
            } else {
                setTimeout(() => {
                    this.saveStockItemField(rangeID, productGUID, stockItemGUID, field);
                }, 100);
            }
        }
    }

    startAddingManufacturer(usage) {
        this.setState({ isAddingManufacturer: usage });
        this.focusElement = 'new-manufacturer-' + usage;
    }

    async addManufacturer(usage, e) {
        e.preventDefault();
        if (this.state.isSavingManufacturer == usage) {
            return;
        }
        if (!this.state.newManufacturerName) {
            this.setState({
                isAddingManufacturer: null,
                isSavingManufacturer: null,
                newManufacturerName: null
            });
            return;
        }
        this.setState({ isSavingManufacturer: usage });
        const manufacturer = {
            [`has${usage}Products`]: true,
            name: this.state.newManufacturerName
        };
        const result = await StockService.saveManufacturer(manufacturer);
        manufacturer.id = result.id;
        const manufacturersByUsage = { ...this.state.manufacturersByUsage };
        manufacturersByUsage[usage].push(manufacturer);
        this.setState({
            manufacturersByUsage,
            isAddingManufacturer: null,
            isSavingManufacturer: null,
            newManufacturerName: null
        }, () => {
            this.toggleManufacturerOpen(manufacturer.id, usage);
        });
    }

    async confirmDeleteManufacturer(id, usage) {
        const confirm = await this.confirmDeleteModalRef.current.show({
            message: <>
                <b>Just a heads up,</b> before you delete this, all ranges and products under this manufacturer will also be deleted.<br /><br />
                If you're sure you want to delete this, <b>please type 'DELETE' below to confirm.</b>
            </>
        });

        if (confirm) {
            this.deleteManufacturer(id, usage);
        }
    }

    async deleteManufacturer(id, usage) {
        await StockService.deleteManufacturer(id, usage);
        await this.unloadManufacturers(usage);
        await this.loadManufacturers(usage);
    }

    startAddingRange(manufacturer, usage) {
        const key = `${manufacturer.id}-${usage}`;
        this.setState({ isAddingRange: key });
        this.focusElement = 'new-range-' + key;
    }

    async addRange(manufacturer, usage, e) {
        const key = `${manufacturer.id}-${usage}`;
        e.preventDefault();
        if (this.state.isSavingRange == key) {
            return;
        }
        if (!this.state.newRangeName) {
            this.setState({
                isAddingRange: null,
                isSavingRange: null,
                newRangeName: null
            });
            return;
        }
        this.setState({ isSavingRange: key });
        const range = {
            manufacturerCompanyID: manufacturer.id,
            name: this.state.newRangeName,
            usage
        };
        const result = await StockService.saveRange(range);
        range.id = result.id;
        const rangesByManufacturerID = { ...this.state.rangesByManufacturerID };
        rangesByManufacturerID[key].push(range);
        this.setState({
            rangesByManufacturerID,
            isAddingRange: null,
            isSavingRange: null,
            newRangeName: null
        }, () => {
            this.toggleRangeOpen(range.id);
        });
    }

    async confirmDeleteRange(manufacturerID, id, usage) {
        const confirm = await this.confirmDeleteModalRef.current.show({
            message: <>
                <b>Just a heads up,</b> before you delete this, all products under this range will also be deleted.<br /><br />
                If you're sure you want to delete this, <b>please type 'DELETE' below to confirm.</b>
            </>
        });

        if (confirm) {
            this.deleteRange(manufacturerID, id, usage);
        }
    }

    async deleteRange(manufacturerID, id, usage) {
        await StockService.deleteRange(id, usage);
        await this.unloadRanges(manufacturerID, usage);
        await this.loadRanges(manufacturerID, usage);
    }

    async debounceSearch(query) {
        clearTimeout(this.searchTimeout);
        if (query) {
            this.searchTimeout = setTimeout(() => {
                this.search(query);
            }, 250);
        } else {
            this.reset();
        }
    }

    async search(query) {
        if (query.length >= 2) {
            const results = await SearchService.search(query, ['StockItem', 'Product']);
            this.applyFilter(results);
        } else {
            this.setState({
                isSearched: false,
                numSearchResults: null,
                isSearching: false,
                visibleProductIDs: {},
                isUsageOpen: {},
                isManufacturerOpen: {},
                isRangeOpen: {}
            });
        }
    }

    async applyFilter(items) {
        const isUsageOpen = {};
        const isManufacturerOpen = {};
        const isRangeOpen = {};
        const visibleProductIDs = {};

        items = items || [];
        items.forEach(i => {
            if (i.type == 'Product') {
                visibleProductIDs[parseInt(i.id)] = true;
            } else if (i.type == 'StockItem') {
                visibleProductIDs[parseInt(i.productID)] = true;
            }
        });

        this.setState({
            numSearchResults: items.length,
            isUsageOpen,
            isManufacturerOpen,
            isRangeOpen,
            isSearched: true,
            isSearching: true,
            visibleProductIDs
        });

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (!isUsageOpen[item.usage]) {
                isUsageOpen[item.usage] = true;
                this.setState({ isUsageOpen });
                await this.loadManufacturers(item.usage);
            }
            if (!isManufacturerOpen[item.manufacturerCompanyID]) {
                isManufacturerOpen[`${item.manufacturerCompanyID}-${item.usage}`] = true;
                this.setState({ isManufacturerOpen });
                await this.loadRanges(item.manufacturerCompanyID, item.usage);
            }
            if (!isRangeOpen[item.productRangeID]) {
                isRangeOpen[item.productRangeID] = true;
                this.setState({ isRangeOpen });
                await this.loadProducts(item.productRangeID);
            }
        }

        this.setState({
            isSearching: false
        });
    }

    async saveRange(range) {
        await StockService.saveRange(range);
    }

    async print() {
        window.print();
    }

    async export() {
        this.setState({ isExporting: true });
        await StockService.exportStockroom();
        this.setState({ isExporting: false });
    }

    reset() {
        const newState = {
            isUsageOpen: {},
            isManufacturerOpen: {},
            isRangeOpen: {},
            manufacturersByUsage: {},
            rangesByManufacturerID: {},
            isSearched: false
        };
        for (let key in this.state) {
            if (key.indexOf('products-') == 0) {
                newState[key] = null;
            }
        }
        this.setState(newState);
    }

    async showProductSettingsModal(rangeID, productGUID, stockItemGUID) {
        const product = this.state[`products-${rangeID}`].find(p => p.guid == productGUID);
        let stockItem = product.stockItems.find(si => si.guid == stockItemGUID);
        const result = await this.productSettingsModalRef.current.show({
            product, stockItem
        });
        if (result.action == 'save') {
            await this.updateProductFields(rangeID, productGUID, result.product);
            await StockService.saveProduct(result.product);
            await this.updateStockItemFields(rangeID, productGUID, stockItemGUID, {
                packSize: result.product.packSize
            });
            await this.saveStockItemField(rangeID, productGUID, stockItemGUID, 'packSize');
        }
        else if (result.action == 'delete') {
            await this.deleteProduct(rangeID, productGUID);
        }
    }

    async deleteProduct(rangeID, productGUID) {
        const products = [...this.state[`products-${rangeID}`]];
        const index = products.findIndex(p => p.guid == productGUID);
        if (index != -1) {
            const product = products[index];
            products.splice(index, 1);
            this.setState({
                [`products-${rangeID}`]: products
            });
            if (product.productID) {
                await StockService.deleteProduct(product.productID);
            }
        }
    }

    async dummySave(stockItemGUID) {
        let dummySaveStatus = { ...this.dummySaveStatus };
        dummySaveStatus[stockItemGUID] = 'saving';
        this.setState({ dummySaveStatus });

        setTimeout(() => {
            dummySaveStatus = { ...this.dummySaveStatus };
            dummySaveStatus[stockItemGUID] = 'saved';
            this.setState({ dummySaveStatus });
        }, 250);

        setTimeout(() => {
            dummySaveStatus = { ...this.dummySaveStatus };
            delete dummySaveStatus[stockItemGUID];
            this.setState({ dummySaveStatus });
        }, 1250);
    }

    updateProductRangeFields(range, newValues) {
        const key = `${range.manufacturerCompanyID}-${range.usage}`;
        const rangesByManufacturerID = { ...this.state.rangesByManufacturerID };
        const ranges = [...rangesByManufacturerID[key]];
        const index = ranges.findIndex(r => r.id == range.id);
        range = { ...ranges[index] };
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            range[fieldName] = value;
        }
        this.saveRange(range);
        ranges[index] = range;
        rangesByManufacturerID[key] = ranges;
        this.setState({ rangesByManufacturerID });
    }

    newOrder() {
        this.setState({
            isCreatingOrder: true
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        setTimeout(() => {
            this.focusElement = null;
        }, 0);

        return (<>

            <div className="page-content">

                <div className="page-content-left"></div>

                <div className="page-content-right">

                    <div className="page-content-right-inner">

                        {this.renderInfoBar()}

                    </div>

                </div>

            </div>

            <div className="stock-manager-content">

                <div className="panel stock-manager-filter-panel sticky-topmost">

                    {this.renderFilterPanel()}

                </div>

                <div className="panel stock-manager-main">

                    {this.renderMainContent()}

                </div>

            </div>

            <ProductSettingsModal ref={this.productSettingsModalRef} />
            <ConfirmDeleteModal ref={this.confirmDeleteModalRef} />

        </>);
    }

    renderFilterPanel() {
        const {
            isSearched,
            isSearching
        } = this.state;

        return (
            <div className="stock-search-bar">
                <input type="search" placeholder="Type to search..." onChange={e => this.debounceSearch(e.target.value)} />

                {isSearching ? <>
                    {' '}<Loader isInline />
                </> :
                    isSearched && <>
                        {' '}<span className="fa fa-check" />
                    </>
                }

                <button className="button ml-auto" onClick={e => this.print()} style={{ width: 120 }}>
                    Print
                </button>
                <button className="button" onClick={e => this.export()} style={{ width: 120 }}>
                    Export
                </button>

            </div>);

    }

    renderInfoBar() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <InfoBar className="stock-manager-info-bar">

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">

                    Stock Manager

                </div>

                <div className="info-bar-panel-section ml-auto-desktop">

                    {/*
                    {!isEditMode && loginDetails.permissions['StockroomEditStockManager'] &&
                        <button className="button" onClick={() => this.startEditMode()}>
                            <span className="fa fa-pencil-alt"></span>{' '}
                            Edit Mode
                        </button>
                    }

                    {isEditMode &&
                        <button className="button" onClick={() => this.save()}>
                            <span className="fa fa-check"></span>{' '}
                            Save
                        </button>
                    }
                    */}

                </div>

                <div className="info-bar-panel-section">

                    {/*
                    <Link className="button me-3" to="/stock/categorise">
                        <span className="fas fa-list-ol"></span>{' '}
                        Orders
                    </Link>

                    <button className="button me-3" onClick={() => this.newOrder()}>
                        <span className="fas fa-boxes"></span>{' '}
                        New Order
                    </button>
                    */}

                    {loginDetails.permissions['StockroomEditStockManager'] &&

                        <button className="button" onClick={() => this.props.history.push('/stock/categorise')} style={{ width: 125 }}>
                            <span className="fa fa-clipboard-list"></span>{' '}
                            Categorise
                        </button>
                    }

                </div>
                <div className="info-bar-panel-section">
                    {loginDetails.permissions['StockroomCheckInOut'] &&
                        <button className="button" onClick={() => this.props.history.push('/stock/check-out')} style={{ width: 'auto', minWidth: 50 }}>
                            <span className="fa fa-box-open"></span>
                            {' '}
                            Check out
                        </button>
                    }
                </div>
                <div className="info-bar-panel-section">
                    {loginDetails.permissions['StockroomCreateStockOrder'] &&
                        <button className="button" onClick={() => this.props.history.push('/stock/stock-ordering')} style={{ width: 'auto', minWidth: 50 }}>
                            <span className="fa-solid fa-warehouse"></span>
                            {' '}
                            Ordering
                        </button>
                    }

                </div>

            </InfoBar>
        );
    }

    renderMainContent() {
        const {
            isSearched,
            numSearchResults,
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {isSearched && numSearchResults == 0 &&
                <div className="empty-text">
                    No results found - please try a different search
                </div>
            }

            {this.renderUsage('retail', 'Retail')}
            {this.renderUsage('professional', 'Professional')}
        </>);
    }

    renderUsage(usage, title) {
        const {
            isSearched,
            isAddingManufacturer,
            isSavingManufacturer,
            newManufacturerName
        } = this.state;
        const isLoading = this.state.isItemLoading[`usage-${usage}`];
        const isOpen = this.state.isUsageOpen[usage];

        if (isSearched && !isOpen) return null;

        const manufacturers = this.state.manufacturersByUsage[usage] || [];

        return (
            <div className={'usage-container ' + (isOpen ? 'open' : '')}>

                <div className={`header ${isSearched ? '' : 'clickable'}`} onClick={() => this.toggleUsageOpen(usage)}>
                    {isOpen ?
                        <span className="fas fa-chevron-circle-up" /> :
                        <span className="fas fa-chevron-circle-right" />
                    }
                    {title}
                    {isLoading && <>{' '}<Loader isInline /></>}
                </div>

                {isOpen &&
                    <div className="content">
                        {manufacturers.map(m =>
                            <React.Fragment key={m.id}>
                                {this.renderManufacturer(m, usage)}
                            </React.Fragment>
                        )}
                        {this.canEdit &&
                            <div className="manufacturer-container">
                                {isAddingManufacturer == usage ?
                                    <form className="header" onSubmit={e => this.addManufacturer(usage, e)}>
                                        <span className="fas fa-plus-circle" />
                                        <input
                                            type="text"
                                            value={newManufacturerName || ''}
                                            onChange={e => this.setState({
                                                newManufacturerName: e.target.value
                                            })}
                                            autoFocus={this.focusElement == 'new-manufacturer-' + usage}
                                            readOnly={isSavingManufacturer == usage}
                                        />
                                        <button
                                            className="button button-secondary save-button"
                                            disabled={isSavingManufacturer == usage}
                                        >
                                            <span className="fa fa-check" />
                                        </button>
                                    </form> :
                                    !isSearched &&
                                    <div className="header clickable" onClick={() => this.startAddingManufacturer(usage)}>
                                        <span className="fas fa-plus-circle" />
                                        Add a new manufacturer...
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    renderManufacturer(manufacturer, usage) {
        const {
            isAddingRange,
            isSavingRange,
            newRangeName
        } = this.state;
        const isSearched = this.state.isSearched;
        const key = `${manufacturer.id}-${usage}`;
        const isLoading = this.state.isItemLoading[`manufacturer-${key}`];
        const isOpen = this.state.isManufacturerOpen[key];
        const ranges = this.state.rangesByManufacturerID[key] || [];

        if (isSearched && !isOpen) return null;

        return (
            <div className={'manufacturer-container ' + (isOpen ? 'open' : '')}>
                <div className={`header ${isSearched ? '' : 'clickable'}`} onClick={() => this.toggleManufacturerOpen(manufacturer.id, usage)}>
                    {isOpen ?
                        <span className="fas fa-chevron-circle-up" /> :
                        <span className="fas fa-chevron-circle-right" />
                    }
                    {manufacturer.name}
                    {isLoading && <>{' '}<Loader isInline /></>}
                    {!isOpen &&
                        <button className="button delete-button button-primary" onClick={e => {
                            e.stopPropagation();
                            this.confirmDeleteManufacturer(manufacturer.id, usage);
                        }}>
                            <span className="fa fa-times" />
                        </button>
                    }
                </div>

                {isOpen &&
                    <div className="content">
                        {ranges.map(r =>
                            <React.Fragment key={r.id}>
                                {this.renderRange(r, usage)}
                            </React.Fragment>
                        )}
                        {this.canEdit &&
                            <div className="range-container">
                                {isAddingRange == key ?
                                    <form className="header" onSubmit={e => this.addRange(manufacturer, usage, e)}>
                                        <span className="fas fa-plus-circle" />
                                        <input
                                            type="text"
                                            value={newRangeName || ''}
                                            onChange={e => this.setState({
                                                newRangeName: e.target.value
                                            })}
                                            autoFocus={this.focusElement == 'new-range-' + key}
                                            readOnly={isSavingRange == key}
                                        />
                                        <button
                                            className="button button-secondary save-button"
                                            disabled={isSavingRange == key}
                                        >
                                            <span className="fa fa-check" />
                                        </button>
                                    </form> :
                                    !isSearched &&
                                    <div className="header clickable" onClick={() => this.startAddingRange(manufacturer, usage)}>
                                        <span className="fas fa-plus-circle" />
                                        Add a new product range...
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    renderRange(range, usage) {
        const {
            isSearched,
            categoriesByUsage,
            categoriesByID,
            isCreatingOrder
        } = this.state;
        const isLoading = this.state.isItemLoading[`range-${range.id}`];
        const isOpen = this.state.isRangeOpen[range.id];
        const products = this.state[`products-${range.id}`] || [];
        const category = (range.productCategoryID ? categoriesByID[range.productCategoryID] : null);

        if (isSearched && !isOpen) return null;

        const currency = SalonService.getCurrentCurrency();

        return (
            <div className={'range-container ' + (isOpen ? 'open' : '')}>
                <div className={`header ${isSearched ? '' : 'clickable'}`} onClick={() => this.toggleRangeOpen(range.id)}>
                    {isOpen ?
                        <span className="fas fa-chevron-circle-up" /> :
                        <span className="fas fa-chevron-circle-right" />
                    }
                    {range.name}
                    {isLoading && <>{' '}<Loader isInline /></>}
                    {isOpen && this.canEdit &&
                        <select
                            value={range.productCategoryID}
                            onChange={e => this.updateProductRangeFields(range, { productCategoryID: e.target.value })}
                            onClick={e => e.stopPropagation()}
                        >
                            <option value="">(Select category)</option>
                            {categoriesByUsage[usage] && categoriesByUsage[usage].map(c =>
                                <option key={c.id} value={c.id}>
                                    {c.name}
                                </option>
                            )}
                        </select>
                    }
                    {!isOpen &&
                        <button className="button delete-button button-primary" onClick={e => {
                            e.stopPropagation();
                            this.confirmDeleteRange(range.manufacturerCompanyID, range.id, usage);
                        }}>
                            <span className="fa fa-times" />
                        </button>
                    }
                </div>

                {isOpen &&
                    <div className="content">

                        <div className="add-product-button-container">
                            <button className="button button-primary" onClick={() => this.addProduct(range.id, usage)} disabled={!this.canEdit} title="Add a new product">
                                <span className="fa fa-plus" />
                            </button>
                        </div>

                        <div className="table-container">
                            <table className="stock-manager-table">
                                <thead>
                                    <tr>
                                        <th className="name-column">Name</th>
                                        <th className="more-settings-column mobile-only">&nbsp;</th>
                                        <th className="status-column"></th>
                                        {(!category || category.stockItemTracking != 'singleNoSize') &&
                                            <th className="size-column">Size</th>
                                        }
                                        <th className="buy-price-column">Cost {currency.symbol}</th>
                                        {!isCreatingOrder && <>
                                            <th className="sell-price-column">Sell {currency.symbol}</th>
                                            <th className="barcodes-column">Barcode(s)</th>
                                            {/*<th className="sku-column">SKU</th>*/}
                                        </>}
                                        <th className="current-stock-column">Current</th>
                                        <th className="min-stock-column">Min</th>
                                        <th className="ideal-stock-column">Ideal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map(p =>
                                        <React.Fragment key={p.guid}>
                                            {this.renderProduct(p, category, range)}
                                        </React.Fragment>
                                    )}
                                    {products.length == 0 &&
                                        <tr>
                                            <td colSpan={10} className="empty-text">
                                                There are no products this range yet
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                }
            </div>
        );
    }

    renderProduct(product, category, range) {
        const {
            dummySaveStatus,
            isSearching,
            isSearched,
            visibleProductIDs,
            isCreatingOrder
        } = this.state;

        // If searching, only show matching products
        if (isSearching || isSearched && visibleProductIDs && product.productID && !visibleProductIDs[product.productID]) {
            return;
        }

        product = { ...product };
        if (product.stockItems.length < 1) {
            const guid = TextHelpers.getRandomGUID();
            product.stockItems.push({
                productGUID: product.guid,
                guid: guid,
                isNew: true
            });
        }

        const currency = SalonService.getCurrentCurrency();

        return product.stockItems.map((si, index) =>
            <tr key={index}>
                {index == 0 &&
                    <td className="name-column" rowSpan={product.stockItems.length}>
                        <div>
                            <input
                                type="text"
                                value={product.name || ''}
                                onChange={e => this.updateProductFields(range.id, product.guid, { name: e.target.value })}
                                autoFocus={this.focusElement == 'product-' + product.guid}
                                onBlur={() => this.saveProduct(range.id, product.guid)}
                                disabled={!this.canEdit}
                            />

                            <button className="button button-primary settings-button desktop-only" title="More Settings" onClick={() => this.showProductSettingsModal(range.id, product.guid, si.guid)}>
                                <span className="fa fa-cog" />
                            </button>

                        </div>
                    </td>
                }
                <td className="more-settings-column mobile-only">

                    <button className="button button-primary settings-button" title="More Settings" onClick={() => this.showProductSettingsModal(range.id, product.guid, si.guid)}>
                        <span className="fa fa-cog" />
                    </button>

                </td>
                <td className="status-column">
                    {this.renderStockItemAlertIcon(si)}
                </td>
                {(!category || category.stockItemTracking != 'singleNoSize') &&
                    <td className="size-column">
                        <div>
                            <input
                                type="text"
                                value={si.size || ''}
                                onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { size: e.target.value })}
                                onBlur={(e) => this.updateStockItemSize(range.id, product.guid, si.guid, e.target.value)}
                                autoFocus={this.focusElement == 'stock-item-' + si.guid}
                                disabled={!this.canEdit}
                            />
                            {product.stockUnit &&
                                <div className="stock-unit-label">
                                    {product.stockUnit}
                                </div>
                            }

                            {(!category || category.stockItemTracking == 'multiple') &&
                                <button className="button button-primary add-size-button" title="Add a new size underneath this one" disabled={!this.canEdit} onClick={() => this.addStockItem(range.id, product.guid, index)}>
                                    <span className="fa fa-plus" />
                                </button>
                            }

                        </div>
                    </td>
                }
                <td className="cost-price-column">
                    <NumberFormat
                        thousandSeparator={currency.thousandSeparator}
                        decimalSeparator={currency.decimalSeparator}
                        prefix={currency.symbol}
                        className="has-prefix"
                        value={si.costPrice || ''}
                        onValueChange={(values) => this.updateStockItemFields(range.id, product.guid, si.guid, { costPrice: values.value })}
                        onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'costPrice')}
                        disabled={!this.canEdit}
                    />
                </td>
                {!isCreatingOrder && <>
                    <td className="sell-price-column">
                        <NumberFormat
                            thousandSeparator={currency.thousandSeparator}
                            decimalSeparator={currency.decimalSeparator}
                            prefix={currency.symbol}
                            className="has-prefix"
                            value={si.sellPrice || ''}
                            onValueChange={(values) => this.updateStockItemFields(range.id, product.guid, si.guid, { sellPrice: values.value })}
                            onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'sellPrice')}
                            disabled={!this.canEdit}
                        />
                    </td>
                    <td className="barcode-column">
                        <input
                            type="text"
                            value={si.barcodes || ''}
                            onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { barcodes: e.target.value })}
                            onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'barcodes')}
                            disabled={!this.canEdit}
                        />
                    </td>
                    {/*
                    <td className="sku-column">
                        <input
                            type="text"
                            value={si.sku || ''}
                            onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { sku: e.target.value })}
                            onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'sku')}
                        />
                    </td>
                    */}
                </>}
                <td className="current-stock-column">
                    <input
                        type="number"
                        value={si.currentStock || ''}
                        onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { currentStock: e.target.value || 0 })}
                        onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'currentStock')}
                        disabled={!this.canEdit}
                    />
                </td>
                <td className="min-stock-column">
                    <input
                        type="number"
                        value={si.minStock || ''}
                        onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { minStock: e.target.value || 0})}
                        onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'minStock')}
                        disabled={!this.canEdit}
                    />
                </td>
                <td className="ideal-stock-column">
                    <div>
                        <input
                            type="number"
                            value={si.idealStock || ''}
                            onChange={e => this.updateStockItemFields(range.id, product.guid, si.guid, { idealStock: e.target.value || 0})}
                            onBlur={() => this.saveStockItemField(range.id, product.guid, si.guid, 'idealStock')}
                            disabled={!this.canEdit}
                        />

                        <button className="button button-primary save-product-button" title="Save" disabled={!this.canEdit} onClick={() => this.dummySave(si.guid)}>
                            {dummySaveStatus[si.guid] == 'saved' ?
                                <span className="fa fa-check" /> :
                                dummySaveStatus[si.guid] == 'saving' ?
                                    <Loader isInline /> :
                                    <span className="fa fa-save" />
                            }
                        </button>

                        {/*
                        <button className="button button-primary delete-product-button" title="Save" onClick={() => this.dummySave(si.guid)}>
                            {dummySaveStatus[si.guid] == 'saved' ?
                                <span className="fa fa-check" /> :
                                dummySaveStatus[si.guid] == 'saving' ?
                                    <Loader isInline /> :
                                    <span className="fa fa-save" />
                            }
                        </button>
                        */}

                    </div>
                </td>
            </tr>
        );
    }

    renderStockItemAlertIcon(stockItem) {
        const cls = 'rag-indicator-' + (stockItem.stockAlertLevel || 'unknown').toLowerCase();
        return (
            <span className={'fas fa-circle rag-indicator ' + cls}></span>
        );
    }
};

export default withRouter(StockManagerPage);