// Libs
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
//import CreatableSelect      from 'react-select/creatable';

// Services & Helpers
import StockService from 'services/StockService';
import SearchService from 'services/SearchService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';
import TaxRateService from 'services/TaxRateService';
import GlobalStateService from 'services/GlobalStateService';

// Components
import Loader               from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
import InfoBar from 'components/layout/InfoBar'

//-------------------------------------------------------------------------------------------------------------------

class StockCategorisePage extends React.Component {

    constructor(props) {
        super(props);

        this.NumItemsPerPage = 100;

        // Init state
        this.state = {
            isLoading: true,
            showCategorised: false,
            checkedAllProducts: false
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    componentWillMount() {
        this.load();
    }

    async load() {
        const usages = [
            { name: 'Retail', id: 'retail' },
            { name: 'Professional', id: 'professional' }
        ];

        // Trigger requests
        const manufacturersReq = StockService.listManufacturers();
        const rangesReq = StockService.listRanges();
        const productsReq = StockService.listProducts();
        const taxRatesReq = TaxRateService.list();

        // Gather results
        const manufacturers = await manufacturersReq;
        const ranges = await rangesReq;
        const allProducts = await productsReq;
        const taxRates = await taxRatesReq;

        // Load extra information into product
        allProducts.forEach(product => {
            const range = ranges.find(pr => pr.id == product.productRangeID);
            product.usage = range ? range.usage : null;
            product.manufacturerCompanyID = range ? range.manufacturerCompanyID : null;
            product.sizes = product.stockItems.map(si => (si.size || '') + (product.stockUnit || '')).join(', ');
            product.isCategorisedNew = product.isCategorised;
        });

        const products = this.getFiltered(allProducts, false);

        this.setState({
            usages,
            manufacturers,
            products,
            allProducts,
            ranges,
            taxRates,
            pageNum: 0,
            numPages: Math.ceil(products.length / this.NumItemsPerPage)
        }, () => {
            this.loadPage(0);
        });
    }

    getFiltered(products, showCategorised) {
        if (!products) {
            products = this.state.products;

            if (!products || products.length == 0) {
                this.load();
            }
        }
        if (!showCategorised) {
            products = products.filter(p => !p.isCategorised);
        }
        if (this.state.isSearched && this.state.visibleProductIDs) {
            products = products.filter(p => this.state.visibleProductIDs[p.productID]);
        }

        this.setState({
            numPages: Math.ceil(products.length / this.NumItemsPerPage)
        })
        return products;
    }

    async loadPage(pageNum) {
        this.setState({
            isLoading: true            
        });
        while (pageNum * this.NumItemsPerPage > this.state.products.length) {
            pageNum--;
        }
        const productsThisPage = this.state.products.slice(this.NumItemsPerPage * pageNum, this.NumItemsPerPage * pageNum + this.NumItemsPerPage);
        this.setState({
            checkedAllProducts: productsThisPage.every(p => p.canDelete == true),
            productsThisPage,
            pageNum,
            isLoading: false
        });
    }

    updateProductFields(productID, newValues) {
        const allProducts = [...this.state.allProducts];
        const index = allProducts.findIndex(p => p.productID == productID);
        const product = { ...allProducts[index] };
        let isCategorisedSet = false;
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            const oldValue = product[fieldName];
            product[fieldName] = value;
            product.isChanged = true;

            switch (fieldName) {
                case 'isCategorisedNew':
                    isCategorisedSet = true;
                    break;
                case 'manufacturerCompanyID':
                    if (value == 'new') {
                        this.addNewManufacturer(product.productID, oldValue);
                    }
                    break;
                case 'productRangeID':
                    if (value == 'new') {
                        this.addNewRange(product, oldValue);
                    }
                    break;
                case 'taxRateID':
                    if (value == 'new') {
                        this.addNewTaxRate(product, oldValue);
                    }
                    break;
                case 'canDelete':
                    isCategorisedSet = true;// just to make sure it doesn't effect the categorisation
                    break;
            }
            
        }

        if (!isCategorisedSet) {
            product.isCategorisedNew = !!(product.usage && product.manufacturerCompanyID && product.productRangeID && product.taxRateID);
        }

        allProducts[index] = product;
        
        this.setState({
            allProducts,
            products: this.getFiltered(allProducts, this.state.showCategorised)
        }, () => {
            this.loadPage(this.state.pageNum);
        });
    }

    async addNewManufacturer(productID, oldManufacturerCompanyID) {
        const name = await BootboxHelper.prompt('Manufacturer name');
        if (name) {
            const result = await StockService.saveManufacturer({
                name
            });
            const manufacturers = [...this.state.manufacturers];
            manufacturers.push({
                id: result.id,
                name
            });
            this.setState({
                manufacturers
            }, () => {
                this.updateProductFields(productID, {
                    manufacturerCompanyID: result.id
                });
            });
        } else {
            this.updateProductFields(productID, {
                manufacturerCompanyID: oldManufacturerCompanyID
            });
        }
    }

    async addNewRange(product, oldProductRangeID) {
        const name = await BootboxHelper.prompt('Range name');
        if (name) {
            const newRange = {
                manufacturerCompanyID: product.manufacturerCompanyID,
                usage: product.usage,
                name
            };
            newRange.id = (await StockService.saveRange(newRange)).id;
            const ranges = [...this.state.ranges];
            ranges.push(newRange);
            this.setState({
                ranges
            }, () => {
                this.updateProductFields(product.productID, {
                    productRangeID: newRange.id
                });
            });
        } else {
            this.updateProductFields(product.productID, {
                productRangeID: oldProductRangeID
            });
        }
    }

    async addNewTaxRate(product, oldTaxRateID) {
        let rate = (await BootboxHelper.prompt('Tax rate (%)') || '');
        rate = rate.match(/([0-9\.]+)/)[1];
        
        if (rate) {
            const newTaxRate = {
                name: `Tax at ${rate}%`,
                rate
            };
            rate /= 100;
            newTaxRate.id = (await TaxRateService.save(newTaxRate)).id;
            const taxRates = [...this.state.taxRates];
            taxRates.push(newTaxRate);
            this.setState({
                taxRates
            }, () => {
                this.updateProductFields(product.productID, {
                    taxRateID: newTaxRate.id
                });
            });
        } else {
            this.updateProductFields(product.productID, {
                taxRateID: oldTaxRateID
            });
        }
    }

    async save() {
        const changedProducts = this.state.products.filter(p => p.isChanged);
        const changedProductIDs = {};
        changedProducts.forEach(p => {
            changedProductIDs[p.productID] = true;
        });

        if (changedProducts.length > 0) {
            this.setState({ isLoading: true });
            const errorProductIDs = {};
            const errorProducts = await StockService.bulkUpdate(changedProducts);
            if (errorProducts.length > 0) {
                let msg = 'Some products couldn\'t be updated:';
                msg += '<ul>';
                errorProducts.forEach(ep => {
                    msg += '<li>';
                    msg += `    ${TextHelpers.escapeHTML(ep.product.name)}: ${ep.errors.join(', ')}`;
                    msg += '</li>';

                    errorProductIDs[ep.product.productID] = true;
                });
                msg += '</ul>';
                BootboxHelper.alert(msg);
            }

            // Remove any products successfully updated
            const allProducts = [...this.state.allProducts];
            for (let i = 0; i < allProducts.length; i++) {
                let product = allProducts[i];
                if (changedProductIDs[product.productID] && !errorProductIDs[product.productID]) {
                    product = { ...product };
                    product.isChanged = false;
                    product.isCategorised = product.isCategorisedNew;
                    allProducts[i] = product;
                }
            }

            this.setState({
                isLoading: false,
                allProducts,
                products: this.getFiltered(allProducts, this.state.showCategorised)
            }, () => {
                this.loadPage(this.state.pageNum);
            });
        } else {
            BootboxHelper.alert('There are no changes to save');
        }
    }

    toggleShowCategorised() {
        this.setState({ isLoading: true });
        const showCategorised = !this.state.showCategorised;
        this.setState({
            isLoading: false,
            showCategorised,
            products: this.getFiltered(this.state.allProducts, showCategorised)
        }, () => {
            this.loadPage(0);
        });
    }

    async debounceSearch(query) {
        clearTimeout(this.searchTimeout);
        if (query) {
            this.searchTimeout = setTimeout(() => {
                this.search(query);
            }, 250);
        } else {
            this.setState({
                visibleProductIDs: null,
                isSearched: false
            }, () => {
                this.setState({
                    products: this.getFiltered(this.state.allProducts, this.state.showCategorised)                   
                }, () => {
                    this.loadPage(this.state.pageNum);
                });
            });
        }
    }

    async search(query) {
        const results = await SearchService.search(query, ['StockItem', 'Product']) || [];
        const visibleProductIDs = {};
            results.forEach(r => {
                if (r.type == 'Product') {
                    visibleProductIDs[parseInt(r.id)] = true;
                } else if (r.type == 'StockItem') {
                    visibleProductIDs[parseInt(r.productID)] = true;
                }
            });

        this.setState({
            isSearched: true,
            visibleProductIDs
        }, () => {
            this.setState({
                products: this.getFiltered(this.allProducts, this.state.showCategorised)
            }, () => {
                this.loadPage(this.state.pageNum);
            });
        });
    }

    checkAll(value) {
        const productsThisPage = [...this.state.productsThisPage];

        productsThisPage.forEach(product => {
            product.canDelete = value;
            this.updateProductFields(product.productID, {
                canDelete: value
            })
        });

        this.setState({
            productsThisPage,
            checkedAllProducts : value
        })
    }

    async delete() {
        const productIds = this.state.allProducts.filter(x => x.canDelete == true);
        if (productIds && productIds.length > 0) {
            var confirm = await BootboxHelper.confirm(`Are you sure you want to delete this product${productIds.length > 1 ? 's? These' : '? It'} will delete from all areas of Stockroom.`);
            if (confirm) {
                this.setState({
                    isLoading: true
                });
                productIds.forEach(async (product) => {
                    await StockService.deleteProduct(product.productID);
                });
                this.setState({
                    isSearched: false
                });
                this.load();
            }
        }
        else {
            BootboxHelper.alert('Please select the products to delete.');
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        setTimeout(() => {
            this.focusElement = null;
        }, 0);

        return (<>

            <div className="page-content">

                <div className="page-content-left"></div>

                <div className="page-content-right">

                    <div className="page-content-right-inner">

                        {this.renderInfoBar()}

                    </div>

                </div>

            </div>

            <div className="stock-categorise-content">

                <div className="panel stock-categorise-filter-panel sticky-topmost">

                    {this.renderFilterPanel()}

                </div>

                <div className="panel stock-categorise-main">

                    {this.renderMainContent()}

                </div>

            </div>

        </>);
    }

    renderInfoBar() {
        const {
            showCategorised
        } = this.state;

        const loginDetails = GlobalStateService.getValue('loginDetails');
        return (
            <InfoBar className="stock-categorise-info-bar">

                <div className="info-bar-panel-section info-bar-panel-section-text">

                    Categorise Stock

                </div>

                <div className="info-bar-panel-section ml-auto">
                    <button type="button" className="button me-3" style={{ width: 100 }} onClick={() => this.delete()}>
                        <span className="fa fa-trash"></span>{' '}
                        Delete
                    </button>
                    <button type="button" className="button me-3" style={{ width: 190 }} onClick={() => this.toggleShowCategorised()}>
                        {showCategorised ?
                            <span className="far fa-square-check me-2"></span> :
                            <span className="far fa-square me-2"></span>
                        }
                        Show already done
                    </button>

                    <button type="button" className="button" style={{ width: 145 }} onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save changes
                    </button>

                </div>
                <div className="info-bar-panel-section">
                    {loginDetails.permissions['StockroomViewStockManager'] &&
                        <Link className="button stock-manager-button" to="/stock/manager">
                            <span className="fa fa-boxes"></span>{' '}
                            Stock Manager
                        </Link>
                    }
                </div>

            </InfoBar>
        );
    }

    renderFilterPanel() {
        const {
            isSearched,
            isSearching
        } = this.state;

        return (<>

            <input type="search" placeholder="Type to search..." onChange={e => this.debounceSearch(e.target.value)} />

            {isSearching ? <>
                {' '}<Loader isInline />
            </> :
                isSearched && <>
                    {' '}<span className="fa fa-check" />
                </>
            }

        </>);

    }

    renderMainContent() {
        const {
            isLoading,
            usages,
            manufacturers,
            numPages,
            pageNum,
            productsThisPage,
            ranges,
            taxRates,
            checkedAllProducts
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const pages = [...new Array(numPages).keys()];
        const pagination = numPages > 1 && (
            <ul className="pagination">
                {pages.map(page =>
                    <li key={page} className={pageNum == page ? 'active' : ''} onClick={e => this.loadPage(page)}>
                        {(page + 1)}
                    </li>
                )}
            </ul>
        );

        return (<>

            {pagination}

            <SuperTable
                className="stock-categorise-table"
                rows={productsThisPage}
                keyAccessor={p => p.productID}
                emptyText="You have no products that need to be categorised"
                cols={{
                    name: { label: 'Name' },
                    sizes: { label: 'Size(s)' },
                    usage: {
                        label: 'Usage',
                        getValue: (colInfo, product) =>
                            <select
                                value={product.usage || ''}
                                onChange={e => this.updateProductFields(product.productID, {
                                    usage: e.target.value
                                })}
                            >
                                <option value="Unspecified">(Please select)</option>
                                {usages.map(usage =>
                                    <option key={usage.id} value={usage.id}>{usage.name}</option>
                                )}
                            </select>
                    },
                    manufacturerCompanyID: {
                        label: 'Manufacturer',
                        getValue: (colInfo, product) =>
                            <select
                                value={product.manufacturerCompanyID || ''}
                                onChange={e => this.updateProductFields(product.productID, {
                                    manufacturerCompanyID: e.target.value,
                                    productRangeID: null
                                })}
                            >
                                <option value="">(Please select)</option>
                                <option value="new">(Add a new manufacturer)</option>
                                {manufacturers.map(mc =>
                                    <option key={mc.id} value={mc.id}>{mc.name}</option>
                                )}
                            </select>
                    },
                    productRangeID: {
                        label: 'Range',
                        getValue: (colInfo, product) =>
                            product.manufacturerCompanyID &&
                            <select
                                value={product.productRangeID || ''}
                                onChange={e => this.updateProductFields(product.productID, { productRangeID: e.target.value })}
                            >
                                <option value="">(Please select)</option>
                                <option value="new">(Add a new range)</option>
                                {ranges.filter(r => r.manufacturerCompanyID == product.manufacturerCompanyID && r.usage == product.usage).map(pr =>
                                    <option key={pr.id} value={pr.id}>{pr.name}</option>
                                )}
                            </select>
                    },
                    taxRateID: {
                        label: 'Tax Rate',
                        getValue: (colInfo, product) =>
                            <select
                                value={product.taxRateID || ''}
                                onChange={e => this.updateProductFields(product.productID, { taxRateID: e.target.value })}
                            >
                                <option value="">(Please select)</option>
                                <option value="new">(Add a new tax rate)</option>
                                {taxRates.map(tr =>
                                    <option key={tr.id} value={tr.id}>{tr.name}</option>
                                )}
                            </select>
                    },
                    isCategorised: {
                        label: 'Done',
                        getValue: (colInfo, product) =>
                            <input
                                type="checkbox"
                                className="large-checkbox"
                                checked={product.isCategorisedNew || false}
                                onChange={e => this.updateProductFields(product.productID, { isCategorisedNew: e.target.checked })}
                            />
                    },
                    canDelete: {
                        label: <div style={{ width: 100, marginLeft: 10 }}>
                            <span className="fa fa-trash"></span>{' '}
                            <input type="checkbox" className="trash-check-box" checked={checkedAllProducts} onChange={e => this.checkAll(e.target.checked)} />
                        </div>,
                        getValue: (colInfo, product) =>
                            <input
                                type="checkbox"
                                checked={product.canDelete}
                                onChange={e => this.updateProductFields(product.productID, { canDelete: e.target.checked })}
                            />
                    }
                }}
            />

            {pagination}

        </>);
    }

};

export default withRouter(StockCategorisePage);