import bootbox from 'bootbox';
import 'bootstrap/dist/js/bootstrap.js';

export default class BootboxHelper {

    static alert(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.alert({
                ...opt,
                message: message,
                callback: function () {
                    resolve();
                }
            });
        });
    }

    static confirm(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.confirm({
                ...opt,
                buttons: {
                    cancel: {
                        className: 'mr-auto',
                        label: 'No'
                    },
                    confirm: {
                        className: 'ml-auto',
                        label: 'Yes'
                    }
                },
                message: message,
                callback: function (result) {
                    resolve(result);
                }
            });
        });
    }

    static confirmWithButtonSwap(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.confirm({
                ...opt,
                swapButtonOrder: true,
                buttons: {
                     confirm: {
                         className: 'mr-auto confirm-yes',
                        label: 'Yes'
                    },
                    cancel: {
                        className: 'ml-auto confirm-no',
                        label: 'No'
                    },
                },
                message: message,
                callback: function (result) {
                    resolve(result);
                }
            });
        });
    }

    static prompt(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.prompt({
                ...opt,
                title: message,
                callback: function (result) {
                    resolve(result);
                }
            });
        });
    }

    static hide() {
        return bootbox.hideAll();
    }

}

