// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import GlobalStateService from 'services/GlobalStateService';
import ThermalPrinterService from 'services/ThermalPrinterService';
import ServiceService from 'services/ServiceService';
import CommissionService from 'services/CommissionService';
import PromoCodeService from 'services/PromoCodeService';
import EvoService from 'services/EvoService';
import UserService from 'services/UserService';
import RetailService from 'services/RetailService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import CustomerSummary from 'components/reusable/CustomerSummary';

import PaymentPanel from './checkout/PaymentPanel';
import CheckOutPanel from './checkout/CheckOutPanel';
import NextAppoinmentsPanel from './checkout/NextAppointmentsPanel';
import PosTerminalPanel from './checkout/PosTerminalPanel';
import TipsPanel from './checkout/TipsPanel';

//-------------------------------------------------------------------------------------------------------------------

class CheckOut extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.paymentPanelRef = React.createRef();

        this.state = {
            isLoading: true,
            isLoadingStep: false,
            checkOut: null,
            isTransactionComplete: true,
            step: (props.step || (props.isAddingDeposit || props.goToPayment ? 3 : 1))
        };

        this.serviceSequenceID = -100;
        this.purchaseSequenceID = -1;

        this.clientInfo = GlobalStateService.getValue('clientInfo');

        this.save = this.save.bind(this);
        this.goBack = this.goBack.bind(this);
        this.viewCustomer = this.viewCustomer.bind(this);
    }

    componentDidMount() {
        this.load();

        setTimeout(() => {
            $('[data-appointment-id=' + this.props.appointmentID + ']').closest('.rbc-event').addClass('highlighted');
        }, 0);
    }

    componentWillUnmount() {
        clearTimeout(this.confirmTimeout);
    }

    async load() {
        // Load info
        const checkOut = await DiaryService.loadCheckOut(this.props.appointmentID);
        const isSplitCommissionEnabled = await CommissionService.getSplitCommissionStatus();
        var commissions = [];
        if (isSplitCommissionEnabled) {
            commissions = await CommissionService.list();
            commissions.map((c, index) => {
                if (c.commissionPercentage <= 1) {
                    var cp = Math.round((1 - c.commissionPercentage) * 10000) / 10000
                    const isExists = commissions.some(c => c.commissionPercentage == cp);
                    if (!isExists) {
                        commissions.push({
                            commissionPercentage: cp
                        })
                    }
                }
            });

            // Add any missing commission info
            checkOut.appointmentServices.forEach(asv => {
                if (!asv.appointmentServiceUserCommissions || asv.appointmentServiceUserCommissions.length == 0) {
                    const userID = asv.salesUserID || asv.stylistUserID;
                    if (userID) {
                        asv.appointmentServiceUserCommissions = [{ stylistUserID: userID, commissionProportion: 1 }]
                    }
                }
            });
        }

        // Valid Promo codes list for appointment
        const apptPromoCodes = await PromoCodeService.getPromoCodes(this.props.appointmentID);
        const promoCodes = await PromoCodeService.list();//Total Promo codes list
        checkOut.apptPromoCodes = apptPromoCodes;       

        let paymentMethods = await DiaryService.loadPaymentMethods();
        const cardPM = paymentMethods.find(pm => pm.code == 'Card');

        checkOut.canApplyPromo = checkOut.promoCodeID ? true : false;
        const users = await UserService.list();

        //Voucher list for appointment
        const voucherList = await RetailService.listVouchersForAppointment(this.props.appointmentID);
        checkOut.apptVoucherList = voucherList;
        
        if (!checkOut.dateCheckedOut) {
            await this.addAutomaticallyAddedServices(checkOut, promoCodes);
        }

        this.clientInfo = GlobalStateService.getValue('clientInfo');
        checkOut.paymentProvider = this.clientInfo.paymentProvider;
        checkOut.appointmentServices.forEach(asv => {
            if (asv.time) {
                asv.time = new Date(asv.time);
            }
        });

        // Init payments
        checkOut.newPayments = checkOut.appointmentPayments.map(ap => ({ ...ap }));
        checkOut.tipsPaid = checkOut.tipTotal;

        // Load loyalty scheme info
        let loyaltyScheme = null;
        if (checkOut.status != 'CheckedOut') {
            loyaltyScheme = await LoyaltySchemeService.get();
        }

        // Update
        DiaryService.updateBalance(checkOut);

        // Display
        await this.setStateAsync({
            checkOut,
            promoCodes,
            loyaltyScheme,
            isSplitCommissionEnabled,
            commissions,
            paymentMethods,
            cardPM,
            users,
            isLoading: false
        });
    }

    async addAutomaticallyAddedServices(checkOut, promoCodes) {
        const servicesToAddAutomatically = await ServiceService.getAutomaticallyAddedServices();
        if (servicesToAddAutomatically.length > 0) {
            servicesToAddAutomatically.forEach(async st => {
                const isServiceAlreadyAdded = checkOut.appointmentServices.some(s => s.service.serviceID == st.serviceID);
                if (!isServiceAlreadyAdded) {
                    const serviceDetails = await ServiceService.get(st.serviceID);
                    const price = this.props.getServicePrice(st.serviceID, 0);

                    const appointmentService = {
                        appointmentServiceID: this.serviceSequenceID--,
                        appointmentID: 0,
                        stylistUserID: null,
                        salesUserID: null,
                        service: serviceDetails,
                        total: price
                    };
                    checkOut.appointmentServices.push(appointmentService);
                    var canApplyPromo = false, isPromoService = false;
                    var promoCode = checkOut.apptPromoCodes?.find(ap => ap.promoCodeID == checkOut.promoCodeID);
                    if (promoCode) {
                        if (checkOut.appointmentServices) {
                            checkOut.appointmentServices.forEach(asv => {
                                if (promoCode.promoServiceApplicability == 'selected') {
                                    isPromoService = promoCode.servicePromoCodeIDs.includes(asv.service.serviceID);
                                }
                                else if (promoCode && promoCode.promoServiceApplicability == 'all') {
                                    isPromoService = true;
                                }
                                asv.canApplyPromo = isPromoService;

                                if (asv.appointmentPackageID && !promoCode.useForPackage && isPromoService) {
                                    asv.canApplyPromo = false;
                                }
                            });
                        }
                        canApplyPromo = checkOut.appointmentServices.some(as => as.canApplyPromo);
                    }
                    checkOut.canApplyPromo = canApplyPromo;
                    DiaryService.updateTotals(checkOut, this.state.promoCodes);
                    DiaryService.updateBalance(checkOut);
                    this.isScheduleChanged = true;
                }
            });
        }
    }

    async updateFields(values) {
        const checkOut = { ...this.state.checkOut };

        for (var field in values) {
            let value = values[field];

            switch (field) {
                case 'appointmentServices':
                case 'appointmentPackages':
                    this.isScheduleChanged = true;
                    break;
                case 'discountType':
                    if (value == 'percentage') {
                        if (checkOut.appointmentServices.every(asv => !asv.isDiscounted)) {
                            checkOut.appointmentServices.forEach(asv => {
                                asv.isDiscounted = true;
                            });
                        }
                    } else if (!value) {
                        checkOut.discountTotal = 0;
                        checkOut.discountAmount = 0;
                        checkOut.discountReason = null;
                    }
                    break;
                case 'discountAmount':
                    if (value < 0) {
                        value = -value;
                    }
                    break;
                case 'retailDiscountType':
                    if (value === 'percentage' || value === 'amount') {
                        if (checkOut.appointmentPurchases.every(asv => !asv.isDiscounted)) {
                            checkOut.appointmentPurchases.forEach(asv => {
                                asv.isDiscounted = true;
                            });
                        }
                    } else if (!value) {
                        checkOut.retailDiscountTotal = 0;
                        checkOut.retailDiscountAmount = 0;
                        checkOut.discountReason = null;
                    }
                    break;
                case 'retailDiscountAmount':
                    if (value < 0) {
                        value = -value;
                    }
                    break;
            }

            checkOut[field] = value;
        }
        var canApplyPromo = false, isPromoService = false;
        var promoCode = checkOut.apptPromoCodes?.find(ap => ap.promoCodeID == checkOut.promoCodeID);
        if (promoCode) {
            if (checkOut.appointmentServices) {
                checkOut.appointmentServices.forEach(asv => {
                    if (promoCode.promoServiceApplicability == 'selected') {
                        isPromoService = promoCode.servicePromoCodeIDs.includes(asv.service.serviceID);
                    }
                    else if (promoCode && promoCode.promoServiceApplicability == 'all') {
                        isPromoService = true;
                    }
                    asv.canApplyPromo = isPromoService;
                    if (asv.appointmentPackageID && !promoCode.useForPackage && isPromoService) {
                        asv.canApplyPromo = false;
                    }
                });
                canApplyPromo = checkOut.appointmentServices.some(as => as.canApplyPromo);
            }
        }
        checkOut.canApplyPromo = canApplyPromo;
        DiaryService.updateTotals(checkOut, this.state.promoCodes);
        DiaryService.updateBalance(checkOut);
        await this.setStateAsync({
            checkOut
        });
    }

    checkOutChanged(checkOut) {
        this.setState({
            checkOut
        });
    }

    updateButtonText() {
        return PaymentPanel.updateButtonText(this.state.checkOut, 'Complete checkout', this.state.paymentMethods);
    }

    updateDepositButtonText() {
        return PaymentPanel.updateButtonText(this.state.checkOut, 'Add Deposit', this.state.paymentMethods);
    }

    canSave() {
        return PaymentPanel.canGoNext(this.state.checkOut);
    }

    canPayDeposit() {
        return PaymentPanel.canPayDeposit(this.state.checkOut);
    }

    canCheckOut() {
        let tipTotal = 0;
        const appointmentTips = this.state.checkOut.appointmentTips;
        appointmentTips.forEach(at => {
            tipTotal += at.amount;
        });
        if (tipTotal == this.state.tipAmountFromDevice)
            return true;
        else
            return false;
    }

    canContinue() {
        if (this.state.checkOut.paymentDeviceId > 0)
            return true;
        else
            return false;
    }

    async checkForTransactionDetails(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == EvoService.RESPONSE_CANCELLED || transactionResponse == EvoService.RESPONSE_REFUSED || transactionResponse == EvoService.RESPONSE_SUCCESS) {
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            if (transactionResponse == EvoService.RESPONSE_SUCCESS) {
                let newPayments = this.state.checkOut.newPayments.filter(np => np.paymentMethodID == this.state.cardPM.paymentMethodID && !np.id
                    && !np.isSavedCard && !np.txnReference);

                if (newPayments.length > 0) {
                    newPayments[0].txnReference = transactionId;
                    this.save(true);
                }
            }
            else if (transactionResponse == EvoService.RESPONSE_CANCELLED) {
                BootboxHelper.alert("Card transacation cancelled.Please try again.");
                this.setState({
                    isTransactionComplete: false,
                    isLoading: false
                });
                this.goBack();
            }
            else if (transactionResponse == EvoService.RESPONSE_REFUSED) {
                BootboxHelper.alert("Card payment declined. Please try with other payment method.");
                this.setState({
                    isTransactionComplete: false,
                    isLoading: false
                });
                this.goBack();
            }
        }
    }

    async checkForIncompleteTransaction(transactionId, paymentDeviceId) {
        const transactionResponse = await DiaryService.getTransactionDetails(transactionId, paymentDeviceId);
        if (transactionResponse == 'Fetching Details') {
            BootboxHelper.alert('Please make sure to select the correct payment device.');
            clearTimeout(this.checkIfPosPendingTransactionTimeout);
            clearInterval(this.checkTransactionCompleteInterval);
            clearTimeout(this.checkIfPosIncompleteTransactionTimeout);
            this.setState({
                isTransactionComplete: false
            });
            this.goBack();
        }
    }

    async save(goToNextStep) {
        const {
            checkOut,
            step
        } = this.state;
        const {
            isAddingDeposit
        } = this.props;

        // Validation
        if (step == 1 && checkOut.appointmentServices.length == 0 && checkOut.appointmentPackages.length == 0) {
            BootboxHelper.alert('There are no services to check out on this appointment');
            return;
        }
        else if (step == 3) {
            if (checkOut.newBalance > 0 && !isAddingDeposit) {
                BootboxHelper.alert('The appointment needs to be fully paid to complete check out.');
                return;
            }
            const anyPaymentsWithNoPaymentMethod = checkOut.newPayments.some(ap => !ap.id && !ap.paymentMethodID);
            if (anyPaymentsWithNoPaymentMethod) {
                BootboxHelper.alert('Please make sure that all payments have a Payment Method selected');
                return;
            }
        }
        //send the payment to card machine
        else if (step == 4 && !goToNextStep) {
            this.setState({
                isLoadingStep: true
            });
            if (checkOut.paymentDeviceId <= 0) {
                BootboxHelper.alert('Please make sure to select the payment device.');
                return;
            }

            let newPayments = checkOut.newPayments.filter(np => np.paymentMethodID == this.state.cardPM.paymentMethodID && !np.id && !np.isSavedCard && !np.txnReference);
            if (newPayments.length > 0) {
                let response = await DiaryService.sendToCardMachine(newPayments[0].amount, checkOut);
                BootboxHelper.alert('Please wait while we process your payment <br/> Please do not refresh or close this screen');
                this.checkTransactionCompleteInterval = setInterval(() => {
                    this.checkForTransactionDetails(response.TransactionId, response.PaymentDeviceId);
                }, 1000);

                //check if anything needs to be completed on Pos Device
                this.checkIfPosPendingTransactionTimeout = setTimeout(function () {
                    BootboxHelper.alert('Pos is busy. Please complete the transaction on the terminal');
                }, 50000);

                this.checkIfPosIncompleteTransactionTimeout = setTimeout(() => {
                    this.checkForIncompleteTransaction(response.TransactionId, response.PaymentDeviceId);
                }, 100000);
            }
        }
        this.setState({ isLoadingStep: true });

        if (step == 1) {
            if (this.isScheduleChanged) {
                const { apptServiceIDMapping } = await DiaryService.saveAppointmentSchedule(checkOut);
                checkOut.appointmentServices.forEach(asv => {
                    asv.appointmentServiceID = apptServiceIDMapping[asv.appointmentServiceID];
                });
                this.isScheduleChanged = false;
            }
            try {
                await DiaryService.saveCheckOut(checkOut);
                this.props.onSave();
            } catch (error) {
                BootboxHelper.alert(error);
                this.setState({ isLoadingStep: false });
                return;
            }
        }

        if (goToNextStep) {
            switch (step) {
                case 1:
                    this.setState({ step: 2, isLoadingStep: false });
                    break;
                case 2:
                    this.setState({ step: 3, isLoadingStep: false });
                    break;
                case 3:
                    // Save
                    const pendingCardPayments = checkOut.newPayments.find(ap => ap.paymentMethodID == this.state.cardPM.paymentMethodID && !ap.id && !ap.isSavedCard && !ap.txnReference);
                    checkOut.isCheckoutComplete = !isAddingDeposit;
                    checkOut.isAddingDeposit = isAddingDeposit;
                    checkOut.isPendingPosTransaction = checkOut.paymentProvider == 'evo' && pendingCardPayments ? true : false;
                    try {
                        const { openCashDrawer } = await DiaryService.saveCheckOut(checkOut);
                        this.props.onSave();
                        this.setState({ isLoadingStep: false });

                        // Print
                        if (checkOut.printReceipt) {
                            ThermalPrinterService.printApptReceiptWithPDFFallback(checkOut.appointmentID);
                        }

                        // Open cash drawer
                        if (openCashDrawer) {
                            ThermalPrinterService.openCashDrawers(false);
                        }

                        if (pendingCardPayments && (checkOut.paymentProvider == 'evo')) {
                            this.setState({ step: 4, isLoadingStep: false });
                        }
                        else {
                            this.setState({ step: 6, isLoadingStep: false });
                            this.confirmTimeout = setTimeout(() => {
                                this.props.onCheckoutComplete();
                            }, 3 * 1000);
                        }
                    } catch (error) {
                        BootboxHelper.alert(error);
                        this.setState({ isLoadingStep: false });
                        return;
                    }
                    break;
                case 4:
                    checkOut.isCheckoutComplete = !isAddingDeposit;
                    checkOut.isAddingDeposit = isAddingDeposit;
                    const cardPayments = checkOut.newPayments.find(ap => ap.paymentMethodID == this.state.cardPM.paymentMethodID && !ap.id && !ap.isSavedCard && !ap.txnReference);
                    checkOut.isPendingPosTransaction = checkOut.paymentProvider == 'evo' && cardPayments ? true : false;
                    try {
                        const { tipAmountFromDevice } = await DiaryService.saveCheckOut(checkOut);
                        this.props.onSave();
                        this.setState({ isLoadingStep: false });
                        this.setState({ tipAmountFromDevice: tipAmountFromDevice });
                        if (tipAmountFromDevice > 0) {
                            this.setState({ step: 5, isLoadingStep: false });
                        }
                        else if (cardPayments && (checkOut.paymentProvider == 'evo')) {
                            BootboxHelper.hide();
                            this.setState({ step: 4, isLoadingStep: false });
                        }
                        else {
                            this.setState({ step: 6, isLoadingStep: false });
                            this.confirmTimeout = setTimeout(() => {
                                this.save(true);
                            }, 4 * 1000);
                        }
                    } catch (error) {
                        BootboxHelper.alert(error);
                        this.setState({ isLoadingStep: false });
                        return;
                    }
                    break;
                case 5:
                    checkOut.isCheckoutComplete = false;
                    checkOut.isAddingDeposit = isAddingDeposit;
                    checkOut.isPendingPosTransaction = false;
                    try {
                        await DiaryService.saveCheckOut(checkOut);
                        this.props.onSave();
                        BootboxHelper.hide();
                        this.props.onCheckoutComplete();
                    } catch (error) {
                        BootboxHelper.alert(error);
                        this.setState({ isLoadingStep: false });
                        return;
                    }
                    break;
                case 6:
                    BootboxHelper.hide();
                    this.props.onCheckoutComplete();
                    break;
            }
        } else if (step != 4) {
            this.props.onBackClicked();
        }
    }

    goBack() {
        if (!this.state.isTransactionComplete) {
            this.setState({ step: 3 });
            this.setState({ isTransactionComplete: true });

            this.setState({
                isLoadingStep: false
            });
            return;
        }

        if (this.props.isAddingDeposit) {
            this.props.onBackClicked();
            return;
        }
        const step = this.state.step;
        switch (step) {
            case 1:
                this.props.onBackClicked();
                break;
            case 2:
            case 3:
                this.setState({ step: step - 1 });
                break;
            case 4:
                this.setState({ step: step - 1 });
                break;
            case 5:
                this.setState({ step: step - 1 });
                break;
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Services
    //--------------------------------------------------------------------------------------------------------------------

    async updateAppointmentServiceFields(index, values) {
        const appointmentServices = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices[index];
        for (let fieldName in values) {
            const value = values[fieldName];
            apptService[fieldName] = value;
            if (fieldName == 'stylistUserID') {
                apptService.total = this.props.getServicePrice(apptService.service.serviceID, parseInt(value));
            }
        }
        await this.updateFields({ appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }

    // Called from DiaryPage
    async move(apptServiceID, stylistUserID, time) {
        const index = this.state.checkOut.appointmentServices.findIndex(asv => asv.appointmentServiceID == apptServiceID);
        const appointmentService = this.state.checkOut.appointmentServices[index];
        const fields = {
            stylistUserID: stylistUserID,
            time
        };
        if (appointmentService.salesUserID == appointmentService.stylistUserID) {
            fields.salesUserID = stylistUserID;
        }
        this.updateAppointmentServiceFields(index, fields);
    }

    viewCustomer() {
        this.props.history.push('/customer/' + this.state.checkOut.customer.customerID);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderStep1() {
        return (<>
            <CheckOutPanel
                checkOut={this.state.checkOut}
                loyaltyScheme={this.state.loyaltyScheme}
                promoCodes={this.state.promoCodes}
                onChange={(a, b) => {
                    this.isScheduleChanged = true;
                    this.props.onChange(a, b);
                }}
                getServicePrice={this.props.getServicePrice}
                isSplitCommissionEnabled={this.state.isSplitCommissionEnabled}
                commissions={this.state.commissions}
                onCheckOutChanged={ch => {
                    this.isScheduleChanged = true;
                    this.checkOutChanged(ch);
                }}
                users={this.state.users}
			/>

            <div className="button-stack">

                <button className="button button-primary button-small" onClick={e => this.save(true)}>
                    Save and continue{' '}
                    <i className="fas fa-arrow-right"></i>
                </button>

                <button className="button button-secondary button-small" onClick={e => this.save(false)}>
                    Save and exit
                </button>

                <button className="button button-tertiary button-small" onClick={e => this.goBack()}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Cancel checkout
                </button>

            </div>

        </>);
    }

    renderStep2() {
        const {
            checkOut,
        } = this.state;

        return (<>

            <NextAppoinmentsPanel
                checkOut={checkOut}
                onPreBookClicked={ch => this.props.onPreBookClicked(ch)}
            />

            <div className="button-stack">

                <button className="button button-primary button-small" onClick={e => this.save(true)}>
                    Save and continue{' '}
                    <i className="fas fa-arrow-right"></i>
                </button>

                <button className="button button-secondary button-small" onClick={e => this.save(false)}>
                    Save and exit
                </button>

                <button className="button button-tertiary button-small" onClick={e => this.goBack()}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Back
                </button>

            </div>
        </>);
    }

    renderStep3() {

        return (<>
            <PaymentPanel
                ref={this.paymentPanelRef}
                checkOut={this.state.checkOut}
                isAddingDeposit={this.props.isAddingDeposit}
                isRetail={false}
                promoCodes={this.state.promoCodes}
                isCancelPayment={false}
                onChange={(a, b) => this.props.onChange(a, b)}
                onCheckOutChanged={ch => this.checkOutChanged(ch)}
            />

            <div className="button-stack">

                {this.props.isAddingDeposit ?
                    <button className={'button button-primary button-small ' + (this.canPayDeposit() ? '' : 'disabled')} onClick={e => this.save(true)}>
                        <i className="fas fa-check"></i>{' '}
                        {this.updateDepositButtonText()}
                    </button> :
                    <button className={'button button-primary button-small ' + (this.canSave() ? '' : 'disabled')} onClick={e => this.save(true)}>
                        <i className="fas fa-check"></i>{' '}
                        {this.updateButtonText()}
                    </button>
                }

                <button className="button button-tertiary button-small" onClick={e => this.goBack()}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Back
                </button>

            </div>

        </>);
    }

    renderStep4() {
        return (<>
            <PosTerminalPanel
                checkOut={this.state.checkOut}
                onChange={(a, b) => this.props.onChange(a, b)}
                onCheckOutChanged={ch => this.checkOutChanged(ch)}
            />
            <div className="button-stack">
                <button className={'button button-primary button-small ' + (this.canContinue() ? '' : 'disabled')} onClick={e => this.save(false)}>
                    <i className="fas fa-check"></i>{' '}
                    Send to payment device
                </button>

                <button className="button button-tertiary button-small" onClick={e => this.goBack()}>
                    <i className="fas fa-arrow-left"></i>{' '}
                    Back
                </button>

            </div>
        </>)
    }

    renderStep5() {
        return (<>
            <TipsPanel
                checkOut={this.state.checkOut}
                tipAmountFromDevice={this.state.tipAmountFromDevice}
                onChange={(a, b) => this.props.onChange(a, b)}
                onCheckOutChanged={ch => this.checkOutChanged(ch)}
            />
            <div className="button-stack">
                <button className={'button button-primary button-small ' + (this.canCheckOut() ? '' : 'disabled')} onClick={e => this.save(true)}>
                    <i className="fas fa-check"></i>{' '}
                    Complete Checkout
                </button>
            </div>
        </>)
    }

    renderStep6() {
        return (<>
            <div className="panel retail-purchase-complete-panel">
                <div className="panel-body">
                    {this.props.isAddingDeposit ? <>Deposit paid!</> : <>Checkout complete!</>}
                </div>
            </div>
        </>);
    }

    render() {
        const {
            isLoading,
            isLoadingStep,
            checkOut,
            step
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {/* Customer summary */}
            <CustomerSummary
                customerID={checkOut.customer.customerID}
                onViewInfoClicked={this.viewCustomer}
            />

            {isLoadingStep && <Loader />}

            {/* Selected services / Check Out */}
            {!isLoadingStep && step == 1 && this.renderStep1()}

            {/* Next Appointment(s) */}
            {!isLoadingStep && step == 2 && this.renderStep2()}

            {/* Payment Methods */}
            {!isLoadingStep && step == 3 && this.renderStep3()}

            {/* Card Terminal */}
            {!isLoadingStep && step == 4 && this.renderStep4()}

            {/* Tips Terminal */}
            {!isLoadingStep && step == 5 && this.renderStep5()}

            {/* Confirmation */}
            {!isLoadingStep && step == 6 && this.renderStep6()}

        </>);
    }
}

export default withRouter(CheckOut);