import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class OnlineBookingService
    extends BaseService {
    
    static async getSettings() {
        return BaseService.callAPI('online-booking/get-settings');
    }

    static async saveSettings(obSettings) {
        return BaseService.callAPI('online-booking/save-settings', obSettings);
    }

    static async getMerchantServicesSettings() {
        return BaseService.callAPI('online-booking/get-merchant-services-settings');
    }

    static async listPaymentDevices() {
        return BaseService.callAPI('online-booking/list-payment-devices');
    }

    static async getPaymentDevice(id) {
        return await BaseService.callAPI('online-booking/get-payment-device/' + id);
    }

    static async getPosIDs(isvID) {
        return BaseService.callAPI('online-booking/get-pos-ids/' + isvID);
    }

    static async saveMerchantSettings(obSettings) {
        return BaseService.callAPI('online-booking/save-merchant-settings', obSettings);
    }

    static async savePaymentDevices(paymentDevice) {
        return BaseService.callAPI('online-booking/save-payment-device', paymentDevice);
    }

    static async deletePaymentDevice(id) {
        return await BaseService.callAPI('online-booking/delete-payment-device/' + id);
    }

    static async testCardMachineConnection(id) {
        return await BaseService.callAPI('online-booking/test-connection/' + id);
    }

    static async updateCardMachine(id) {
        return await BaseService.callAPI('online-booking/update-card-machine/' + id);
    }

    static async reconcileTerminal(id) {
        return await BaseService.callAPI('online-booking/reconcile-card-machine/' + id);
    }
}
